export const translations = {
  "de": {
    "Powered by": "Powered by",
    "Continue": "Weiter",
    "Settings": "Einstellungen",
    "{project} asks for your email address": "{project} fragt nach Deiner E-Mail-Adresse",
    "Don't share email": "E-Mail nicht weitergeben",
    "Receive notifications from this project": "Benachrichtigungen über dieses Projekt erhalten",
    "{project} requires your email address to continue": "{project} benötigt Deine E-Mail-Adresse",
    "{project} will collect your email address and utilize it in accordance with their data protection policy. Bowline will also store your email address for the purpose of sending notifications.": "{project} sammelt Ihre E-Mail-Adresse und verwendet sie in Übereinstimmung mit ihrer Datenschutzpolitik. Bowline speichert Ihre E-Mail-Adresse auch zum Zweck des Versands von Benachrichtigungen.",
    "Your stored email": "Deine gespeichert E-Mail",
    "Email address": "E-Mail-Adresse",
    "Email is required": "E-Mail ist erforderlich",
    "I subscribe to project updates": "Ich abonniere die Projekt-Updates",
    "Submit": "Abschicken",
    "Wallet": "Wallet",
    "New alerts": "Neue Benachrichtigungen",
    "Not signed in with Wallet": "Nicht mit Wallet angemeldet",
    "Connected to wrong network": "Mit falschem Netzwerk verbunden",
    "Account": "Konto",
    "Balance": "Kontostand",
    "Signing Request": "Signieranfrage",
    "Cancel": "Abbrechen",
    "Sign": "Unterzeichnen",
    "You are not signed in with your Wallet yet": "Du bist noch nicht mit deinem Wallet angemeldet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Du wirst gebeten, eine Nachricht zu signieren, um eine sichere Login-Verbindung für Dein Wallet herzustellen. Wir haben keinen Zugriff auf Dein Guthaben und NFTs und führen keine Aktionen mit diesen Informationen durch.",
    "You are signed in with your Wallet": "Du bist mit deinem Wallet angemeldet",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Du bist derzeit mit einem anderen Netzwerk verbunden als dem, auf dem dieser Vertrag ausgeführt ist. Bitte ändere dein Netzwerk in {networkName} in deinem Wallet.",
    "Switch to {networkName} network": "Zu {networkName} Netzwerk wechseln",
    "Copy address": "Adresse kopieren",
    "Tokens": "Tokens",
    "Activity": "Aktivität",
    "Please \"Sign in with Wallet\" first.": "Bitte nutze erst “Anmelden mit Wallet”.",
    "See above": "Siehe oben",
    "Change E-mail address": "Ändere E-Mail-Adresse",
    "Change": "Ändern",
    "Delete Account": "Konto löschen",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Um dein Konto zu löschen, kontaktiere uns bitte unter: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Weitere Informationen zur Verarbeitung Deiner personenbezogenen Daten findest Du in unserer <a href='https://www.bowline.app/data-protection/' target='_blank'>Datenschutzerklärung</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Nutzungsbedingungen</a>.",
    "No Activity yet in this session": "Noch keine Aktivität in dieser Sitzung",
    "Recent Signatures": "Letzte Signaturen",
    "Transactions": "Transaktionen",
    "Disconnect": "Trennen",
    "ok": "ok",
    "Reload Tokens": "Tokens neu laden",
    "Sort by Creation Date (Newest to Oldest)": "Sortieren nach Erstellungsdatum (Neueste bis Älteste)",
    "Sort by Creation Date (Oldest to Newest)": "Sortieren nach Erstellungsdatum (Älteste bis Neueste)",
    "Sort by Identifier (Lowest to Highest)": "Sortieren nach Kennung (Niedrigste bis Höchste)",
    "Sort by Identifier (Highest to Lowest)": "Sortieren nach Kennung (Höchste bis Niedrigste)",
    "Sort by Contract Address": "Sortieren nach Contract",
    "You do not have any Token in Wallet for this project": "Du hast keine Token in Deinem Wallet für dieses Projekt",
    "Reload": "Neu laden",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Dein Wallet kann auch andere Tokens von anderen dApps enthalten. Bitte besuche die Websites der Projekte, um sie zu sehen, oder überprüfe Dein Wallet auf zum Beispiel",
    "or": "oder",
    "Details": "Details",
    "Connect to Wallet": "Mit Wallet verbinden",
    "close": "schließen",
    "Select a Wallet": "Wallet auswählen",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Mit der Verbindung Deines Walles akzeptierst Du unsere <a href='https://www.bowline.app/tos/' target='_blank'>Nutzungsbedingungen</a>.",
    "How to connect?": "Wie verbinden?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Für eine sichere Verbindung und Interaktion mit diesem Dienst benötigst Du ein Wallet, die das Web3-Protokoll unterstützt. Bitte wählen Sie eine der folgenden Optionen, um eine Verbindung zu Ihrer Geldbörse herzustellen.",
    "I do have a wallet.": "Ich habe ein Wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "Falls Du bereits ein Wallet haben solltest, wähle bitte MetaMask, Ledger, Coinbase oder Wallet Connect, um dich mit Deinem Wallet zu verbinden.",
    "No wallet yet?": "Du hast noch kein Wallet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "Kein Problem, Du hast die Möglichkeit, sehr einfach ein persönliches Wallet zu erstellen, indem Du ein soziales Konto nutzt. Wir erstellen für Dich im Hintergrund ein non-custodial Wallet und Du kannst Deinen privaten Schlüssel bei Bedarf exportieren.",
    "Connect with Coinbase Wallet": "Mit Coinbase Wallet verbinden",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet ist eine weit verbreitete und häufig genutzte Wallet, die den Nutzern den Zugang zum Web 3 Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Want to learn more about this wallet?": "Möchtest du mehr Über dieses Wallet erfahren?",
    "Learn how to install and setup this wallet on": "Erfahre hier, wie Du dieses Wallet Installieren und einrichten kannst",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Wir haben erst kürzlich die Unterstützung für dieses Wallet hinzugefügt. Wenn Du ein Problem hast, lass es uns bitte wissen.",
    "Connect with Ledger": "Mit Ledger verbinden",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger ist eine weit verbreitete und häufig genutzte Geldbörse, die den Nutzern den Zugang zum Web-3-Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Sobald Du auf die Schaltfläche Verbinden klickst, kannst Du Ledger Live und Ledger Connect von Deinem Computer oder Mobiltelefon aus nutzen.",
    "Connect with MetaMask": "Mit MetaMask verbinden",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask ist eine weit verbreitete und häufig genutzte Geldbörse, die den Nutzern den Zugang zum Web 3 Ökosystem der dezentralen Anwendungen ermöglicht.",
    "Please use your existing MetaMask wallet.": "Bitte nutze Dein existierendes MetaMask Wallet.",
    "Connect with your preferred wallet": "Verbinde dich mit Deinem bevorzugten Wallet",
    "With WalletConnect you can connect to almost any existing wallet.": "Mit WalletConnect kannst Du dich mit fast jedem bestehendem Wallet verbinden.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Bitte klicke auf WalletConnect und scanne entweder den QR-Code mit der Wallet deiner Wahl oder wähle aus der vorgegebenen Liste der kompatiblen Wallets.",
    "Connect with your Google Account": "Mit Google Account verbinden",
    "Connect with Google": "Mit Google verbinden",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Wenn Du noch keine Krypto-Wallet hast, kannst Du deine aktuellen sozialen Konten wie Google oder Facebook nutzen, um eine Brieftasche zu erstellen.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "Wir haben keinen Zugang zu Deinen privaten Schlüsseln, was bedeutet, dass wir nicht in der Lage sind, Deine Güter in Deinem Namen zu verwalten. Du, und nur Du, behältst die vollständige Kontrolle und das Eigentum über Dein Wallet und den dazugehörigen privaten Schlüssel.",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Um eine Verbindung mit Deinem Google-Konto herzustellen, musst Du einer vertrauenswürdigen Anwendung eines Drittanbieters die Genehmigung erteilen, ein Konto für ein soziales Netzwerk zu erstellen, das die sichere Verwaltung Deines non-custodial Wallets gewährleistet.",
    "Connect to your Wallet via Social Connect.": "Verbinde Dein Wallet über Social Connect.",
    "Connect with your Meta Account": "Verbinden mit Meta Account",
    "Connect with Facebook": "Verbinden mit Facebook",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect ist ein neuer, einfacher und schneller Weg, eine Wallet zu erstellen. Hierfür nutzt Bowline Deine bestehenden sozialen Konten wie Google oder Facebook, um ein Wallet zu erstellen, die in jeder von Bowline betriebenen dezentralen Anwendung (dApp) verwendet werden kann.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline hat keinen Zugang zu Deinen privaten und finanziellen Informationen, nur Du hast die volle Kontrolle und das Eigentum über Dein Wallet.",
    "Connect with Metamask": "Verbinden mit Metamask",
    "Connect with Wallet Connect": "Verbinden mit Wallet Connect",
    "Verify your e-mail address": "Bestätige Deine E-Mail-Adresse",
    "The code was sent to your e-mail": "Der Code wurde an deine E-Mail-Adresse geschickt",
    "Please enter the code": "Bitte gib den Code ein",
    "Verify": "Verifizieren",
    "Resend": "Nochmal senden",
    "Discard": "Verwerfen",
    "Attending": "Angemeldet",
    "Type": "Typ",
    "Start": "Start",
    "End": "Ende",
    "Signed up": "Angemeldet",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Neue Inhalte sind verfügbar. Bitte klicke hier, um die Seite zu aktualisieren und Probleme zu vermeiden.",
    "Home": "Home",
    "Already minted in total": "Insgesamt bereits geminted",
    "Already minted / wallet": "Bereits geminted / wallet",
    "Mint for {mintPrice} ETH / each": "Minten für {mintPrice} ETH / pro Stück",
    "Paid Transaction": "Bezahlte Transaktion",
    "Mint": "Mint",
    "Buy Mint": "Kaufe Mint",
    "Select an option to pay for this mint": "Wähle eine Option, um für diese Münze zu bezahlen",
    "Pay with Crypto": "Bezahle mit Krypto",
    "We realized that your balance is too low. You need to have at least ": "Wir haben festgestellt, dass dein Guthaben zu niedrig ist. Du musst mindestens Folgendes haben ",
    "Pay with Credit Card": "Bezahle mit Kreditkarte",
    "Please wait for the payment to be completed": "Bitte warte, bis die Zahlung abgeschlossen ist",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "Die Transaktion wurde abgeschickt und du erhältst eine Benachrichtigung in deine Bowline-Wallet",
    "No tokens available for minting": "Keine Tokens zum Minten verfügbar",
    "Max tokens minted": "Maximale Tokens geminted",
    "Mint will start soon. Stay tuned!": "Mint wird bald beginnen. Stay tuned!",
    "Price/NFT": "Preis/NFT",
    "Amount you already minted": "Bereits gemintete Anzahl",
    "Current Price": "Derzeitiger Preis",
    "e.g. Voucher Code": "z.B. Gutschein Code",
    "Sold out!": "Ausverkauft!",
    "Please visit the": "Bitte besuche den",
    "secondary market.": "Sekundärmarkt.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "Switch to Ethereum network": "Wechsle zum Ethereum Netzwerk",
    "Switch to Rinkeby network": "Wechsle zum Rinkeby Netzwerk",
    "Switch to Görli network": "Wechsle zum Görli Netzwerk",
    "Switch to Sepolia network": "Wechsle zu Sepolia Netzwerk",
    "Switch to Polygon network": "Wechsle zum Polygon Netzwerk",
    "Switch to Mumbai network": "Wechsle zum Mumbai Netzwerk",
    "Switch to Amoy network": "Wechsle zum Amoy Netzwerk",
    "Preview": "Vorschau",
    "Edit": "Bearbeiten",
    "Update": "Aktualsieren",
    "Create": "Erstellen",
    "Update Page": "Aktualisiere Seite",
    "Create Page": "Erstelle Seite",
    "Do you want to update this page?": "Willst Du diese Seite aktualisieren?",
    "Do you want to create this page?": "Willst Du diese Seite erstellen?",
    "Set Title": "Setze Titel",
    "Set URL": "Setze URL",
    "Locale": "Sprache",
    "Page Deleted": "Seite gelöscht",
    "Page was deleted successfully!": "Die Seite wurde erfolgreich gelöscht!",
    "Back": "Zurück",
    "Missing title": "Titel fehlt",
    "Expired": "Abgelaufen",
    "Unlocking deal": "Entsperre Deal",
    "Successfully Unlocked": "Erfolgreich freigeschalten",
    "You successfully unlocked this deal": "Du hast erfolgreich diesen Deal entsperrt",
    "Close": "Schließen",
    "There was a problem while unlocking this deal.": "Es gab ein Problem beim entsperren dieses Deals",
    "Unlock with different tokens": "Entsperre mit anderen Tokens",
    "Unlock": "Freischalten",
    "Please connect your wallet to be able to unlock": "Bitte verbinde Dein Wallet damit du entsperren kannst",
    "Secrets": "Secrets",
    "Welcome to {project}": "Wilkommen bei {project}",
    "By submitting you accept the": "Mit dem Absenden akzeptierst Du die",
    "terms of service": "Servicebedingungen",
    "terms of service for {projectTitle}": "Servicebedingungen von {projectTitle}",
    "data protection policy": "Datenschutzerkärung",
    "data protection policy for {projectTitle}": "Datenschutzerkärung von {projectTitle}",
    "of {projectTitle}.": "von {projectTitle}",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Weitere Informationen über die Verarbeitung Deiner personenbezogenen Daten durch bowline findest Du in dieser <a href='https://www.bowline.app/data-protection/' target='_blank'>Datenschutzerklärung</a>.",
    "Position": "Position",
    "Your Wallet": "Dein Wallet",
    "here": "Hier",
    "Share": "Teilen",
    "Bid for this Product": "Für dieses Produkt bieten",
    "you must bid at least": "Du musst mindestens bieten",
    "Enter bid": "Gebot eingeben",
    "Bid can't be higher than current balance": "Gebot kann nicht höher sein als das aktuelle Guthaben",
    "Warning: Your bid is lower than the current highest bid": "Warnung: Dein Gebot ist niedriger als das aktuell höchste Gebot",
    "Your balance": "Dein Guthaben",
    "Place your Bid": "Gebot abgeben",
    "You cannot place a bid as you have insufficient funds.": "Du kannst kein Gebot abgeben das dein Guthaben nicht ausreicht.",
    "Please follow the confirmation steps in your wallet.": "Bitte folge den Bestätigungsschritten in deinem Wallet.",
    "Your bid is stored in our database.": "Dein Gebot ist in unserer Datenbank gespeichert.",
    "You have successfully placed your bid!": "Du hast erfolgreich ein Gebot abgegeben!",
    "We will notify you if you are outbid, or if you are the bid winner!": "Wir werden dich benachrichtigen falls du überboten wirst oder falls dein Gebot erfolgreich ist!",
    " view more": " zeige mehr",
    "This is a cached value, the price should soon be loaded from blockchain.": "Dies ist ein zwischengespeicherter Wert, der Preis sollte bald von der Blockchain geladen werden.",
    "Purchase": "Kaufen",
    "Your item purchase was successful": "Dein Kauf war erfolgreich",
    "Okay": "Okay",
    "Order Summary": "Zusammenfassung der Bestellung",
    "Amount: ": "Anzahl: ",
    "Total": "Gesamt",
    "Close Modal": "Modal schließen",
    "Transaction in Progress": "Laufende Transaktion",
    "Please sign allowance": "Bitte signiere die Erlaubnis",
    "please approve the interaction": "Bitte genehmige die Ineraktion",
    "Checking your balance": "Überprüfung Deines Guthabens",
    "checking if your wallet has enough funds to buy this product": "Wir überprüfen ob dein Wallet genügend Guthaben hat um dieses Produkt kaufen zu können",
    "Please sign transaction": "Bitte signiere die Transaktion",
    "Metamask should open and ask you to sign the transaction": "Metamask sollte sich öffnen und auffordern, die Transaktion zu unterzeichnen",
    "Wait for transaction to be mined": "Warte bis die Transaktion gemined ist",
    "Depending on network traffic this could take a few minutes": "Je nach Netzauslastung kann dies einige Minuten dauern",
    "Something went wrong": "Etwas ist schief gelaufen",
    "We could not purchase the product!": "Wir konnten das Produkt nicht kaufen!",
    "Biling details": "Rechnungsdaten",
    "First name": "Vorname",
    "This field is required": "Dieses Feld ist verpflichtend",
    "Maximum length is 150": "Maximale Länge ist 150",
    "Last name": "Nachname",
    "Company name": "Firmenname",
    "Address": "Adresse",
    "Minimum length is 5": "Minimale Länge ist 5",
    "Maximum length is 250": "Maximale Länge ist 250",
    "Postal Code": "Postleitzahl",
    "City": "Stadt",
    "Country": "Land",
    "Summary": "Zusammenfassung",
    "Products": "Produkte",
    "Price": "Preis",
    "Continue Purchase": "Einkauf fortsetzen",
    "Please Sign in with Wallet first before purchasing a product!": "Bitte melde Dich zuerst mit deinem Wallet an, bevor Du ein Produkt kaufst!",
    "Please connect your wallet first before being able to purchase products": "Bitter verbinde zuerst Dein Wallet bevor Du ein Produkt kaufen kannst",
    "Click Purchase to complete your checkout.": "Klicke auf Kaufen, um Deine Bestellung abzuschließen.",
    "Sign in with Wallet": "Anmelden mit Wallet",
    "Stake All selected": "Stake alle Ausgewählten",
    "Unstake All selected": "Unstake alle Ausgewählten",
    "staked": "staked",
    "Unstake": "Unstake",
    "Stake": "Stake",
    "unclaimed earnings": "nicht beanspruchte Erträge",
    "Your retrieved earnings, that are ready to claim!": "Du hast Erträge, die abgerufen werden können!",
    "pending rewards": "ausstehende Belohnungen",
    "Tokens in the Hole": "Tokens im Loch",
    "Your total Tokens staked": "Anzahl deiner gestakten Tokens",
    "Available Balance": "Verfügbares Guthaben",
    "Your lifetime earnings from staking.": "All Deine jemals erzielten Erträge durch Staking.",
    "Claim": "Claim",
    "Authorization required": "Autorisierung erforderlich",
    "The provided voucher code is not valid": "Der angegebene Gutscheincode ist nicht gültig",
    "The provided voucher code got already used. Each voucher code can be used only once.": "Der angegebene Gutscheincode wurde bereits verwendet. Jeder Gutscheincode kann nur einmal verwendet werden.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Deine Transaktion wurde bereits an die Blockchain gesendet. Es sieht so aus, als hättest du versucht, denselben Token erneut zu minten. Bitte habe etwas Geduld.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Falls du mehr als einen Token versuchst zu minten, kann es sein, dass unsere Bot Protection dich gefiltert hat. In diesem Fall überprüfen wir deine Transaktion manuell und senden dir den Token später zu.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "Wir konnten Deinen Token derzeit nicht per Airdrop schickem, da derzeit zu viel Verkehr auf der Blockchain herrscht. Dein Airdrop wurde jedoch erfolgreich zur Warteschlange hinzugefügt und Du erhältst Deinen Token, sobald die Transaktion in die Blockchain geschrieben wurde. Dies kann je nach Warteschlangenlänge und Gas bis zu ein paar Stunden dauern.",
    "Attention:": "Achtung:",
    "We can't guarantee the exact order of the transactions.": "Wir können die genaue Reihenfolge der Transaktionen nicht garantieren.",
    "An Error occured!": "Es ist ein Fehler aufgetreten!",
    "show full error message": "vollständige Fehlermeldung anzeigen",
    "Processing": "Verarbeitung",
    "The airdrop is handled in the background and might experience delays beyond the usual timeframe due to network congestion.": "Der Airdrop wird im Hintergrund ausgeführt und aufgrund von Netzwerküberlastungen kann es zu Verzögerungen kommen, die über den üblichen Zeitrahmen hinausgehen.",
    "This transaction takes longer than usual. You can close this modal without canceling the transaction.": "Diese Transaktion dauert länger als üblich. Sie können dieses Modal schließen, ohne die Transaktion zu stornieren.",
    "Success!": "Erfolgreich!",
    "a content category": "eine Inhaltskategorie",
    "an unlockable content": "einen Entsperrbaren Inhalt",
    "a whitelist entry": "einen Whitelisteintrag",
    "a event": "ein Event",
    "a token": "einen Token",
    "created": "erstellt",
    "updated": "aktualisiert",
    "minted": "Geminted",
    "transfered": "übertragen",
    "You successfully {action} {resourceType}": "Du hast erfolgreich {resourceType} {action}",
    "click to get back to overview screen": "anklicken, um zur Übersicht zurückzukehren",
    "Missing Name": "Fehlender Name",
    "Select": "Auswählen",
    "Selected": "Ausgewählt",
    "This token has already been used": "Dieser Token wurde bereits verwendet",
    "This token is already selected elsewhere": "Dieser Token wurde bereits wo anders ausgewählt",
    "Auto Selected": "Automatisch gewählt",
    "Any {contract} Token": "Jeder {contract} Token",
    "Collapse": "",
    "Expand": "",
    "Transfer Token": "Token übertragen",
    "Receiver": "Empfäger",
    "Not a valid address!": "Keine gültige Adresse!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Du wirst einen Token an {address} übertragen. Dieser Vorgang kann nicht rückgängig gemacht werden",
    "Transfer this NFT": "Übertrage diesen NFT",
    "Purchase in Progress": "Kauf in Bearbeitung",
    "Signing with your wallet": "Mit deinem Wallet einloggen",
    "Please follow your wallet instructions to confirm the transaction.": "Bitte folge den Anweisungen in deinem Wallet um die Transaktion zu bestätigen.",
    "You confirmed the transaction with your wallet.": "Du hast du Transaktion in deinem Wallet bestätigt.",
    "Transaction is sent to the blockchain": "Transaktion wird an die Blockchain gesendet",
    "After signing the transaction it is send to the blockchain.": "Nach dem Signieren der Transaktion wird sie an die Blockchain gesendet.",
    "Transaction is being processed": " Transaktion wird gerade bearbeitet",
    "Please check your wallets transaction history for your actual transaction status!": "Bitte überprüfe Deinen Transaktionsverlauf in Deinem Wallet auf den aktuellen Transaktionsstatus!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Du kannst dieses Modal jederzeit schließen - es hat keinen Einfluss auf Ihre Transaktion. Wir werden das Modal für dich schließen, sobald wir sehen, dass die Transaktion gemined ist.",
    "You need to connect your wallet to unlock": "Bitte verbinde deine Wallet, um freizuschalten",
    "Email Required": "E-Mail erforderlich",
    "We noticed that you haven't set up an email address yet, which is necessary to proceed with the unlocking process. Please provide your email address below.": "Wir haben festgestellt, dass Sie noch keine E-Mail-Adresse eingerichtet haben, was für den Fortgang des Entsperrvorgangs erforderlich ist. Bitte geben Sie unten Ihre E-Mail-Adresse ein.",
    "Email": "E-Mail",
    "email is required": "E-Mail ist erforderlich",
    "Step": "Schritt",
    "of": "Von",
    "You have unlocked this successfully": "Du hast das erfolgreich entsperrt",
    "Select your Tokens for Voting": "Wähle Tokens zum abstimmen",
    "Select your Token": "Wähle Tokens",
    "You can unlock this unlimited times.": "Du kannst das beliebig oft entsperren",
    "You can unlock this once.": "Du kannst das einmal entsperren.",
    "You can unlock this from {date}.": "Du kannst das ab {date} entsperren.",
    "You can unlock this until {date}.": "Du kannst das bis {date} entsperren.",
    "If you sell your token you will loose access": "Wenn du deinen Token verkaufst verlierst du den Zugriff",
    "Sorry you dont have any matching Token for unlocking": "Leider hast Du keinen passenden Token für die Freischaltung",
    "Please enter to unlock": "Bitte eingeben zum entsperren",
    "Entered value does not match email format": "Eingegebener Wert stimmt nicht mit E-Mail-Format überein",
    "Unlocking": "Entsperrt",
    "Sign the message in your wallet to submit the transaction. Once submitted, We will wait until the transaction is finalized and your unlockable content is available.": "Signiere die Nachricht in deinem Wallet um die Transaktion abzuschicken. Sobald sie abgeschickt ist, warten wir bis die Transaktion abgeschlossen ist und der Inhalt für Dich verfügbar ist.",
    "Next": "Weiter",
    "Sign Up": "Anmelden",
    "Vote": "Abstimmen",
    "And": "Und",
    "Or": "Oder",
    "Loading...": "",
    "Select {n} token from these": "Wähle {n} Token aus diesen",
    "Sorry, no matching tokens for this boundary": "",
    "Conditions met": "Bedingungen erfüllt",
    "Select tokens below you want to use": "Wähle unten Tokens die du verwenden möchtest",
    "Change password": "Passwort ändern",
    "Insert your current password and the new password": "Gib Dein aktuelles und neues Passwort ein",
    "Current Password": "Aktuelles Passwort",
    "Password": "Passwort",
    "Save": "Speichern",
    "tokens": "Tokens",
    "community event": "Community Event",
    "partner event": "Partner Event",
    "conference": "Konferenz",
    "irl meetup": "IRL Meetup",
    "irl party": "IRL Party",
    "virtual meetup": "Virtuelles Meetup",
    "discord event": "Discord Event",
    "ask me anything": "Ask me Anything",
    "There are no votings options available": "Es sind keine Abstimmungsoptionen verfügbar",
    "vote": "Abstimmen",
    "Please connect your wallet to be able to vote": "Bitte verbinde Dein Wallet um Abstimmen zu können",
    "Your Vote has been sent successfully": "Deine Stimme wurde erfolgreich",
    "Voting": "Abstimmung",
    "Successfully Voted": "Erfolgreich abgestimmt",
    "There was a problem while sending your vote. Please try again.": "Es gab ein Problem beim senden deiner Stimme. Bitte versuche es nochmal.",
    "Reveal": "Veröffentlichung",
    "get more Info": "mehr Infos erhalten",
    "This vote has ended. Thanks!": "Diese Abstimmung ist beendet. Danke!",
    "Wallet Changed": "Wallet geändert",
    "It looks like you changed your wallet, would you like to connect your wallet?": "Es sieht so aus, als hättest du dein Wallet geändert. Möchtest Du Dein Wallet verbinden?",
    "Export Wallet": "Wallet Exportieren",
    "(A QR code will be generated for {n} seconds)": "(Ein QR-Code wird für {n} Sekunden generiert)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Bitte scanne den QR-Code mit der Kamera Deines Handys oder einer Wallet-App (z. B. Metamask), um Dein Wallet zu exportieren, oder klicke auf die Schaltfläche unten, um den Schlüssel zu kopieren.",
    "Copy Private Key": "Privaten Schlüssel kopieren",
    "please complete your profile": "Bitte vervollständige Dein Profil",
    "* means required": "* bedeutet erforderlich",
    "Please connect to your wallet first.": "Bitte verbinde erst deine Wallet.",
    "Contract Address": "Contract Adresse",
    "Add my Wallet to this Allowlist": "Mein Wallet zu dieser Allowlist hinzufügen",
    "Congratulations!": "Glückwunsch!",
    "You've been added to the Allowlist!": "Du wurdest zur Allowlist hinzugefügt!",
    "Do you want to receive notifications on your email about this listing?": "Möchtest du per E-Mail über diese Liste benachrichtigt werden?",
    "Wallet Address": "Wallet Adresse",
    "You are already part of this allowlist!": "Du bist bereits auf dieser Allowlist!",
    "share email anonymised": "teile E-Mail anonymisiert",
    "(recommended)": "(empfohlen)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} wird nur eine anonymisierte E-Mail-Adresse erhalten, aber bowline wird Deine echte E-Mail-Adresse aufbewahren.",
    "share email": "teile E-Mail",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} erhält Ihre E-Mail-Adresse und verwendet sie gemäß der Datenschutzrichtlinie, und bowline speichert Deine E-Mail-Adresse auch, um Benachrichtigungen zu versenden.",
    "don't share email": "teile E-Mail nicht",
    "Two brands one vision": "Zwei Brands eine Vision",
    "Samsung x Ledger": "Samsung x Ledger",
    "Maximum security and the most important basic knowledge for the step into Web3.": "Hohe Sicherheit und die wichtigsten Grundkenntnisse für den Schritt ins Web3.",
    "Samsung opens the gates to Web3. Be the first to join the Samsung community and embark on a journey to a new world. \nFirst step to provide a Web3 Starter Bundle with Ledger on the Amazon marketplace. In addition to an onboarding experience by Ledger, the exclusive bundle also includes various NFTs designed by international and German artists from the NFT scene with an exciting minting experience over two phases.": "Samsung öffnet die Tore ins Web3. Sei jetzt als einer der Ersten Teil der Samsung Community und begebe dich auf die Reise in eine neue Welt.\nErster Schritt die Bereitstellung eines Web3 Starter Bundles mit Ledger auf dem Marktplatz Amazon. Zu dem exklusiven Bundle gibt es neben einer Onboarding Experience durch Ledger auch verschiedene NFTs entworfen Von internationalen und deutschen Künstlern aus der NFT Szene mit einer spannenden Minting-Experience über zwei Phasen.",
    "Learn more": "Erfahre mehr",
    "Visit": "Besuchen",
    "artist.tabs.art.title": "Die Samsung MX1 ART COLLECTION NFTs - 3 Artists, 9 einzigartige Kunstwerke",
    "Antoni Tudisco": "Antoni Tudisco",
    "Bruno Urli": "Bruno Urli",
    "Charlotte Taylor & Anthony Authié": "Charlotte Taylor & Anthony Authié",
    "artist.tudisco.subtitle": "Antoni Tudisco - 3D Digital Art - Fashion",
    "artist.tudisco.text": "Antoni Tudisco, Creative Director und visual Artist, ist in Hamburg geboren und aufgewachsen, wo er Medienmanagement, Webdesign und Programmierung studierte. Er arbeitete mit Top-Marken wie Nike, Adidas, Versace, Google, Samsung, Monclair und D&G zusammen.",
    "artist.modal.tudisco.subHeader": "Antoni Tudisco",
    "artist.modal.tudisco.headline": "Antoni Tudisco - The Journey of ETHEE",
    "artist.modal.tudisco.text": "ETHEEVERSE\nETHEE ist ein Metaverse-Charakter der Zukunft, der von Antoni Tudisco erschaffen wurde. ETHEE’s Reise durch das ETHEEVERSE ist inspiriert von der futuristischen und kreativen Interpretation von Tech und neuen Wegen der digitalen Kommunikation durch Antoni. “Wir entwerfen unsere Träume im Metaverse.”",
    "artist.bruno.subtitle": "Bruno Urli - die Kunst der NFT Photographie",
    "artist.bruno.text": "Bruno Urli (2004) ist ein achtzehnjähriger zeitgenössischer Digital Artist, der vor allem für seine verträumte Digitalkunst bekannt ist. Er wurde in Deutschland geboren und hat sich bereits in jungen Jahren eine besonderes Standing in der Krypto-Kunstszene erarbeitet. In den letzten Jahren hatte Bruno Urli mehrere Ausstellungen seiner Werke in Galerien auf der ganzen Welt, unter anderem in New York, Tel-Aviv und Paris.",
    "artist.modal.bruno.subHeader": "Bruno Urli",
    "artist.modal.bruno.headline": "Bruno Urli - Nachwuchstalent der Crypto Art Szene",
    "artist.modal.bruno.text": "Bruno spielt mit Tönen, Kompositionen und Lichtern, um fesselnde neue Welten zu schaffen, die gleichzeitig lebendig und surreal wirken. Die sehr detaillierten 3D-Werke des Künstlers spiegeln oft sein Interesse an der natürlichen Welt sowie seine persönlichen Erfahrungen und Gefühle wider. Seine Arbeiten sind auch sehr emotional und rufen Gefühle von Freude, Trauer und Sehnsucht hervor.",
    "artist.charlotte.subtitle": "Charlotte Taylor - Real-Estate in der Kunstwelt ",
    "Charlotte Taylor": "Charlotte Taylor",
    "artist.charlotte.text": "Das Studio Charlotte Taylor schafft Designwelten, die das Skulpturale mit dem Praktischen, das Futuristische mit Komfort verbinden. Das Studio erforscht die Grenzen zwischen dem digitalen und dem physischen und nutzt ultra-realistische visuelle Renderings, um reale Welten neu zu gestalten. Das Ergebnis ist eine einzigartige, unverwechselbare Vision, ein Prozess und ein Stil, der konventionelle Regeln über den Haufen wirft und das Greifbare neu interpretiert.",
    "artist.modal.charlotte.subHeader": "Charlotte Taylor",
    "artist.modal.charlotte.headline": "Charlotte Taylor: The journey into the real-estate NFT world",
    "artist.modal.charlotte.text": "Wir stellten uns die Reise Ihres Lebens durch eine Reihe von persönlichen Räumen und deren Reise durch die verschiedenen Ebenen des Lebens vor, die durch einen metathematischen Aufzug dargestellt werden. Wir sind beide sehr mit häuslichen Räumen und dem Gefühl von Zuhause verbunden und haben das Gefühl, dass es ein Portal ist, um sich selbst und seine Persönlichkeit, die sich im Laufe der Jahre verändert und anpasst, wirklich zu zeigen.",
    "Anthony Authié": "Anthony Authié",
    "artist.authie.text": "Anthony Authié ist 30 Jahre alt, und seit einigen Jahren kennen ihn die Normalsterblichen unter dem Namen Zyva Studio, seinem Studio… der Architektur, sagen wir mal. Mit 3D Tools schafft Anthony einen unvergleichbaren Charakter und verwendet die Codes von influence 3.0.",
    "artist.modal.authie.subHeader": "Anthony Authié",
    "artist.modal.authie.headline": "Anthony Authié: The journey into the real-estate NFT world",
    "artist.modal.authie.text": "Wir stellten uns die Reise Ihres Lebens durch eine Reihe von persönlichen Räumen und deren Reise durch die verschiedenen Ebenen des Lebens vor, die durch einen metathematischen Aufzug dargestellt werden. Wir sind beide sehr mit häuslichen Räumen und dem Gefühl von Zuhause verbunden und haben das Gefühl, dass es ein Portal ist, um sich selbst und seine Persönlichkeit, die sich im Laufe der Jahre verändert und anpasst, wirklich zu zeigen.",
    "artist.tabs.genesis.title": "Die Samsung MX1 GENESIS EDITION NFTs - Samsungs offizieller Community-Token",
    "Jaiden Stipp": "Jaiden Stipp",
    "Specter": "Specter",
    "Plastic Pen": "Plastic Pen",
    "Thomas Fleck": "Thomas Fleck",
    "artist.jaiden.subtitle": "Jaiden Stipp - new Talent & rising Star",
    "artist.jaiden.text": "Jasti, mit bürgerlichem Namen Jaiden Stipp, ist ein 16-jähriger Künstler, der als einer der produktivsten jungen Künstler im NFT-Bereich gilt. Anfang 2021 entdeckte Jasti sein großes Interesse an NFTs und beschloss, den Sprung zu wagen. Er mintete seine erste NFT und verkaufte sie schnell für 20 ETH ($30.377)",
    "artist.modal.jaiden.subHeader": "Jaiden Stipp",
    "artist.modal.jaiden.headline": "Jasti’s Art Journey",
    "artist.modal.jaiden.text": "Auf der spektakulären reise ins Web3 hat Jaiden einige Elemente entwickelt, welche er in jedem seiner Kunstwerke als Elementare Bestandteile verwendet. In diesem Werk vereint er alle für Ihn emotional bedeutungsvollsten Elemente.",
    "artist.specter.subtitle": "Specter Berlin - Artist! Director! Creator!",
    "artist.specter.text": "Geboren 1975, aufgewachsen in Paris, heute in Berlin ansässig Specter Berlin begann seine Karriere als Graffiti-Künstler in den 90er Jahren. Er ist einer der drei Mitbegründer des Plattenlabels “Aggro Berlin”, während seiner Wirkungszeit größtes Independent-Label Europas. Seine Arbeit und Leistungen für - und innerhalb der HipHop-Kultur machen ihn zu einer lebenden HipHop-Legende. Doch das war erst der Anfang! Sein kreatives Schaffen ist noch lange nicht zu Ende - sein atemberaubender Output als Regisseur spiegelt seine leidenschaftliche Verbindung zwischen Kunst, urbaner Kultur und einem hoch entwickelten visuellen Ansatz wider.",
    "artist.modal.specter.subHeader": "Specter Berlin",
    "artist.modal.specter.headline": "Die “Journey of your Life” - “Knock Knock! Wer ist da?” von Specter Berlin",
    "artist.modal.specter.text": "Specter hat das Thema “Journey of Life” der Erschaffung neuer Welten und origineller Charaktere gewidmet, indem er ihre Lebensgeschichten erzählt. Dabei integriert er verschiedenste Blickwinkel auf Kulturen seiner kreativen Welt.",
    "artist.plastic_pen.subtitle": "Stefan Grosse Halbuer - DESIGNER UND ILLUSTRATOR",
    "Stefan Grosse Halbuer": "Stefan Grosse Halbuer",
    "artist.plastic_pen.text": "Freiberuflicher Designer und digitaler Künstler seit 10 Jahren. “Ich liebe es, mit meiner Kunst Geschichten zu erzählen, kleine Welten zu erschaffen und tief in Details einzutauchen.”",
    "artist.modal.plastic_pen.subHeader": "Stefan Grosse Halbuer - DESIGNER UND ILLUSTRATOR",
    "artist.modal.plastic_pen.headline": "Stefan Grosse Halbuer - “Doors”",
    "artist.modal.plastic_pen.text": "“Für mich besteht die Reise meines Lebens darin, dass sich Türen öffnen und schließen. Wann immer sich eine Gelegenheit bietet, ist es eine Tür, durch die ich gehen kann - oder auch nicht. “Doors” steht für diese Möglichkeiten und Chancen und die vielen Dimensionen, die eine Tür haben kann.”",
    "artist.thomas.subtitle": "Thomas Fleck - der Designer",
    "artist.thomas.text": "“Design ist mein Leben, und Leben ist das, was jetzt passiert, nicht gestern und nicht morgen.”\nMit Fokus auf Unverwechselbarkeit, Funktionalität und Style entwickelt Thomas ganzheitliche Designlösungen. Ihm ist wichtig jedes Projekt neu und unverwechselbar zu denken und dabei nicht auf bestehende Projekte zurückzugreifen.",
    "artist.modal.thomas.subHeader": "Thomas Fleck",
    "artist.modal.thomas.headline": "Thomas Fleck - Unverwechselbar, anmutende Designs",
    "artist.modal.thomas.text": "Die Entstehung des Universums aus der Sicht der Zukunftstechnologien. Design ist mein Leben, und Leben ist das, was jetzt passiert, nicht gestern und nicht morgen.",
    "Choose your Artist": "Wähle deinen Künstler",
    "Launch soon": "Started bald",
    "Days": "Tage",
    "Hours": "Stunden",
    "Minutes": "Minuten",
    "Seconds": "Sekunden",
    "View": "Anzeigen",
    "Frequently Asked Questions": "Häufige Fragen",
    "Legal": "Rechtliches",
    "Terms & Conditions": "AGB",
    "Aktionsbedingungen": "Aktionsbedingungen",
    "Disclaimer": "Disclaimer",
    "Data Privacy": "Datenschutz",
    "About": "Über",
    "Imprint": "Impressum",
    "MX1 GE Raffle Winner": "MX1 GE Raffle Gewinner",
    "Support": "Support",
    "Contact": "Kontakt",
    "FAQs": "FAQs",
    "Copyright© 2023 - {currentYear} Samsung. All Rights reserved.": "Copyright© 2023 - {currentYear} Samsung. All Rights reserved.",
    "generators.about.illuvium.text": "Auflage von 2000",
    "generators.prices.illuvium.copy": "Lerne Illuvium Labs kennen, ein Web3 Game Development Studio, das modernste Interoperable Games entwickelt. Ihr Bestreben, die Grenzen in der Spieleindustrie zu erweitern, macht sie zu einem außergewöhnlichen Partner, der spannende Preise bietet, darunter insgesamt 2000 Samsung x Illuvium Design-Skin-Pakete. Jedes Paket enthält Skins eines Ranger-Suits, einer Bach-Drohne, einer Mozart-Drohne, einer Chopin-Drohne und einer Hilde-Drohne.",
    "generators.about.ledger.text": "Auflage von 50",
    "generators.prices.ledger.copy": "Wir stellen Dir Ledger vor, die top Digital Asset Security Authority, die im Jahr 2024 ihr zehnjähriges Bestehen feiert und mit 6 Millionen Geräten weltweit 20 % der globalen Krypto-Vermögenswerte sichert. Wir freuen uns, bekannt zu geben, dass sie 50 individuelle Ledger Nano X- Devices als Preise anbieten.",
    "generators.about.wilderworlds.text": "Auflage von 20",
    "generators.prices.wilderworlds.copy": "Tauche ein in die Welt von Wilder World, wo Realität mit modernster Technologie verschwimmt. Mit Fokus auf Fotorealismus, Blockchain und KI bietet Wilder World ein unvergleichliches, immersives Erlebnis. Gewinne einen der folgenden, exklusive Preise von Wilder World - 5 GENs, 7 Motos, 3 Wapes, 4 Wheels oder 1 Wolf",
    "generators.about.wow.text": "Auflage von 75",
    "generators.prices.wow.copy": "Entdecke World of Women (WoW), initiiert von der gefeierten Künstlerin Yam Karkai, für mehr weibliche Repräsentation im digitalen Kunstzeitalter​.\n\nIn Partnerschaft mit Samsung bringt WoW Kunst in die Wohnzimmer dieser Welt. Gewinne eines der folgenden exklusiven NFTs: 5 WoW, 20 WoWG, 50 WoW Artfest!",
    "generators.about.samsung.text": "Auflage von 93",
    "generators.prices.samsung.copy": "Samsung feiert den Start des GEN3RATOR mit einer großartigen Auswahl an hochwertigen Hardware-Devices und Gadgets, begleitet von exklusiver digitaler Kunst aus dem Belvedere Museum Wien. Gewinne entweder einen von 75 Belvedere The Kiss NFTs oder eines von 5 Samsung S23 Ultra, 5 Samsung S24 Ultra, 3 Samsung web3 TV Bundles oder 5 Ledger Nano X MX1 Edition.",
    "generators.about.title": "Entdecke die GEN3RATORs von Samsung und Partnerkollektionen",
    "generators.about.copy": "Gemeinsam mit Partnerkollektionen verbindet Samsung das Ecosystem im Web3 und agiert als Enabler und Connector. Die langfristigen Partnerschaften eröffnen neue digitale Möglichkeiten.",
    "Securely start your crypto journey": "Starte deine sichere Krypto-Reise",
    "Secure, grow your crypto and manage your NFTs \n with the new Bluetooth-enabled hardware wallet from Ledger.": "Sichere und vermehre deine Kryptos und verwalte deine NFTs \nmit dem Bluetooth-fähigen Hardware-Wallet von Ledger.",
    "Every journey in web3 should start with a Ledger. Together with our partner Ledger we enable you something epic.": "Deine web3-Reise sollte mit einem Ledger-Hardware-Wallet erfolgen, die sie sicher und gut macht. Zusammen mit unserem Partner Ledger ermöglichen wir dir etwas Episches.",
    "Experience first-class NFT and crypto security with the Samsung x Ledger Web3 Starter Bundle and get an exclusive Samsung MX1 GENESIS NFT.": "Erlebe hochwertige NFT- und Krypto-Sicherheit mit dem Samsung x Ledger Web3 Starter Bundle und sichere dir einen Samsung MX1 ART COLLECTION NFT.",
    "Secure your valuable digital assets with the Ledger Nano X hardware wallet. Manage and grow them, always with the Ledger Live app. It’s simple to get started.": "Schütze deine wertvollen digitale Vermögenswerte mit der Ledger Nano X-Hardware-Wallet. Verwalten und vermehren diese mit der Ledger Live App. Der Einstieg ist so einfach.",
    "For information on how to get your ledger from the Web3 Starter Bundle and what other benefits come with it see <span class='pointer-url'>here</span>.": "Informationen darüber, wie du dein Ledger aus dem Web3-Starterpaket erhältst und welche weiteren Vorteile damit verbunden sind, findest du <span class='pointer-url'>hier</span>.",
    "GEN3RATOR": "GEN3RATOR",
    "Partner": "Partner",
    "Attached": "Verbunden",
    "GEN3 Chip": "GEN3 Chip",
    "View Prize": "Zeige Preis",
    "Continue Claim": "Claim fortsetzen",
    "Currently nothing to claim": "Aktuell nichts zu claimen",
    "Attach GEN3 Chip": "GEN3 Chip verbinden",
    "Connect to {networkName} network first": "Verbinde dich erst mit {networkName} Netzwerk",
    "Attaching is disabled": "Verbinden deaktiviert",
    "Detach GEN3 Chip": "GEN3 Chip trennen",
    "Do you really want to detach your {attachedChip}?": "Willst du wirklich deinen {attachedChip} trennen?",
    "You can't claim rewards with the GEN3RATOR until you attach again a GEN3 Chip": "Du kannst keine Rewards claimen bis du wieder einen GEN3 Chip mit deinem GEN3ATOR verbindest",
    "GEN3 Chip will go back to your Inventory": "Der GEN3 Chip geht zurück in dein Inventar",
    "You can use the GEN3 Chip again on any GEN3RATOR": "Du kannst den GEN3 Chip mit jedem GEN3RATOR wiederverwenden",
    "Confirm": "Bestätigen",
    "Do you reallly want to claim your {tokenName} reward?": "Willst du wirklich deinen {tokenName} Reward claimen?",
    "Once claimed the GEN3RATOR can not claim this reward again": "Nach dem Claim kann der GEN3RATOR diesen Reward nicht erneut claimen",
    "Depending on the reward you need to enter different contact information": "Abhängig vom Reward musst du unterschiedliche Kontaktinformationen angeben.",
    "Claiming your Reward": "Dein Reward wird geclaimed",
    "Your reward is being claimed. Sit tight!": "Dein Reward wird gerade geclaimed. Bitte hab etwas Geduld!",
    "This transaction takes longer than usual.": "Diese Transaktion dauert länger als üblich.",
    "Reward claimed!": "Dein Reward wurde geclaimed!",
    "Congratulations! Your reward has been successfully claimed. However, we need some information from you to complete the process. Please continue.": "Glückwunsch! Dein Reward wurde erfolgreich geclaimed. Allerdings benötigen wir noch einige Informationen von dir, um den Prozess abzuschließen. Bitte fahre fort.",
    "Something unexpected happened, please restart the service and view the error.": "Es ist etwas Unerwartetes passiert, bitte starten Sie den Dienst neu und sehen Sie sich den Fehler an.",
    "Submitting Form": "Formular wird übermittelt",
    "Kindly wait while we get the last step to finish": "Bitte warten, während wir den letzten Schritt abschließen",
    "Attach GEN3 Chip to your GEN3RATOR": "Verbinde einen GEN3 Chip mit deinem GEN3RATOR",
    "Select one of your GEN3 Chips you want to attach": "Wähle einen deiner GEN3 Chips, den du verbinden möchtest",
    "Attach now": "Jetzt verbinden",
    "Attaching GEN3 Chip": "GEN3 Chip wird verbunden",
    "The chosen GEN3 Chip is being attached to your GEN3RATOR. In just a moment, your GEN3RATOR will be activated!": "Der ausgewählte GEN3-Chip wird gerade mit deinem GEN3RATOR verbunden. Dein GEN3RATOR wird jeden Moment aktiviert sein!",
    "GEN3 Chip Attached!": "GEN3 Chip Verbunden!",
    "GEN3 Chip was successfully attached to your GEN3RATOR": "Der GEN3 Chip wurde erfolgreich mit deinem GEN3RATOR verbunden.",
    "Attaching successful": "Verbinden erfolgreich",
    "Congratulations! Your GEN3 Chip is now attached to your GEN3RATOR": "Glückwunsch! Dein GEN3 Chip ist jetzt mit deinem GEN3RATOR verbunden",
    "Once claim window is open use your activated GEN3RATOR to claim your rewards": "Wenn das Zeitfenster für den Claim geöffnet ist, kannst du mit deinem aktivierten GEN3RATOR deinen Reward claimen",
    "Claiming is only possible if you hold the activated GEN3RATOR": "Claiming ist nur möglich, wenn du den aktivierten GEN3RATOR besitzt",
    "You can detach your GEN3 Chip no earlier than 24 hours after attaching it": "Du kannst den GEN3 Chip frühestens 24 Stunden nach Verbinden wieder trennen",
    "inputType.physicalAddress": "Glückwunsch, dein Claim war erfolgreich! Um eine reibungslose Lieferung sicherzustellen, gib bitte deine Versandadresse unten ein. Achte darauf, dass dein Name an der angegebenen Adresse deutlich sichtbar ist und dass du Pakete empfangen kannst. Sobald du deine Versanddaten noch einmal überprüft hast, sende sie bitte ab.\n\nWir werden deine Daten ausschliesslich für die Zustellung verwenden.",
    "inputType.walletAddress": "Glückwunsch, dein Claim war erfolgreich! Um sicherzustellen, dass Du dein digitales NFT einfach und reibungslos erhältst, benötigen wir deine öffentliche Wallet-Adresse eines Ethereum / Polygon Wallets. Wir airdoppen dein NFT an diese Adresse. Bitte stelle daher sicher, dass du ein Wallet angibst, mit dem du NFTs erhalten und transferieren kannst, Zugriff auf dieses Wallet hast und dass es die Adresse ist, an die dein claimed Token gesendet werden soll.\nÜberprüfe unbedingt, ob dein Wallet-Adresse korrekt ist, da alle an eine falsche Adresse gesendeten Tokens nicht wiederhergestellt werden können.",
    "inputType.imxPassportAddress": "Herzlichen Glückwunsch, dein Claim war erfolgreich!\n\nIlluvium läuft auf der Immutable X-Blockchain. Um deine Assets zu erhalten, benötigst Du eine gültige Immutable Passport (IMX Passport)-Adresse. Um eine zu erstellen, besuche illuvium.io. Überprüfe unbedingt, ob die Adresse korrekt ist, da alle an eine falsche Adresse gesendeten Artikel nicht wiederhergestellt werden können.",
    "IMX Passport Address": "IMX Passport Address",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    "ZIP or postal code": "Postleitzahl",
    "Detach possible in": "Trennen möglich in",
    "Manage your GEN3RATORs and attached GEN3 Chips": "Manage deine GEN3RATORs und verbundenen GEN3 Chips",
    "You don't have any GEN3RATORs yet": "Du hast noch keine GEN3RATORen",
    "These are your owned unattached GEN3 Chips": "Das sind unverbundene GEN3 Chips die du besitzt",
    "You don't own any unattached GEN3 Chips": "Du besitzt keine unverbundenen GEN3 Chips",
    "Connect your wallet to view your GEN3RATORs and GEN3 Chips": "Verbinde dein Wallet um deine GEN3RATORs und GEN3 Chips zu sehen",
    "You successfully minted the {tokenTitle} Token.": "Du hast den Token {tokenTitle} erfolgreich geminted",
    "You can see it in your Wallet": "Du kannst es in deiner Wallet sehen",
    "Check your transaction on Polygonscan": "Check deine Transaktion auf Polygonscan",
    "Check your transaction on Etherscan": "Check deine Transaktion auf Etherscan",
    "Validating Voucher Code": "Gutscheincode validieren",
    "We are validating your voucher code.": "Wir validieren deinen Gutscheincode.",
    "Validating Allowlist": "Überprüfung der Allowlist",
    "We are validating that your wallet is in the allowlist.": "Wir validieren deinen Gutscheincode.",
    "Generating Airdrop": "Airdrop generieren",
    "The token is going to be airdropped to your wallet.": "Der Token wird in deine Wallet geairdropped",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "Die Transaktion wurde an die Blockchain gesendet und wir warten auf das Ergebnis. Bitte habe Geduld, dies kann einige Zeit dauern",
    "Something went wrong minting your token": "Beim Minten deines Tokens ist etwas schief gelaufen",
    "We could not mint your Token!": "Wir konnten dein Token nicht minten!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.": "Leider ist beim Absenden deiner Transaktion ein Fehler aufgetreten. Bitte versuche es erneut. Wenn der Fehler weiterhin besteht, kontaktiere uns bitte unter minting.gen3@samsung.com und gebe die vollständige Fehlermeldung an.",
    "Something went wrong validating": "Bei der Validierung ist etwas schief gelaufen",
    "We could not validate your code!": "Wir konnten Ihren Code nicht validieren!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.": "Leider konnten wir deinen Gutscheincode nicht validieren. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere uns bitte unter minting.gen3@samsung.com und gebe die vollständige Fehlermeldung an.",
    "Info": "Info",
    "free": "free",
    "Total Minted": "Total Minted",
    "Blockchain": "Blockchain",
    "Token Standard": "Token Standard",
    "You have to select first the Artist you want to mint. Choose wisely, this can't be changed!": "Du musst zu erste inen Künstler den du minten möchtest auswählen. Die Auswahl kann nicht verändert werden.",
    "You don't have a code yet? Find out more here.": "Du hast noch keinen Code? Hier erfährst du mehr darüber.",
    "Don't have a code for the free mint yet Here is how you can get your NFT or a code?": " Du hast noch keinen Code für den free Mint? Erfahre hier, wie du dein NFT oder deinen persönlichen Code erhalten kannst?",
    "Don't have a code for the MX1 ART COLLECTION MINT?": "Du hast noch keinen Code für den MX1 ART COLLECTION MINT?",
    "mintpass.tx1.genesis.nocodemodal.header": "Du hast noch keinen Code für den free Mint? Erfahre hier, wie du dein NFT oder deinen persönlichen Code erhalten kannst?",
    "Regardless of our minting windows, you always have the option to buy the MX1GE or MX1AC NFT on the secondary market. For example on OpenSea. Enclosed the original link: LINK FOLLOWS<br />In the first phase of the project from 01.16 - 01.02 you had the possibility to get a code by registering on Amazon.<br />You missed this window? No big deal :-)<br />We will provide our partner Ledger and our Artist with additional codes to give out for free. Our tip: Keep an eye on the communication channels of Ledger and the Artists.": "Unabhängig von unseren Minting Zeitfenstern, hast du immer die Möglichkeit das MX1GE oder MX1AC NFT auf dem Zweitanbietermarkt zu kaufen. Zum Beispiel auf OpenSea. Anbei der Originallink: <a href='https://opensea.io/collection/samsung-mx1-genesis-edition' target='_blank' rel='noreferrer'>https://opensea.io/collection/samsung-mx1-genesis-edition</a><br />\nIn der ersten Phase des Projekts vom 01.16. - 01.02 hattest du die Möglichkeit durch Anmeldung auf Amazon einen Code zu erhalten.<br />\nDu hast dieses Zeitfenster verpasst? Nicht schlimm :-)<br />\nWir werden unserem Partner Ledger und unseren Artist zusätzliche Codes zur kostenlosen Ausgabe zur Verfügung stellen. Unser Tipp: Behalte die Kommunikationskanäle von Ledger und den Artists im Auge.",
    "The Codes for the MX1AC created by Antoni Tudisco, Bruno Urli, Charlotte Taylor and Anthony Authié is only available for users that have pre-ordered the Samsung Galaxy S23 through Amazon in the time from 01.02.2023 until 16.02.2023.<br /><br />If you hold a Samsung MX1 GENESIS EDITION Community Token NFT during the Snapshot at 14.02.2023 11PM CET you are eligible for the Raffle which includes the chance to win a MX1AC NFT randomly. There will be also the chance to get a free-code from the Artists or Ledger.": "Die von Antoni Tudisco, Bruno Urli, Charlotte Taylor und Anthony Authié erstellten Codes für das MX1AC sind nur für Nutzer verfügbar, die das Samsung Galaxy S23 in der Zeit vom 01.02.2023 bis zum 16.02.2023 über Amazon vorbestellt haben.<br /><br />\nWenn Sie einen Samsung MX1 GENESIS EDITION Community Token NFT während des Snapshots am 14.02.2023 11PM CET besitzen, sind Sie berechtigt, an der Verlosung teilzunehmen, die die Chance beinhaltet, einen MX1AC NFT nach dem Zufallsprinzip zu gewinnen. Außerdem haben Sie die Chance, einen kostenlosen Code von den Artists oder Ledger zu erhalten.",
    "mintpass.tx1.genesis.nocodemodal.body": "Unabhängig von unseren Minting Zeitfenstern, hast du immer die Möglichkeit das TX1 NFT auf dem Zweitanbietermarkt zu kaufen. Zum Beispiel auf OpenSea. <br />\nUm einen Code zu Minten des Samsung TX1- Token zu erhalten, musst du dich zwischen dem 11.04.2024 und 24.04.2024 auf samsung.com/de oder gen3.samsung.com für eine E-Mail-Benachrichtigung zur Teilnahme an der TX1 Kampagne registriert und eine E-Mail Benachrichtigung zur Registrierung zu dieser Teilnahme mit Aktionscode erhalten haben.",
    "Please enter your unique Mint Voucher": "Bitte gebe deinen einmaligen Mint-Gutschein ein",
    "e.g. CODE123 (required)": "Z.B. CODE123 (erforderlich)",
    "You are using the wrong voucher code. Please click on the link below to learn where you can find your personal code.": "Du verwendest den falschen Code. Bitte klicke auf den nachfolgenden Link um zu erfahren wie du an deinen personlichen Code kommst.",
    "It looks like you want to use a mint code for a different page.": "Es sieht aus, als hättest du den Mint Code für eine andere Seite verwendet.",
    "Click here to go to correct page": "Hier klicken um zur richtigen Seite zu kommen.",
    "You need to connect your Wallet to be able to mint.": "Du musst dein Wallet connecten, um minten zu können.",
    "You are asked to sign a message to generate a secure login connection for your Wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Du wirst gebeten, eine Nachricht zu unterschreiben, um eine sichere Login-Verbindung für deine Wallet zu generieren. Wir werden keinen Zugriff auf deine Guthaben und NFTs haben oder irgendwelche Aktivitäten mit diesen Informationen durchführen.",
    "MX1 ART Collection": "MX1 ART Collection",
    "Minting starts Friday evening, 17. Februar.": "Minting startet am Freitag, den 17. Februar, abends.",
    "The Samsung MX1 ART COLLECTION includes 9 different artpieces of 4 well known artists form the segments fashion and 3-motion, photography and architecture and interior design.  Artists: Antoni Tudisco, Bruno Urli, Charlotte Taylor, Anthony Authié and Nico Chiara. 100% royalty fees will be provided to artists of the pieces.": "Die Samsung MX1 ART COLLECTION beinhaltet 9 verschiedene Kunstwerke von 4 namhaften Künstlern aus den Bereichen Mode und 3-Motion, Fotografie und Architektur und Interior Design. Künstler: Antoni Tudisco, Bruno Urli, Charlotte Taylor, Anthony Authié und Nico Chiara. Die Künstler der Werke erhalten 100 % der Royalty Fees.",
    "Mint starts soon": "Mint startet bald!",
    "If you ordered the limited Samsung x Ledger web3 starter bundle or the Samsung Galaxy S23 between 01. Feb - 17. Feb 2023 via Amazon you will receive a unique mint voucher code.": "Wenn du zwischen dem 01. und 16. Februar 2023 das limitierte Samsung x Ledger web3 Starter Bundle oder das Samsung Galaxy S23 über Amazon bestellst, erhältst du einen einmaligen Mint-Code.",
    "Use this code to claim your Samsung MX1 ART Collection NFT of your favorite Artist.": "Verwende diesen Code, um dir dein Samsung MX1 ART Collection NFT deines Lieblingskünstlers zu sichern.",
    "You want to bookmark upcoming Samsung mints? Add them to your calendar here.": "Füge den Samsung MX1 Art Collection Mint deinem Kalender hinzu.",
    "Bookmark Mint Calendar": "Bookmark Mint Datum",
    "Where can I get my code?": "Wo kann ich meinen Code bekommen?",
    "This is how you obtain a MX1 Genesis Edition Code": "So erhältst du einen MX1 Genesis Edition Code",
    "This is how you obtain a MX1 Art Collection Code": "So erhältst Du einen MX1 Genesis Edition Code",
    "bundle.title": "Samsung x Ledger Partnerschaft",
    "bundle.text": "Sichere dir dein Samsung x Ledger Nano X*, um deine Kryptowährungen und NFTs sicher verwalten zu können. Verwende deinen persönlichen Promo-Code, den du mit dem Web3 Starter Bundle erhalten hast, um deinen Ledger Nano X zu erhalten inklusive zeitlich limitiertem Ledger Nano X packaging.",
    "Terms apply": "Aktionsbedingungen gelten",
    "bundle.button": "Jetzt vorbestellen",
    "teaser.tvbundle.title": "Samsung web3 TV Bundle",
    "teaser.tvbundle.copy": "Die Reise geht weiter: Hol dir dein Samsung x Ledger Nano X für sicheres Krypto- und NFT-Management. Verwende den exklusiven Promo-Code, der deinem Sero- oder Frame TV im Samsung web3 TV Bundle* beiliegt, um diesen Ledger Nano X zu erwerben. Er verfügt über ein Standardmodell sowie eine spezielle Limited-Edition-Verpackung. Als zusätzlichen Bonus erhältst du auch einen der limitierten Samsung GEN3RATORs.",
    "teaser.tvbundle.cta": "Sichere dir dein Bundle jetzt",
    "Congratulations": "Glückwunsch",
    "Raffle Winners": "Gewinner",
    "faq.roadmap.ninth.answer": "<p>Preise: 6x Samsung x Ledger web3 starter Bundle, bestehend aus: einem brandneuen Samsung Galaxy S23 aus der aktuellen Produkteinführung und einem Ledger Nano X Hardware Wallet.\n\n<br />\n<br />\n200 x zufällige Samsung MX1 ART COLLECTION NFTs aus PHASE 2, erstellt von Antoni Tudisco, Bruno Urli, Charlotte Taylor und Anthony Authié\n</p>",
    "Click here to check MX1 GE Winner Tokens": "Hier klicken um den MX1 GE Gewinner Tokens zu prüfen",
    "Register now on Amazon": "Jetzt auf Amazon unverbindlich registrieren",
    "Register now without obligation on the Amazon promotion page and get your Samsung MX1 Genesis Edition NFT free mint.": "Registriere dich jetzt unverbindlich auf  unserer Amazon Seite und sicher dir den kostenlosen Samsung MX1 Genesis Endition NFT.",
    "Register Now": "Jetzt registrieren",
    "more": "mehr",
    "Tier Details": "Tier Details",
    "artist.jaiden.title": "Jaiden \nStipp",
    "artist.specter.title": "Specter \nBerlin",
    "artist.plastic_pen.title": "Stefan \nGrosse  \nHalbuer",
    "artist.thomas.title": "Thomas \nFleck",
    "artist.tudisco.title": "Antoni \nTudisco",
    "artist.bruno.title": "Bruno \nUrli",
    "artist.charlotte.title": "Charlotte \nTaylor",
    "artist.authie.subtitle": "Anthony Authié - die Kunst der Architektur",
    "artist.authie.title": "Anthony \nAuthié",
    "artistIntro.genesis.subtitle": "Der erste Samsung-Community-Token mit zukünftiger Utility",
    "artistIntro.genesis.title": "Samsung MX1 GENESIS EDITION NFT",
    "artistIntro.genesis.smallText": "Entdecke das MX1GE NFT, gestaltet durch die folgenden Artists:",
    "artistIntro.genesis.bigText": "Thomas Fleck [Deutschland], Jaiden Stipp [USA], Stefan Grosse Halbuer [Deutschland] and Specter [Deutschland]. Jeder Artist hat die persönliche Reise seines Lebens auf  creative und intime Art und Weise interpretiert. Das MX1GE NFT bietet für Besitzer die Möglichkeit künftige Utilities von Samsung erhalten.",
    "artistIntro.art.subtitle": "Ein der schönsten Facetten der Welt: KUNST",
    "artistIntro.art.title": "SAMSUNG MX1 ART COLLECTION NFT ARTISTS",
    "artistIntro.art.smallText": "Wir haben für euch eine tolle Mischung aus den derzeit meistgehypten Künstlern zusammengestellt.",
    "artistIntro.art.bigText": "Digitale Kunstwerke von weltbekannten 3D-Künstler*innen, wie „NFT-OG“ Antoni Tudisco, Charlotte Taylor, Anthony Authié und dem Wunderkind der NFT-Fotografie, dem 18 Jahre jungen Bruno Urli.",
    "Highlights": "Highlights",
    "Raffles": "Gewinnspiele",
    "Deals": "Angebote",
    "Holder Benefits": "Holder Benefits",
    "benefits.intro.subtitle": "GEN3 COD3X",
    "benefits.intro.title": "Exklusive Vorteile für Mitglieder",
    "benefits.intro.smallText": "Mehr als nur NFTs: Unser Programm mit exklusiven Gewinnspielen und Vorteilen nur für Mitglieder im Samsung GEN3 Ökosystem.",
    "benefits.intro.bigText": "Mit einer Mischung aus regelmäßigen kleinen Vergünstigungen und großen Aktionen. Von alltäglichen Goodies bis hin zu großen Veranstaltungen sind diese Vorteile darauf ausgerichtet, dich als Teil von Samsung GEN3 zu belohnen. Schaue regelmäßig vorbei – es gibt immer etwas Neues zu entdecken.",
    "There aren't raffles available at this time.": "Derzeit gibt es keine Gewinnspiele.",
    "There aren't deals available at this time.": "Derzeit gibt es keine Angebote.",
    "Cookies": "Cookies",
    "Introduction": "Einführung",
    "Any   functional   non-fungible   token ('NFT')   running   on   a   blockchain, which can be or was generated or issued   ('Minted')   on   this   website ('SAMSUNG   NFT')   and   is   lawfully owned,   and   the   underlying   digital artwork or digital collectible linked to   them   ('Art')   are   subject   to   the following   terms   and   conditions ('these   T&Cs'),   provided   by  [full corporate name of Samsung entity running   the   project   and   the website]  ('Samsung'   or   'us'   or 'we').": "",
    "Website as interface": "",
    "This   website   is   not   on   the blockchain but acts as interface for the   user   (“user”   or   “you”)   to process the Minting of a SAMSUNG NFT   and   interact   with   the   related smart   contract,   and   for   secondary purchasers   of   a   SAMSUNG   NFT,   to access   these   T&Cs.   By   using   this website   and/or   by   Minting   or purchasing or selling (or by offering to   purchase   or   sell)   a   SAMSUNG NFT   and/or   the   underlying   Art   on any   platform   and/or   by   holding   a SAMSUNG   NFT   in   your   wallet,   you consent   to   the   terms   set   forth herein.": "",
    "Risk   assumption   /   your responsibility": "",
    "Minting and transactions": "Mining und Transaktionen",
    "You   as   the   person   Minting   can process   the   transaction   through this   website   by   connecting   your crypto   wallet   and   performing   and confirming   the   transactions indicated in your wallet. You accept and   acknowledge   that   we   are   not responsible   for   the   validation   and confirmation   of   any   transaction   or contract   through   your   own   wallet, but that this happens at your own judgment   and   under   your   own control.   Transactions   on   the blockchain   sometimes   go   wrong and get stuck, while the underlying transaction costs  (“Gas Fees”) still apply   and   can   be   irrevocably   lost, even   in   a   broken   transaction. Samsung does not take any liability in such case. Notwithstanding that, Samsung   may   offer   but   has   no obligation   to   do   so,   to   cover   Gas Fees   related   to   the   Minting   of   a SAMSUNG NFT.": "",
    "Raffle": "Gewinnspiel",
    "You are registered for this event": "Du bist für das Event angemeldet",
    "Event Type": "Eventtyp",
    "Start Date": "Startdatum",
    "End Date": "Enddatum",
    "Sign up for this event": "Melde dich for dieses Event an",
    "This event has ended.": "Das Event ist vorbei.",
    "Unlocking Content": "Unlocking Content",
    "You have signed up for this event": "Du hast dich für dieses Event angemeldet",
    "View event": "Event anzeigen",
    "There was a problem while signing up for this event.": "Es ist ein Problem aufgetreten bei der Anmeldung für dieses Event.",
    "There was a problem showing this event. Please contact support on discord": "Es gab ein Problem beim Laden des Events. Bitte wende dich an unseren Support.",
    "Events": "Events",
    "Upcoming": "Bevorstehend",
    "Completed": "Abgeschlossen",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "Es gab ein Problem bei der Auflistung aller Inhalte. Bitte kontaktiere den Support auf Discord",
    "General": "Allgemein",
    "Page not found": "Seite nicht gefunden",
    "You are not authorized to see this page!": "Du bist nicht berechtigt, diese Seite zu sehen!",
    "Please sign in with Wallet first": "Bitte melde Dich erst mit Deinem Wallet an",
    "You have to be a holder to view this page": "Du musst einen Token haben um diese Seite anzusehen",
    "Become a Holder": "Werde ein Holder",
    "Back to Home": "Zurück zu Home",
    "teaser.gen3rator.title": "Bereit für den GEN3RATOR?",
    "teaser.gen3rator.shortdescription": "Bist du bereit, tief in das Web3-Universum von Samsung einzutauchen? Der GEN3RATOR ist unser neuestes und innovativstes Tool, das zahlreiche aufregende Funktionen auf der Samsung web3-Roadmap freischaltet. Wie kannst du dir dieses Must-Have sichern?",
    "teaser.gen3rator.subtitle": "Tauche ein ins Web3-Universum von Samsung: Bereit für neue Entdeckungen?",
    "teaser.gen3rator.copy": "Der GEN3RATOR ist unser neuestes und innovativstes Werkzeug, das zahlreiche aufregende Funktionen auf der Samsung web3-Roadmap freischaltet. Wie kannst du dir diesen unverzichtbaren Gegenstand sichern? Du kannst entweder an aufregenden Raffles von Samsung oder exklusiven Partnerkollektionen teilnehmen, deine bestehenden Samsung-Tokens in spezifischen Kombinationen einbringen, an aufregende X Spaces teilnehmen oder deinen GEN3RATOR mit dem Kauf eines Sero- oder Frame-TV x Ledger-Bundles sichern. Deine Optionen sind vielfältig. Mach dich bereit, wenn wir im Mai 2024 eine neue Ära der digitalen Revolution einläuten!",
    "teaser.tvapp.title": "Samsung NFT Gallery App",
    "teaser.tvapp.copy": "Verwandle deinen Fernseher mit der NFT Galerie App in eine dynamische NFT-Galerie. Verbinde deine digitale Geldbörse und bringe deine NFT-Sammlung sofort auf dem großen Bildschirm zum Leben. Erlebe deine digitale Kunst in atemberaubender Klarheit und mache jede Ansicht zu einem einzigartigen Erlebnis. Veredle dein Zuhause mit der Zukunft der Kunstpräsentation, in der dein Fernseher nicht nur intelligent ist, sondern auch kunstintelligent.",
    "teaser.tvapp.cta": "Mehr Erfahren",
    "teaser.benefits.title": "Profitiere von Benefits im Samsung GEN3 Ecosystem",
    "teaser.benefits.copy": "Mach dich bereit, das Samsung GEN3 Ecosystem neu zu erleben und eröffne mit unseren Collectibles eine Welt voller Möglichkeiten. Kombiniere geschickt die Kunst des Sammelns mit exklusiven Vorteilen. Dies ist nur ein kleiner Vorgeschmack darauf, wie sich das Samsung GEN3 Ecosystem in Zukunft entwickeln wird - stay tuned.",
    "teaser.benefits.cta": "Erfahre mehr über Holder Benefits",
    "teaser.tip.headline": "Wie du an Samsung NFTs kommst und sie optimal nutzt",
    "teaser.partner.title": "Lern unsere Collab-Partner kennen",
    "teaser.partner.copy": "Entdecke die inspirierenden Kollaborationen, mit denen wir unsere Vision von Innovation und Kreativität zum Leben erwecken. Erfahre mehr über unsere Partner und ihre faszinierenden Produkte, die das Samsung GEN3 Ecosystem antreiben und unvergleichliche Membership Experiences schaffen.",
    "teaser.partner.cta": "Jetzt erkunden!",
    "Samsung Electronics GmbH \nSamsung House \nAm Kronberger Hang 6 \n65824 Schwalbach / Ts. \nDeutschland": "Samsung Electronics GmbH\nSamsung House\nAm Kronberger Hang 6\n65824 Schwalbach / Ts.\nDeutschland",
    "Commercial register: Amtsgericht Königstein, registration number: HRB 4657 \nTAX ID.: DE811710402": "Handelsregister: Amtsgericht Königstein, Registernummer: HRB 4657\nUST-ID.: DE811710402",
    "Managing Director: Man Young Kim": "Geschäftsführer: Man Young Kim",
    "Responsible for content according to § 18 Abs. 2 MStV: Silke Cosetta, address see above.": "Inhaltlich Verantwortliche nach § 18 Abs. 2 MStV: Silke Cosetta, Anschrift s.o.",
    "Hotline for general inquiries: 06196-7755577* \n*Costs according to the conditions of the contract partner for fixed network connections or mobile phone connections.": "Hotline bei allgemeinen Anfragen: 06196-7755577*\n*Kosten laut Konditionen des Vertragspartners für Festnetzanschlüsse oder Mobilfunkanschlüsse.",
    "For general inquiries please contact: kundenbetreuung[at]samsung.de": "Bei allgemeinen Anfragen wenden Sie sich bitte an: kundenbetreuung[at]samsung.de",
    "For General Samsung gen3 support please contact: support.gen3[at]samsung.com": "Bei allgemeinen Fragen zu Samsung gen3, wenden Sie sich an: support.gen3[at]samsung.com",
    "For Samsung minting support please contact: minting.gen3[at]samsung.com": "Bei Fragen zum Samsung Minting, wenden Sie sich an: minting.gen3[at]samsung.com",
    "All contact options for your product or service inquiries can be found here: \nhttps://www.samsung.com/de/support/#contact": "Alle Kontaktmöglichkeiten für Ihre Produkt- oder Service-Anfragen finden Sie hier:\nhttps://www.samsung.com/de/support/#contact",
    "Notice according to § 36 Verbraucherstreitbeilegungsgesetz (VSBG): \nSamsung Electronics GmbH will not participate in any dispute resolution proceedings before a \nconsumer arbitration board within the meaning of the VSBG and is not obliged to do so.": "Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG):\nDie Samsung Electronics GmbH wird nicht an einem Streitbeilegungsverfahren vor einer\nVerbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und ist hierzu auch nicht verpflichtet.",
    "generators.header.title": "SAMSUNG GEN3RATOR & GEN3 Chip",
    "generators.header.description": "Tauche ein in Samsungs Web3-Universum, indem du dir einen unserer exklusiven GEN3RATORs sicherst. Gemeinsam mit Web3 Ecosystem Partnern haben wir einen einzigartigen Mechanismus geschaffen, um weitere Belohnungen und Vorteile freizuschalten.\n\nDer Mint startet im Mai 2024.",
    "Contract": "Contract",
    "generators.nocodemodal.header": "Du hast noch keinen Code für den Mint? Erfahre hier, wie du dein NFT oder deinen persönlichen Code erhalten kannst?",
    "generators.nocodemodal.body": "Unabhängig von unseren Minting Zeitfenstern, hast du immer die Möglichkeit ein GEN3RATOR oder GEN3 NFT auf dem Zweitanbietermarkt zu kaufen. Zum Beispiel auf OpenSea.<br />\n<br />\nWährend des Aktionszeitraums vom 24. April bis zum 30. September 2024, 23:59 Uhr, ist es möglich, GEN3RATOR- und GEN3-Chip-Minting-Codes zu erhalten, indem Du eines der 1.500 Samsung Web3 TV-Bundles über den Samsung Online Shop kaufst. Detaillierte Teilnahemebedingungen findest Du hier (Link: <a href=\"https://gen3.samsung.com/de/disclaimer_tv_bundle\" target=\"_blank\" rel=\"noreferrer\">https://gen3.samsung.com/de/disclaimer_tv_bundle</a>).<br />\n<br />\nDu hast das Angebot verpasst? Kein Problem.<br />\nHolen Dir einen GEN3RATOR- und/oder GEN3-Chip-Minting-Codes als Reward für unsere Holder auf der Holder Benefits (Link: https://gen3.samsung.com/de/community/benefits) Seite.\nDarüber hinaus werden wir unseren großartigen Partnern (Link: <a href=\"https://gen3.samsung.com/de/community/collabs\" target=\"_blank\" rel=\"noreferrer\">https://gen3.samsung.com/de/community/collabs</a>) zusätzliche Codes zur Verfügung stellen, die kostenlos weitergegeben werden können. Unser Tipp: Behalte die Kommunikationskanäle unserer Partner im Auge.",
    "generators.howto.title": "So kommst du an deinen GEN3RATOR und Samsung GEN3 Chip",
    "generators.howto.copy": "Um Zugang zum GEN3RATOR und dem erforderlichen Samsung GEN3 Chip zu erhalten, stehen dir drei Hauptwege offen:<br />\n<br />\n1.) Kauf des Samsung web3 TV Bundles: Mit dem Erwerb des Samsung web3 TV Bundles erhältst du automatisch sowohl den GEN3RATOR als auch den GEN3 Chip als NFTs, die für die Aktivierung nötig sind.<br />\n2.) Holder Benefits: Als engagiertes Mitglied der Samsung-Community kannst du durch Teilnahme an bestimmten Aktionen und durch dein Engagement Chancen auf den Erhalt des GEN3RATORs und Chips haben.<br />\n3.) Partneraktionen: Halte die Augen nach speziellen Promotions unserer Partner Kollektionen offen, die dir die Möglichkeit bieten, einen GEN3RATOR oder Chip zu gewinnen.<br />\n<br />\nDu musst beide NFTs kombinieren, nur so aktivierst du deinen GEN3RATOR, was dir wiederum den Zugang zu exklusiven NFTs und anderen Vorteilen ermöglicht. Halte deinen GEN3RATOR und Chip stets in deiner Wallet, um alle zukünftigen Vorteile nutzen zu können.",
    "Minting Section": "Minting Bereich",
    "Please connect your wallet": "Bitte verbinde deine Wallet",
    "Terms and Conditions": "Geschäftsbedingungen",
    "Deal": "Angebot",
    "Your Unlocked Codes": "Deine Freigeschaltenes Codes",
    "The content has been unlocked successfully": "Der Inhalt wurde erfolgreich freigeschalten",
    "View content": "Inhalt anzeigen",
    "There was a problem unlocking this unlockable content.": "Es ist ein Problem beim Freischalten des Inhalts aufgetreten.",
    "There was a problem showing this unlockable content. Please contact support on discord": "Es gab ein Problem beim Laden des Inhaltes. Bitte wende dich an unseren Support.",
    "deals.title": "Exklusiver Samsung x Ledger Deal für MX1-Token-Holder",
    "deals.subtitle": "Sichere dir jetzt deinen Ledger Nano X 60€-Gutschein für MX1GE-Holder, kostenlos für Käufer des S23 mit MX1AC*",
    "deals.paragraph.one": "Wir freuen uns, unseren MX1-Token-Holdern einen epische Partner Deal ankündigen zu können. Im Rahmen dieses legendären Angebots bist du berechtigt, folgenden Benefits freizuschalten:",
    "deals.paragraph.two": "<ul>\n  <li>Einen Gutscheincode im Wert von 60€ zum Kauf eines Samsung x Ledger Nano X. Dieses Angebot steht exklusiv unseren MX1GE-Token-Holdern zur Verfügung und bietet eine perfekte Gelegenheit, deine wertvollen digitalen Vermögenswerte zu schützen.</li>\n  <li>*Zusätzlich kannst du dir als MX1AC-Token-Holder, der das Samsung Galaxy S23 über Amazon erworben hat, einen 100%igen Rabattcode freischalten, der es dir ermöglicht, einen Samsung x Ledger Nano X kostenlos zu erwerben!</li>\n</ul>",
    "deals.paragraph.three": "Starte deine sichere Krypto-Reise mit Ledger, einem führenden Anbieter von Hardware-Wallets für Krypto-Vermögenswerte. Der Ledger Nano X ist das perfekte Gerät zur sicheren Verwaltung deiner digitalen Vermögenswerte. Mit über 5.500 unterstützten Coins und Tokens, darunter Bitcoin, Ethereum, XRP und mehr, kannst du sicher sein, dass dein gesamtes Portfolio geschützt und abrufbar ist.\nWenn du Krypto besitzt, musst du deinen Private-Key sicher aufbewahren. Mit Ledger-Wallets kannst du sicher sein, dass dein Schlüssel durch branchenführende Security-Features geschützt ist. Dies bedeutet, dass du deine Krypto-Reise sicher beginnen und deine NFTs mit unserer neuen Bluetooth-fähigen Hardware-Wallet sicher verwalten kannst.",
    "Unlock your personal voucher code": "Schalte deinen persönlichen Gutscheincode frei",
    "There are currently no deals for you to unlock.": "Derzeit gibt es keine Angebote, die Sie freischalten können.",
    "To get your voucher code, simply connect your wallet and unlock the \"partner deal\" with one of your existing Samsung MX1GE token. Once you have done this, the voucher code will be shown to you (note that it will only be shown once) and shared with you via email. You can then use this code to purchase your Ledger Nano X at a discounted price.": "Um Ihren Gutscheincode zu erhalten, verbinden Sie einfach Ihre Brieftasche und entsperren Sie den “Partner-Deal” mit einem Ihrer bestehenden Samsung MX1GE-Token. Sobald Sie dies getan haben, wird Ihnen der Gutscheincode angezeigt (beachten Sie, dass er nur einmal angezeigt wird) und per E-Mail an Sie weitergegeben. Mit diesem Code können Sie dann Ihren Ledger Nano X zu einem vergünstigten Preis kaufen.",
    "*Please be aware that not every MX1GE token can unlock a voucher code. Only users who had one or more MX1GE tokens in their wallet at the specified snapshot on 07.05.2023 are eligible to unlock a voucher code using one (!) of their tokens. It's important to note that owning multiple tokens does not guarantee the ability to unlock an equivalent number of vouchers.": "*Bitte beachten, dass nicht jeder MX1GE-Token einen Gutscheincode freischalten kann. Nur Holder, die zum Snapshot am 07.05.2023 einen oder mehrere MX1GE-Token in ihrer Wallet hatten, sind berechtigt, einen Gutscheincode mit einem (!) ihrer Token freizuschalten. Bitte beachte, dass der Besitz mehrerer Token nicht garantiert, eine entsprechende Anzahl von Gutscheinen freizuschalten.",
    "There was a problem listing all the partner deals. Please contact support.gen3@samsung.com.": "Es gab ein Problem bei der Auflistung aller Partner-Deals. Bitte kontaktieren Sie support.gen3@samsung.com.",
    "Successfully Unlocked!": "Erfolgreich freigeschalten!",
    "There was a problem unlocking this Partner Deal. Please contact support.gen3@samsung.com.": "Es gab ein Problem bei der Freischaltung dieses Partner-Deals. Bitte kontaktieren Sie support.gen3@samsung.com.",
    "partners.ledger.subtitle": "Hardware- & Offline-Wallet",
    "partners.ledger.title": "Ledger",
    "partners.ledger.description": "Im Jahr 2024 feiert Ledger sein 10-jähriges Jubiläum und ist weltweit führend in der Sicherheit digitaler Vermögenswerte für Verbraucher und Unternehmen. Ledger bietet Connected Devices und Plattformen an, die in über 10 Sprachen in 200 Ländern verkauft wurden, mit mehr als 6 Millionen Geräten, sowie Partnerschaften mit über 100 Finanzinstituten und Handelsmarken. Über 20% der weltweiten Krypto-Vermögenswerte sind durch Ledger gesichert.",
    "partners.ledger.modal.subHeader": "Marktführer bei Hardware-Wallets und Kryptosicherheit",
    "partners.ledger.modal.headline": "Ledger",
    "partners.ledger.modal.text": "Samsung und Ledger erfreuen sich einer langjährigen Partnerschaft, die ihre Stärke in der Bereitstellung von Sicherheit für Web3-Anwendungen zeigt. Mit einem starken Fokus auf die Sicherheit im aufkeimenden Web3-Sektor legt Samsung großen Wert auf die Aufklärung seiner Kunden in diesem Bereich. Dabei ist es entscheidend, einen erfahrenen und spezialisierten Partner wie Ledger an der Seite zu haben, der bewährtes Know-how in der Sicherheitstechnologie mitbringt. Diese Kooperation unterstreicht Samsungs Engagement, seine Kunden durch verlässliche Sicherheitslösungen auf dem neuesten Stand der Technik zu halten.",
    "partners.ledger.link": "https://www.ledger.com/",
    "partners.wiami.subtitle": "Eine neue Dimension der Realität",
    "partners.wiami.title": "Wilder World",
    "partners.wiami.description": "WIRE IN\nWilder World ist ein massives Multiplayer-Metaverse, das die neuesten Fortschritte in Echtzeit-Photorealismus, Blockchain, künstlicher Intelligenz und Multiplayer-Technologie im großen Maßstab nutzt. Du, als Wilder, kontrollierst seine Zukunft!\n\nTROTZE DEM FORUM. RETTE DIE REALITÄT.\nIm Jahr 1945 verursachte die Detonation der Atombombe einen Große Fork, der unsere Basisrealität mit einer anderen vereinte. Wilder World wurde zur Brücke zwischen diesen beiden Welten.\n\nVERTEIDIGE WIAMI.\nWiami ist die erste Stadt in Wilder World. In ihrem Zentrum befindet sich der KERNEL – ein Quellcode-Modul, das entworfen wurde, um die Macht zu dezentralisieren und äußere Kräfte zu begrenzen, die versuchen, die Kontrolle über das Metaverse zu übernehmen.",
    "partners.wiami.modal.subHeader": "Eine neue Dimension der Realität",
    "partners.wiami.modal.headline": "Wilder World",
    "partners.wiami.modal.text": "Alles in Wiami, von Avataren über Kleidung, Fahrzeugen, Land, Gebäuden und mehr, wird NFTs sein, die auf dem Wilder World Metaverse Markt vollständig handelbar sind. Die NFTs von Wilder World werden prozedural generiert und gehören zu den qualitativ hochwertigsten und nützlichsten NFTs, die jemals erschaffen wurden. Erkunde hier einige seiner Sammlungen.",
    "partners.wiami.link": "https://www.wilderworld.com/",
    "partners.illuvium.subtitle": "Ein Universum voller Games",
    "partners.illuvium.title": "Illuvium",
    "partners.illuvium.description": "Illuvium ist ein vollständig dezentralisiertes globales Studio, das das erste interoperable Blockchain-Spiel (IBG) Universum auf der Ethereum-Blockchain entwickelt, einschließlich eines Open-World-Explorationsspiels, eines industriellen Städtebau-Spiels und eines Autobattlers.\nTrete ein in ein grafisch reiches Sci-Fi-Abenteuer, erkunde sieben atemberaubende außerirdische Landschaften und bezwinge die Wildnis, um die kataklysmischen Ereignisse aufzudecken, die Illuvium zerrütteten, und hilf deiner notgelandeten Besatzung zu gedeihen!",
    "partners.illuvium.modal.subHeader": "Willkommen in der Welt von Illuvium",
    "partners.illuvium.modal.headline": "Illuvium – Gewinne ETH im Duell gegen Andere",
    "partners.illuvium.modal.text": "Tauche ein in ein grafisch beeindruckendes Science-Fiction-Abenteuer, erkunde 7 faszinierende außerirdische Welten und erobere die Wildnis, um die katastrophalen Ereignisse aufzudecken, die Illuvium zerstört haben. Hilf deiner abgestürzten Crew dabei erfolgreich zu sein!",
    "partners.illuvium.link": "https://illuvium.io/",
    "partners.wow.subtitle": "Ermächtigung von Frauen durch Kunst im Web3",
    "partners.wow.title": "World of Women",
    "partners.wow.description": "World of Women (WoW) ist aus der Vision des gefeierten digitalen Künstlers Yam Karkai entstanden, um Frauen in der nächsten Ära des Webs eine größere Präsenz zu geben. Die ikonischen PFP-generativen Kunstkollektionen von WoW haben historische Meilensteine erreicht, mit einem Gesamtumsatz von über 400 Millionen US-Dollar und einem Rekordverkauf bei Christie’s Evening Sale durch eine Künstlerin. Als größte missiongetriebene Brand im Web3 hat WoW über 3 Millionen US-Dollar für wohltätige Zwecke gespendet, über 500 Künstlerinnen unterstützt und ins Rampenlicht gerückt, und Arbeiten von 40 Künstlern in Auftrag gegeben, um ihrer Community lore-based Art zu präsentieren. Jetzt, in Zusammenarbeit mit Samsung, strebt WoW danach, vielfältige Kunst und Storytelling in die Haushalte und physischen Räume weltweit zu bringen.",
    "partners.wow.modal.subHeader": "ÜBER IKONISCHE KUNST ZUR EINER GLOBALEN COMMUNITY",
    "partners.wow.modal.headline": "World of Women",
    "partners.wow.modal.text": "World of Women (WoW) ist zu einer Gemeinschaft von über 350.000 Unterstützern und 13.000 Sammlern herangewachsen, die Wow’s Vision für eine inklusive Zukunft teilen. Mitglieder verbinden sich bei Veranstaltungen rund um die Welt persönlich und online auf Patio, dem digitalen Hub von WoW. Erkunde WoW noch heute und sei Teil einer wegweisenden Community, die an vorderster Front von Kunst, Technologie und Kultur steht.",
    "partners.wow.link": "https://patio.wow.art/",
    "partners.belvedere.subtitle": "The Kiss NFT",
    "partners.belvedere.title": "Belvedere Museum",
    "partners.belvedere.description": "Das Belvedere ist ein Weltkulturerbe, ein barockes Juwel und der Ort des Österreichischen Staatsvertrags. Es ist sowohl eines der ältesten Museen der Welt als auch eine Bühne für zeitgenössische Kunst. Als eine der größten kulturellen Einrichtungen des Landes und österreichisches Wahrzeichen stehen wir vor der Herausforderung, uns inmitten unterschiedlicher Prioritäten von kulturellen und wissenschaftlichen Anforderungen, der Verbundenheit zur lokalen Gemeinschaft und dem Tourismus weiterzuentwickeln. Während wir unserer Mission folgen, die Vergangenheit zu bewahren, streben wir auch danach, neue Wege zu gehen. Wir stehen als Vermittler der Geschichte und als unbequemer Befrager der Gegenwart da. Wir sind der kulturelle Knotenpunkt des Landes von internationalem Rang und existieren im transnationalen digitalen Raum.",
    "partners.belvedere.modal.subHeader": "Gustav Klimt vom Belvedere Museum",
    "partners.belvedere.modal.headline": "The Kiss NFT",
    "partners.belvedere.modal.text": "Gustav Klimts ”Der Kuss” ist ein weltberühmtes Kunstwerk und das Highlight der Sammlung des Belvedere. Das angesehene Museum in Wien hat ein hochauflösendes digitales Bild des Meisterwerks in einer limitierten Auflage von 10.000 einzigartigen Einzelkacheln veröffentlicht. Dies ist die einzige offizielle ”The Kiss” NFT-Kollektion des Belvedere. Jedes NFT ist ein einmaliges exklusives Objekt, das jemandem Besonderem gewidmet werden kann.",
    "partners.belvedere.link": "https://thekiss.art/?ref=SAMSUNG24",
    "partners.polygonlabs.subtitle": "Die Wertebene des Internets",
    "partners.polygonlabs.title": "Polygon Labs",
    "partners.polygonlabs.description": "Polygon Labs entwickelt Ethereum-Skalierungslösungen für Polygon-Protokolle. Polygon Labs arbeitet mit anderen Entwicklern im Ökosystem zusammen, um skalierbare, erschwingliche, sichere und nachhaltige Blockchain-Infrastruktur für Web3 bereitzustellen.",
    "partners.polygonlabs.modal.subHeader": "Web3, Aggregiert.",
    "partners.polygonlabs.modal.headline": "Polygon Labs",
    "partners.polygonlabs.modal.text": "Bereitstellen eines unendlich skalierbaren Netzes souveräner Blockchains, das sich wie eine einzige Chain anfühlt. Angetrieben von ZK-Technologie.",
    "partners.polygonlabs.link": "https://polygon.technology/",
    "partners.intro.subtitle": "Samsungs Web3-Revolution: Synergien schaffen, Gemeinschaften stärken und die Zukunft gestalten",
    "partners.intro.title": "LERNE DIE SAMSUNG WEB3 PARTNER KENNEN",
    "partners.intro.smallText": "Wir bei Samsung glauben an den Aufbau von Netzwerken, die Synergien und Verbindungen schaffen. Gemeinsam mit unseren geschätzten Partnern wird das Samsung-Ökosystem zu einem lebendigen Raum, der von der Gemeinschaft angetrieben wird. Als Pioniere der Zukunft des Internets setzen wir auf Dezentralisierung, Blockchain-Technologie und kryptografische Sicherheit, um Online-Begegnungen innerhalb des vertrauenswürdigen Samsung-Ökosystems neu zu gestalten. Begleiten Sie uns auf dieser transformativen Reise, auf der wir Web3-Fähigkeiten nahtlos in die digitale Landschaft integrieren, um die weltweit größte Web3-Community zu formen - eine Ära der vernetzten Möglichkeiten, des gestärkten Vertrauens und der grenzenlosen Chancen.",
    "partners.intro.bigText": "Lets Enable web3.",
    "You are registered for this raffle": "Du bist für dieses Gewinnspiel angemeldet",
    "Raffle Type": "Gewinnspiel Typ",
    "Sign up for this raffle": "Nimm an diesem Gewinnspiel teil",
    "This raffle has ended.": "Das Gewinnspiel ist vorbei.",
    "You have signed up for this raffle": "Du hast dich für dieses Gewinnspiel angemeldet",
    "View raffle": "Gewinnspiel anzeigen",
    "There was a problem while signing up for this raffle.": "Es gabe in Problem bei der Anmeldung für dieses Gewinnspiel.",
    "There was a problem showing this raffle. Please contact support on discord": "Es gab ein Problem bei Laden des Gewinnspiels. Bitte melde dich bei unserem Support",
    "There was a problem listing all the raffles. Please contact support on discord": "Es gab ein Problem bei der Auflistung der Gewinnspiele. Bitte kontaktiere den Support",
    "Your wallet does not contain a token that can unlock this content. Maybe the token has already been used?": "Ihr Wallet enthält keinen Token, der diesen Inhalt freischalten kann. Vielleicht wurde der Token bereits verwendet?",
    "Terms & Conditions TV Bundle": "AGB TV Bundle",
    "Terms & Conditions for a raffle": "Teilnahmebedingungen für ein Gewinnspiel",
    "My Tokens": "Meine Token",
    "Missing name": "Fehlender Name",
    "Check out this Token": "Schau dir diesen Token an",
    "Description": "Beschreibung",
    "Owner": "Besitzer",
    "(You own this token)": "(Du besitzt diesen Token)",
    "Traits": "Eigenschaften",
    "Token ID": "Token ID",
    "Check on Marketplaces": "Schaue auf Marktplätzen",
    "Rarible": "Rarible",
    "Token Search": "Token Suche",
    "Tribe": "Tribe",
    "Community Membership Tiers": "Community Membership Tiers",
    "Membership Tier": "Membership Tier",
    "Required Collectibles": "Benötigte Tokens",
    "To view your available tokens, please sign in.": "Melde dich bitte an um deine verfügbaren Tokens zu sehen.",
    "Accessible Benefits for this Membership Tier": "Verfügbare Benefits für dieses Membership Tier",
    "Currently no benefits available for this tier.": "Derzeit sind keine Benefits für dieses Tier verfügbar.",
    "Membership Tiers": "Membership Tiers",
    "tiers.intro.subtitle": "GEN3 COD3X",
    "tiers.intro.title": "Unsere Membership Tiers",
    "tiers.intro.smallText": "Werden Teil des COD3X",
    "tiers.intro.bigText": "Erlebe mit den Samsung GEN3 Membership Tiers das volle Potential des GEN|3|GATE. Diese Tiers sind dein Schlüssel zu einer Welt voller Belohnungen und Möglichkeiten, die speziell darauf ausgerichtet sind, deinen Weg durch das Samsung GEN3 Ecosystem zu bereichern. Von täglichen Vorteilen bis hin zu einmaligen Erlebnissen – mit jedem Tier öffnen sich dir neue Türen.",
    "intro.tvapp.subtitle": "Samsung NFT Gallery - TV App",
    "intro.tvapp.title": "Features, die deine NFTs zum Leben erwecken",
    "intro.tvapp.smallText": "Entdecke eine revolutionäre Möglichkeit, deine digitale Art-Collection mit unserer Samsung NFT Gallery - TV App zu genießen, die entwickelt wurde, um deine NFTs in das Herz deines Zuhauses zu bringen. Diese App bietet eine nahtlose Verbindung zwischen deiner Blockchain-Wallet und deinem Fernseher und verwandelt ihn in eine live Gallery, die nicht nur deine NFTs präsentiert, sondern diese zum Leben erweckt. Erlebe selber, was unsere App zu einem Game-Changer im digitalen Kunstbereich macht.",
    "features.tvapp.title": "Samsung NFT Gallery - TV App",
    "features.tvapp.content": "- Präsentation deiner Lieblings-NFTs in einer personalisierten TV-Galerie<br />\n- Einfaches Management deiner interaktiven Diashow<br />\n- Seamless Wallet Connection für einfachen Zugriff auf deine NFTs.<br />\n- Entdecke unsere Partner-Highlight-Collectionen<br />\n<br />\nMit diesen Funktionen geht es bei unserer NFT Gallery App nicht nur darum, Kunst anzusehen; es geht darum, sie auf eine ganz neue Weise zu erleben und jeden Moment zu Hause zu einem einzigartigen Erlebnis zu machen.",
    "tx1.raffle.teaser.title": "Nimm am TX1 GEN3RATOR Raffle teil",
    "tx1.raffle.teaser.content": "Nutze die Chance, einen GEN3RATOR sowie einen Samsung GEN3 Chip zu gewinnen! Unter allen, die sich für diese Verlosung anmelden, werden nach dem 30. Juli zufällig die glücklichen Gewinner auswählen, die jeweils einen GEN3RATOR Set erhalten.",
    "tx1.raffle.teaser.cta": "Hier klicken um teilzunehmen",
    "artist.tx1ge.title": "Entdecke die visionäre Welt des digitalen Künstlers Paul Milinski – wo Kunst Grenzen überwindet, um transformative Erfahrungen zu schaffen",
    "artist.paulmilinski.name": "Paul Milinski ",
    "artist.paulmilinski.text": "Paul Milinski, der Visionär hinter dem Milinski Studio, überwindet Kunstgrenzen mit digitalen und physischen Erfahrungen. Berühmt für 3D-Rendering und Zusammenarbeiten mit Cartier, Louis Vuitton und anderen, formt er weltweit Räume neu. Pauls Arbeit vereint technisches Können, Emotion und Intellekt und überbrückt Kunst und Luxus. Mit jedem Projekt verwandelt er das Alltägliche ins Außergewöhnliche und definiert Kreativität und Luxuslandschaften neu.",
    "artist.modal.paulmilinski.subHeader": "Erkundung von Emotion und Innovation in der modernen digitalen Kunst",
    "artist.modal.paulmilinski.headline": "Digital Dreams: The Art of Paul Milinski",
    "artist.modal.paulmilinski.text": "Tauche ein in die faszinierende Welt von Paul Milinski, dem Künstler aus Melbourne, der die digitale Kunst neu definiert. Mit seinem unverwechselbaren, gefühlvollen Stil zieht Milinskis Arbeit die Aufmerksamkeit globaler Brands und Sammler auf sich und erreicht rekordverdächtige Auktionsergebnisse. Entdecke, wie seine visionären Werke Artist auf der ganzen Welt inspirieren und die Zukunft der Kunst verändern.",
    "Extras": "Extras",
    "Extra": "Extra",
    "Unlocked Extra": "Freigeschaltenes Extra",
    "Secret Files": "Geheime Dateien",
    "Filter by Category": "Nach Kategorie filtern",
    "All": "Alle",
    "Unlockable Contents": "Entsperrbare Inhalte",
    "There are no unlockable contents yet": "Es gibt noch keine Entsperrbaren Inhalte",
    "immediate": "sofort",
    "after vote": "Nach Stimmabgabe",
    "after end": "Nach dem Ende",
    "Reveal Type": "Veröffentlichung",
    "Your Vote has been send successfully": "Deine Stimme wurde erfolgreich gesendet",
    "There was a problem showing this voting. Please contact support on discord": "Es gab ein Problem bei der Anzeige dieser Abstimmung. Bitte kontaktiere den Support auf Discord",
    "Votings": "Votings",
    "There are no votings yet": "Es sind noch keine Votings vorhanden",
    "Open": "Öffnen",
    "Allowlist": "Allowlist"
  },
  "options": {
    "plural_rule": "n != 1",
    "plural_number": "2"
  },
  "en": {
    "Powered by": "Powered by",
    "Continue": "Continue",
    "Settings": "Settings",
    "{project} asks for your email address": "{project} asks for your email address",
    "Don't share email": "Don’t share email",
    "Receive notifications from this project": "Receive notifications from this project",
    "{project} requires your email address to continue": "{project} requires your email address to continue",
    "{project} will collect your email address and utilize it in accordance with their data protection policy. Bowline will also store your email address for the purpose of sending notifications.": "{project} will collect your email address and utilize it in accordance with their data protection policy. Bowline will also store your email address for the purpose of sending notifications.",
    "Your stored email": "Your stored email",
    "Email address": "Email address",
    "Email is required": "Email is required",
    "I subscribe to project updates": "I subscribe to project updates",
    "Submit": "Submit",
    "Wallet": "Wallet",
    "New alerts": "New alerts",
    "Not signed in with Wallet": "Not signed in with Wallet",
    "Connected to wrong network": "Connected to wrong network",
    "Account": "Account",
    "Balance": "Balance",
    "Signing Request": "Signing Request",
    "Cancel": "Cancel",
    "Sign": "Sign",
    "You are not signed in with your Wallet yet": "You are not signed in with your Wallet yet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.",
    "You are signed in with your Wallet": "You are signed in with your Wallet",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
    "Switch to {networkName} network": "Switch to {networkName} network",
    "Copy address": "Copy address",
    "Tokens": "Tokens",
    "Activity": "Activity",
    "Please \"Sign in with Wallet\" first.": "Please \"Sign in with Wallet\" first.",
    "See above": "See above",
    "Change E-mail address": "Change E-mail address",
    "Change": "Change",
    "Delete Account": "Delete Account",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.",
    "No Activity yet in this session": "No Activity yet in this session",
    "Recent Signatures": "Recent Signatures",
    "Transactions": "Transactions",
    "Disconnect": "Disconnect",
    "ok": "ok",
    "Reload Tokens": "Reload Tokens",
    "Sort by Creation Date (Newest to Oldest)": "Sort by Creation Date (Newest to Oldest)",
    "Sort by Creation Date (Oldest to Newest)": "Sort by Creation Date (Oldest to Newest)",
    "Sort by Identifier (Lowest to Highest)": "Sort by Identifier (Lowest to Highest)",
    "Sort by Identifier (Highest to Lowest)": "Sort by Identifier (Highest to Lowest)",
    "Sort by Contract Address": "Sort by Contract Address",
    "You do not have any Token in Wallet for this project": "You do not have any Token in Wallet for this project",
    "Reload": "Reload",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example",
    "or": "or",
    "Details": "Details",
    "Connect to Wallet": "Connect to Wallet",
    "close": "close",
    "Select a Wallet": "Select a Wallet",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.",
    "How to connect?": "How to connect?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.",
    "I do have a wallet.": "I do have a wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.",
    "No wallet yet?": "No wallet yet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.",
    "Connect with Coinbase Wallet": "Connect with Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Want to learn more about this wallet?": "Want to learn more about this wallet?",
    "Learn how to install and setup this wallet on": "Learn how to install and setup this wallet on",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "We've just recently added support for this wallet. If you experience any issue please let us know.",
    "Connect with Ledger": "Connect with Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.",
    "Connect with MetaMask": "Connect with MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.",
    "Please use your existing MetaMask wallet.": "Please use your existing MetaMask wallet.",
    "Connect with your preferred wallet": "Connect with your preferred wallet",
    "With WalletConnect you can connect to almost any existing wallet.": "With WalletConnect you can connect to almost any existing wallet.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.",
    "Connect with your Google Account": "Connect with your Google Account",
    "Connect with Google": "Connect with Google",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.",
    "Connect to your Wallet via Social Connect.": "Connect to your Wallet via Social Connect.",
    "Connect with your Meta Account": "Connect with your Meta Account",
    "Connect with Facebook": "Connect with Facebook",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.",
    "Connect with Metamask": "Connect with Metamask",
    "Connect with Wallet Connect": "Connect with Wallet Connect",
    "Verify your e-mail address": "Verify your e-mail address",
    "The code was sent to your e-mail": "The code was sent to your e-mail",
    "Please enter the code": "Please enter the code",
    "Verify": "Verify",
    "Resend": "Resend",
    "Discard": "Discard",
    "Attending": "Attending",
    "Type": "Type",
    "Start": "Start",
    "End": "End",
    "Signed up": "Signed up",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "New content is available. Please click here to refresh the page and avoid inconsistencies.",
    "Home": "Home",
    "Already minted in total": "Already minted in total",
    "Already minted / wallet": "Already minted / wallet",
    "Mint for {mintPrice} ETH / each": "Mint for {mintPrice} ETH / each",
    "Paid Transaction": "Paid Transaction",
    "Mint": "Mint",
    "Buy Mint": "Buy Mint",
    "Select an option to pay for this mint": "Select an option to pay for this mint",
    "Pay with Crypto": "Pay with Crypto",
    "We realized that your balance is too low. You need to have at least ": "We realized that your balance is too low. You need to have at least ",
    "Pay with Credit Card": "Pay with Credit Card",
    "Please wait for the payment to be completed": "Please wait for the payment to be completed",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "The transaction has been sent and you will receive a notification in your bowline wallet",
    "No tokens available for minting": "No tokens available for minting",
    "Max tokens minted": "Max tokens minted",
    "Mint will start soon. Stay tuned!": "Mint will start soon. Stay tuned!",
    "Price/NFT": "Price/NFT",
    "Amount you already minted": "Amount you already minted",
    "Current Price": "Current Price",
    "e.g. Voucher Code": "e.g. Voucher Code",
    "Sold out!": "Sold out!",
    "Please visit the": "Please visit the",
    "secondary market.": "secondary market.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "Switch to Ethereum network": "Switch to Ethereum network",
    "Switch to Rinkeby network": "Switch to Rinkeby network",
    "Switch to Görli network": "Switch to Görli network",
    "Switch to Sepolia network": "Switch to Sepolia network",
    "Switch to Polygon network": "Switch to Polygon network",
    "Switch to Mumbai network": "Switch to Mumbai network",
    "Switch to Amoy network": "Switch to Amoy network",
    "Preview": "Preview",
    "Edit": "Edit",
    "Update": "Update",
    "Create": "Create",
    "Update Page": "Update Page",
    "Create Page": "Create Page",
    "Do you want to update this page?": "Do you want to update this page?",
    "Do you want to create this page?": "Do you want to create this page?",
    "Set Title": "Set Title",
    "Set URL": "Set URL",
    "Locale": "Locale",
    "Page Deleted": "Page Deleted",
    "Page was deleted successfully!": "Page was deleted successfully!",
    "Back": "Back",
    "Missing title": "Missing title",
    "Expired": "Expired",
    "Unlocking deal": "Unlocking deal",
    "Successfully Unlocked": "Successfully Unlocked",
    "You successfully unlocked this deal": "You successfully unlocked this deal",
    "Close": "Close",
    "There was a problem while unlocking this deal.": "There was a problem while unlocking this deal.",
    "Unlock with different tokens": "Unlock with different tokens",
    "Unlock": "Unlock",
    "Please connect your wallet to be able to unlock": "Please connect your wallet to be able to unlock",
    "Secrets": "Secrets",
    "Welcome to {project}": "Welcome to {project}",
    "By submitting you accept the": "By submitting you accept the",
    "terms of service": "terms of service",
    "terms of service for {projectTitle}": "terms of service for {projectTitle}",
    "data protection policy": "data protection policy",
    "data protection policy for {projectTitle}": "data protection policy for {projectTitle}",
    "of {projectTitle}.": "of {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.",
    "Position": "Position",
    "Your Wallet": "Your Wallet",
    "here": "here",
    "Share": "Share",
    "Bid for this Product": "Bid for this Product",
    "you must bid at least": "you must bid at least",
    "Enter bid": "Enter bid",
    "Bid can't be higher than current balance": "Bid can't be higher than current balance",
    "Warning: Your bid is lower than the current highest bid": "Warning: Your bid is lower than the current highest bid",
    "Your balance": "Your balance",
    "Place your Bid": "Place your Bid",
    "You cannot place a bid as you have insufficient funds.": "You cannot place a bid as you have insufficient funds.",
    "Please follow the confirmation steps in your wallet.": "Please follow the confirmation steps in your wallet.",
    "Your bid is stored in our database.": "Your bid is stored in our database.",
    "You have successfully placed your bid!": "You have successfully placed your bid!",
    "We will notify you if you are outbid, or if you are the bid winner!": "We will notify you if you are outbid, or if you are the bid winner!",
    " view more": " view more",
    "This is a cached value, the price should soon be loaded from blockchain.": "This is a cached value, the price should soon be loaded from blockchain.",
    "Purchase": "Purchase",
    "Your item purchase was successful": "Your item purchase was successful",
    "Okay": "Okay",
    "Order Summary": "Order Summary",
    "Amount: ": "Amount: ",
    "Total": "Total",
    "Close Modal": "Close Modal",
    "Transaction in Progress": "Transaction in Progress",
    "Please sign allowance": "Please sign allowance",
    "please approve the interaction": "please approve the interaction",
    "Checking your balance": "Checking your balance",
    "checking if your wallet has enough funds to buy this product": "checking if your wallet has enough funds to buy this product",
    "Please sign transaction": "Please sign transaction",
    "Metamask should open and ask you to sign the transaction": "Metamask should open and ask you to sign the transaction",
    "Wait for transaction to be mined": "Wait for transaction to be mined",
    "Depending on network traffic this could take a few minutes": "Depending on network traffic this could take a few minutes",
    "Something went wrong": "Something went wrong",
    "We could not purchase the product!": "We could not purchase the product!",
    "Biling details": "Biling details",
    "First name": "First name",
    "This field is required": "This field is required",
    "Maximum length is 150": "Maximum length is 150",
    "Last name": "Last name",
    "Company name": "Company name",
    "Address": "Address",
    "Minimum length is 5": "Minimum length is 5",
    "Maximum length is 250": "Maximum length is 250",
    "Postal Code": "Postal Code",
    "City": "City",
    "Country": "Country",
    "Summary": "Summary",
    "Products": "Products",
    "Price": "Price",
    "Continue Purchase": "Continue Purchase",
    "Please Sign in with Wallet first before purchasing a product!": "Please Sign in with Wallet first before purchasing a product!",
    "Please connect your wallet first before being able to purchase products": "Please connect your wallet first before being able to purchase products",
    "Click Purchase to complete your checkout.": "Click Purchase to complete your checkout.",
    "Sign in with Wallet": "Sign in with Wallet",
    "Stake All selected": "Stake All selected",
    "Unstake All selected": "Unstake All selected",
    "staked": "staked",
    "Unstake": "Unstake",
    "Stake": "Stake",
    "unclaimed earnings": "unclaimed earnings",
    "Your retrieved earnings, that are ready to claim!": "Your retrieved earnings, that are ready to claim!",
    "pending rewards": "pending rewards",
    "Tokens in the Hole": "Tokens in the Hole",
    "Your total Tokens staked": "Your total Tokens staked",
    "Available Balance": "Available Balance",
    "Your lifetime earnings from staking.": "Your lifetime earnings from staking.",
    "Claim": "Claim",
    "Authorization required": "Authorization required",
    "The provided voucher code is not valid": "The provided voucher code is not valid",
    "The provided voucher code got already used. Each voucher code can be used only once.": "The provided voucher code got already used. Each voucher code can be used only once.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "If you tried to mint more than one Token, you’ve might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.",
    "Attention:": "Attention:",
    "We can't guarantee the exact order of the transactions.": "We can't guarantee the exact order of the transactions.",
    "An Error occured!": "An Error occured!",
    "show full error message": "show full error message",
    "Processing": "Processing",
    "The airdrop is handled in the background and might experience delays beyond the usual timeframe due to network congestion.": "The airdrop is handled in the background and might experience delays beyond the usual timeframe due to network congestion.",
    "This transaction takes longer than usual. You can close this modal without canceling the transaction.": "This transaction takes longer than usual. You can close this modal without canceling the transaction.",
    "Success!": "Success!",
    "a content category": "a content category",
    "an unlockable content": "an unlockable content",
    "a whitelist entry": "a whitelist entry",
    "a event": "a event",
    "a token": "a token",
    "created": "created",
    "updated": "updated",
    "minted": "minted",
    "transfered": "transfered",
    "You successfully {action} {resourceType}": "You successfully {action} {resourceType}",
    "click to get back to overview screen": "click to get back to overview screen",
    "Missing Name": "Missing Name",
    "Select": "Select",
    "Selected": "Selected",
    "This token has already been used": "This token has already been used",
    "This token is already selected elsewhere": "This token is already selected elsewhere",
    "Auto Selected": "Auto Selected",
    "Any {contract} Token": "Any {contract} Token",
    "Collapse": "",
    "Expand": "",
    "Transfer Token": "Transfer Token",
    "Receiver": "Receiver",
    "Not a valid address!": "Not a valid address!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ You will transfer an nft to {address}. This operation can not be undone",
    "Transfer this NFT": "Transfer this NFT",
    "Purchase in Progress": "Purchase in Progress",
    "Signing with your wallet": "Signing with your wallet",
    "Please follow your wallet instructions to confirm the transaction.": "Please follow your wallet instructions to confirm the transaction.",
    "You confirmed the transaction with your wallet.": "You confirmed the transaction with your wallet.",
    "Transaction is sent to the blockchain": "Transaction is sent to the blockchain",
    "After signing the transaction it is send to the blockchain.": "After signing the transaction it is send to the blockchain.",
    "Transaction is being processed": "Transaction is being processed",
    "Please check your wallets transaction history for your actual transaction status!": "Please check your wallets transaction history for your actual transaction status!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.",
    "You need to connect your wallet to unlock": "You need to connect your wallet to unlock",
    "Email Required": "Email Required",
    "We noticed that you haven't set up an email address yet, which is necessary to proceed with the unlocking process. Please provide your email address below.": "We noticed that you haven’t set up an email address yet, which is necessary to proceed with the unlocking process. Please provide your email address below.",
    "Email": "Email",
    "email is required": "email is required",
    "Step": "Step",
    "of": "of",
    "You have unlocked this successfully": "You have unlocked this successfully",
    "Select your Tokens for Voting": "Select your Tokens for Voting",
    "Select your Token": "Select your Token",
    "You can unlock this unlimited times.": "You can unlock this unlimited times.",
    "You can unlock this once.": "You can unlock this once.",
    "You can unlock this from {date}.": "You can unlock this from {date}.",
    "You can unlock this until {date}.": "You can unlock this until {date}.",
    "If you sell your token you will loose access": "If you sell your token you will loose access",
    "Sorry you dont have any matching Token for unlocking": "Sorry you dont have any matching Token for unlocking",
    "Please enter to unlock": "Please enter to unlock",
    "Entered value does not match email format": "Entered value does not match email format",
    "Unlocking": "Unlocking",
    "Sign the message in your wallet to submit the transaction. Once submitted, We will wait until the transaction is finalized and your unlockable content is available.": "Sign the message in your wallet to submit the transaction. Once submitted, We will wait until the transaction is finalized and your unlockable content is available.",
    "Next": "Next",
    "Sign Up": "Sign Up",
    "Vote": "Vote",
    "And": "And",
    "Or": "Or",
    "Loading...": "",
    "Select {n} token from these": "Select {n} token from these",
    "Sorry, no matching tokens for this boundary": "",
    "Conditions met": "Conditions met",
    "Select tokens below you want to use": "Select tokens below you want to use",
    "Change password": "Change password",
    "Insert your current password and the new password": "Insert your current password and the new password",
    "Current Password": "Current Password",
    "Password": "Password",
    "Save": "Save",
    "tokens": "tokens",
    "community event": "community event",
    "partner event": "partner event",
    "conference": "conference",
    "irl meetup": "irl meetup",
    "irl party": "irl party",
    "virtual meetup": "virtual meetup",
    "discord event": "discord event",
    "ask me anything": "ask me anything",
    "There are no votings options available": "There are no votings options available",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Please connect your wallet to be able to vote",
    "Your Vote has been sent successfully": "Your Vote has been sent successfully",
    "Voting": "Voting",
    "Successfully Voted": "Successfully Voted",
    "There was a problem while sending your vote. Please try again.": "There was a problem while sending your vote. Please try again.",
    "Reveal": "Reveal",
    "get more Info": "get more Info",
    "This vote has ended. Thanks!": "This vote has ended. Thanks!",
    "Wallet Changed": "Wallet Changed",
    "It looks like you changed your wallet, would you like to connect your wallet?": "It looks like you changed your wallet, would you like to connect your wallet?",
    "Export Wallet": "Export Wallet",
    "(A QR code will be generated for {n} seconds)": "(A QR code will be generated for {n} seconds)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.",
    "Copy Private Key": "Copy Private Key",
    "please complete your profile": "please complete your profile",
    "* means required": "* means required",
    "Please connect to your wallet first.": "Please connect to your wallet first.",
    "Contract Address": "Contract Address",
    "Add my Wallet to this Allowlist": "Add my Wallet to this Allowlist",
    "Congratulations!": "Congratulations!",
    "You've been added to the Allowlist!": "You've been added to the Allowlist!",
    "Do you want to receive notifications on your email about this listing?": "Do you want to receive notifications on your email about this listing?",
    "Wallet Address": "Wallet Address",
    "You are already part of this allowlist!": "You are already part of this allowlist!",
    "share email anonymised": "share email anonymised",
    "(recommended)": "(recommended)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} will only receive an anonymised email address but bowline will keep track of your real email address.",
    "share email": "share email",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.",
    "don't share email": "don't share email",
    "Two brands one vision": "Two brands one vision",
    "Samsung x Ledger": "Samsung x Ledger",
    "Maximum security and the most important basic knowledge for the step into Web3.": "Maximum security and the most important basic knowledge for the step into Web3.",
    "Samsung opens the gates to Web3. Be the first to join the Samsung community and embark on a journey to a new world. \nFirst step to provide a Web3 Starter Bundle with Ledger on the Amazon marketplace. In addition to an onboarding experience by Ledger, the exclusive bundle also includes various NFTs designed by international and German artists from the NFT scene with an exciting minting experience over two phases.": "Samsung opens the gates to Web3. Be the first to join the Samsung community and embark on a journey to a new world. \nFirst step to provide a Web3 Starter Bundle with Ledger on the Amazon marketplace. In addition to an onboarding experience by Ledger, the exclusive bundle also includes various NFTs designed by international and German artists from the NFT scene with an exciting minting experience over two phases.",
    "Learn more": "Learn more",
    "Visit": "Visit",
    "artist.tabs.art.title": "The Samsung MX1 ART COLLECTION NFTs - 3 artists 9, unique Artpieces",
    "Antoni Tudisco": "Antoni Tudisco",
    "Bruno Urli": "Bruno Urli",
    "Charlotte Taylor & Anthony Authié": "Charlotte Taylor & Anthony Authié",
    "artist.tudisco.subtitle": "Antoni Tudisco - 3D Fashion & Motion Design",
    "artist.tudisco.text": "Creative director & 3D visual artist Antoni Tudisco was born & raised in Hamburg, Germany where he grew up studying media management and web design & development programming. Worked with top-tier brands Nike, Adidas, Versace, Google, Mercedes Benz, D&G. Having been featured in Vogue, Hypebeast and winning several notable design awards.",
    "artist.modal.tudisco.subHeader": "Antoni Tudisco",
    "artist.modal.tudisco.headline": "Antoni Tudisco - 3D Digital Art - Fashion - ETHEE",
    "artist.modal.tudisco.text": "ETHEEVERSE\nETHEE is a future metaverse character created by Antoni Tudisco. ETHEE’S journey through the ETHEEVERSE is inspired by the futuristic and creative interpretation of tech and new ways of digital communication by Antoni. “We design our dreams in the metaverse.”",
    "artist.bruno.subtitle": "Bruno Urli - The art of NFT Photography",
    "artist.bruno.text": "Bruno Urli (2004) is an eighteen-year-old contemporary digital artist who is best known for his dreamy digital art. He was born in Germany and despite his very young age, has already carved out his own space in the crypto art scene.. Over the last years, Bruno Urli has had several art exhibitions of his work in galleries around the world, including in New York, Tel-Aviv and Paris.",
    "artist.modal.bruno.subHeader": "Bruno Urli",
    "artist.modal.bruno.headline": "Bruno Urli - young talent of the crypto art scene",
    "artist.modal.bruno.text": "Bruno plays with tones, compositions and lights to create compelling new worlds that seem alive, yet surreal, all at once. The artist’s highly detailed 3d works often reflects his interest in the natural world, as well as his personal experiences and emotions. His work is also deeply emotional, evoking feelings of joy, sorrow, and longing.",
    "artist.charlotte.subtitle": "Charlotte Taylor - The art of real-estate",
    "Charlotte Taylor": "Charlotte Taylor",
    "artist.charlotte.text": "Studio Charlotte Taylor creates interiors and spaces that blend the sculptural with the practical; the futuristic with comfort. Exploring the boundaries between digital and the physical, the studio utilises ultra-realistic visual renders to reimagine real-world interiors. Pushing conventional rules and reimagining the tangible, the result is a unique signature vision, process and style.",
    "artist.modal.charlotte.subHeader": "Charlotte Taylor",
    "artist.modal.charlotte.headline": "Charlotte Taylor - The art of real-estate",
    "artist.modal.charlotte.text": "We envisaged the journey of your life through a series of personal spaces and their journey through the different levels of life, portrayed through a meta topical lift. We both are very connected to domestic spaces and the sense of home and feel it’s a portal to really show yourself and your personality that changes and adapts over the years.",
    "Anthony Authié": "Anthony Authié",
    "artist.authie.text": "Anthony Authié is 30 years old, and for a few years now, ordinary mortals have known him under the name of Zyva Studio, his studio… of architecture, let’s say. With 3D, Anthony creates a character and uses the codes of influence 3.0.",
    "artist.modal.authie.subHeader": "Anthony Authié",
    "artist.modal.authie.headline": "Anthony Authié - The art of real-estate",
    "artist.modal.authie.text": "We envisaged the journey of your life through a series of personal spaces and their journey through the different levels of life, portrayed through a meta topical lift. We both are very connected to domestic spaces and the sense of home and feel it’s a portal to really show yourself and your personality that changes and adapts over the years.",
    "artist.tabs.genesis.title": "The Samsung MX1 GENESIS EDITION NFTs - Samsung’s official community token",
    "Jaiden Stipp": "Jaiden Stipp",
    "Specter": "Specter",
    "Plastic Pen": "Plastic Pen",
    "Thomas Fleck": "Thomas Fleck",
    "artist.jaiden.subtitle": "Jaiden Stipp - new Talent & rising Star",
    "artist.jaiden.text": "Jasti, real name Jaiden Stipp, is a 16-year-old artist considered to be one of the most prolific young artists in the NFT space. Towards the beginning of 2021, Jasti found himself deeply interested in NFTs and decided to take the plunge, minting then quickly selling his first NFT for 20 ETH ($30,377)",
    "artist.modal.jaiden.subHeader": "Jaiden Stipp",
    "artist.modal.jaiden.headline": "The Jasti Art journey",
    "artist.modal.jaiden.text": "On the spectacular journey into the Web3 Jaiden has developed some elements, which he uses in each of his artworks as elementary components. In this work he combines all the elements that are most emotionally meaningful to him.",
    "artist.specter.subtitle": "Specter Berlin - Artist! Director! Creator!",
    "artist.specter.text": "Born in 1975, raised in Paris, today based in Berlin\nSpecter Berlin started his carrier as a Graffiti Artist in the 90 ́s. He is one of the three cofounders of the record label “Aggro Berlin”, during its operating time biggest independent label in Europe. His work and achievements for - and within the HipHop Culture makes him a living HipHop legend. But this was just the beginning! His creative work is far from over-his stunning output as a Director reflects his passionate connection between art, urban culture and a highly sophisticated visual approach.",
    "artist.modal.specter.subHeader": "Specter Berlin",
    "artist.modal.specter.headline": "Journey of your life - „Knock Knock! Who ́s there?” by Specter Berlin",
    "artist.modal.specter.text": "The journey of Specters life is dedicated to the creation of new worlds and original characters by telling their life stories.\nBuilding the future & being part of an art collective – Specter is part of the Meta Group HellYes.\nSo take a peek- who’s there!?",
    "artist.plastic_pen.subtitle": "Stefan Grosse Halbuer - DESIGNER AND ILLUSTRATOR",
    "Stefan Grosse Halbuer": "Stefan Grosse Halbuer",
    "artist.plastic_pen.text": "Freelance designer & digital artist for 10 years. “I love telling stories through my art, building little worlds and diving deep into details.”",
    "artist.modal.plastic_pen.subHeader": "Stefan Grosse Halbuer - DESIGNER AND ILLUSTRATOR",
    "artist.modal.plastic_pen.headline": "Stefan Grosse Halbuer - “Doors”",
    "artist.modal.plastic_pen.text": "“For me, the journey of my life is about doors opening and closing. Whenever an opportunity shows up, it‘s a door I can walk through - or not. „Doors“ represents these choices and chances, and the many dimensions a door can have.”",
    "artist.thomas.subtitle": "Thomas Fleck - the designer",
    "artist.thomas.text": "“Design is my life, and life is what happens now, not yesterday and not tomorrow.”\nWith a focus on distinctiveness, functionality and style, Thomas develops holistic design solutions. It is important to him to think of each project in a new and distinctive way, and not to fall back on existing projects.",
    "artist.modal.thomas.subHeader": "Thomas Fleck",
    "artist.modal.thomas.headline": "Distinctive, graceful designs",
    "artist.modal.thomas.text": "Origin of the universe through the lense of future technologies. Design is my life, and life is what is happening now, not yesterday, not tomorrow.",
    "Choose your Artist": "Choose your Artist",
    "Launch soon": "Launch soon",
    "Days": "Days",
    "Hours": "Hours",
    "Minutes": "Minutes",
    "Seconds": "Seconds",
    "View": "View",
    "Frequently Asked Questions": "Frequently Asked Questions",
    "Legal": "Legal",
    "Terms & Conditions": "Terms & Conditions",
    "Aktionsbedingungen": "Aktionsbedingungen",
    "Disclaimer": "Disclaimer",
    "Data Privacy": "Data Privacy",
    "About": "About",
    "Imprint": "Imprint",
    "MX1 GE Raffle Winner": "MX1 GE Raffle Winner",
    "Support": "Support",
    "Contact": "Contact",
    "FAQs": "FAQs",
    "Copyright© 2023 - {currentYear} Samsung. All Rights reserved.": "Copyright© 2023 - {currentYear} Samsung. All Rights reserved.",
    "generators.about.illuvium.text": "Edition of 2,000",
    "generators.prices.illuvium.copy": "Meet Illuvium Labs, a Web3 game studio that creates cutting-edge interoperable games. Their commitment to pushing the boundaries of the games industry makes them an exceptional partner, offering enticing prizes including a total of 2000 Samsung x Illuvium design skin packs. Each pack contains skins of a Ranger suit, Bach drone, Mozart drone, Chopin drone and Hilde drone.",
    "generators.about.ledger.text": "Edition of 50",
    "generators.prices.ledger.copy": "Introducing Ledger, the top digital asset security authority, which celebrates its tenth anniversary in 2024 and secures 20% of global crypto assets with 6 million devices worldwide. We are happy to announce they offer 50 custom Ledger Nano X Devices as prizes.",
    "generators.about.wilderworlds.text": "Edition of 20",
    "generators.prices.wilderworlds.copy": "Dive into the Wilder World metaverse, where reality blurs with cutting-edge tech. With its focus on photorealism, blockchain, and AI, Wilder World offers an unparalleled immersive experience. Plus, win exclusive NFT prizes - 5 GENs, 7 Motos, 3 Wapes, 4 Wheels or 1 Wolf!",
    "generators.about.wow.text": "Edition of 75",
    "generators.prices.wow.copy": "Discover World of Women (WoW), championed by renowned artist Yam Karkai, promoting female representation in the digital era. Partnering with Samsung, WoW brings diverse art to homes worldwide. Win exclusive NFTs: 5 Wow, 20 WoWG, 50 WoW Artfest! ",
    "generators.about.samsung.text": "Edition of 93",
    "generators.prices.samsung.copy": "Samsung celebrates the GEN3RATOR launch with a great set of high class hardware devices and gadgets, accompanied with exclusive digital Art from Belvedere Museum Wien. Win either one of 75 Belvedere The Kiss NFTs or one of 5 Samsung S23 Ultra, 5 Samsung S24 Ultra, 3 Samsung web3 TV Bundles or 5 Ledger Nano X MX1 Edition.",
    "generators.about.title": "Discover the GEN3RATORs from Samsung and partner collections.",
    "generators.about.copy": "Together with partner collections, Samsung connects the ecosystem in Web3, acting as an enabler and connector. These long-term partnerships open up new digital possibilities.",
    "Securely start your crypto journey": "Securely start your crypto journey",
    "Secure, grow your crypto and manage your NFTs \n with the new Bluetooth-enabled hardware wallet from Ledger.": "Secure, grow your crypto and manage your NFTs \n with the new Bluetooth-enabled hardware wallet from Ledger.",
    "Every journey in web3 should start with a Ledger. Together with our partner Ledger we enable you something epic.": "Every journey in web3 should start with a Ledger. Together with our partner Ledger we enable you something epic.",
    "Experience first-class NFT and crypto security with the Samsung x Ledger Web3 Starter Bundle and get an exclusive Samsung MX1 GENESIS NFT.": "Experience first-class NFT and crypto security with the Samsung x Ledger Web3 Starter Bundle and get an exclusive Samsung MX1 GENESIS NFT.",
    "Secure your valuable digital assets with the Ledger Nano X hardware wallet. Manage and grow them, always with the Ledger Live app. It’s simple to get started.": "Secure your valuable digital assets with the Ledger Nano X hardware wallet. Manage and grow them, always with the Ledger Live app. It’s simple to get started.",
    "For information on how to get your ledger from the Web3 Starter Bundle and what other benefits come with it see <span class='pointer-url'>here</span>.": "For information on how to get your ledger from the Web3 Starter Bundle and what other benefits come with it see <span class='pointer-url'>here</span>.",
    "GEN3RATOR": "GEN3RATOR",
    "Partner": "Partner",
    "Attached": "Attached",
    "GEN3 Chip": "GEN3 Chip",
    "View Prize": "View Prize",
    "Continue Claim": "Continue Claim",
    "Currently nothing to claim": "Currently nothing to claim",
    "Attach GEN3 Chip": "Attach GEN3 Chip",
    "Connect to {networkName} network first": "Connect to {networkName} network first",
    "Attaching is disabled": "Attaching is disabled",
    "Detach GEN3 Chip": "Detach GEN3 Chip",
    "Do you really want to detach your {attachedChip}?": "Do you really want to detach your {attachedChip}?",
    "You can't claim rewards with the GEN3RATOR until you attach again a GEN3 Chip": "You can't claim rewards with the GEN3RATOR until you attach again a GEN3 Chip",
    "GEN3 Chip will go back to your Inventory": "GEN3 Chip will go back to your Inventory",
    "You can use the GEN3 Chip again on any GEN3RATOR": "You can use the GEN3 Chip again on any GEN3RATOR",
    "Confirm": "Confirm",
    "Do you reallly want to claim your {tokenName} reward?": "Do you reallly want to claim your {tokenName} reward?",
    "Once claimed the GEN3RATOR can not claim this reward again": "Once claimed the GEN3RATOR can not claim this reward again",
    "Depending on the reward you need to enter different contact information": "Depending on the reward you need to enter different contact information",
    "Claiming your Reward": "Claiming your Reward",
    "Your reward is being claimed. Sit tight!": "Your reward is being claimed. Sit tight!",
    "This transaction takes longer than usual.": "This transaction takes longer than usual.",
    "Reward claimed!": "Reward claimed!",
    "Congratulations! Your reward has been successfully claimed. However, we need some information from you to complete the process. Please continue.": "Congratulations! Your reward has been successfully claimed. However, we need some information from you to complete the process. Please continue.",
    "Something unexpected happened, please restart the service and view the error.": "Something unexpected happened, please restart the service and view the error.",
    "Submitting Form": "Submitting Form",
    "Kindly wait while we get the last step to finish": "Kindly wait while we get the last step to finish",
    "Attach GEN3 Chip to your GEN3RATOR": "Attach GEN3 Chip to your GEN3RATOR",
    "Select one of your GEN3 Chips you want to attach": "Select one of your GEN3 Chips you want to attach",
    "Attach now": "Attach now",
    "Attaching GEN3 Chip": "Attaching GEN3 Chip",
    "The chosen GEN3 Chip is being attached to your GEN3RATOR. In just a moment, your GEN3RATOR will be activated!": "The chosen GEN3 Chip is being attached to your GEN3RATOR. In just a moment, your GEN3RATOR will be activated!",
    "GEN3 Chip Attached!": "GEN3 Chip Attached!",
    "GEN3 Chip was successfully attached to your GEN3RATOR": "GEN3 Chip was successfully attached to your GEN3RATOR",
    "Attaching successful": "Attaching successful",
    "Congratulations! Your GEN3 Chip is now attached to your GEN3RATOR": "Congratulations! Your GEN3 Chip is now attached to your GEN3RATOR",
    "Once claim window is open use your activated GEN3RATOR to claim your rewards": "Once claim window is open use your activated GEN3RATOR to claim your rewards",
    "Claiming is only possible if you hold the activated GEN3RATOR": "Claiming is only possible if you hold the activated GEN3RATOR",
    "You can detach your GEN3 Chip no earlier than 24 hours after attaching it": "You can detach your GEN3 Chip no earlier than 24 hours after attaching it",
    "inputType.physicalAddress": "Congratulations, your claim was successful! To ensure smooth delivery, please provide your shipping address below. Take care your name is clearly visible at the provided address and that you can receive packages. Once you double checked your shipping datas, please submit.\n\nWe will only use your data for delivery purposes.",
    "inputType.walletAddress": "Congratulations, your claim was successful! To ensure you get your digital NFT smooth and easy, we’ll need your public wallet address. Your NFT will be airdropped to this address of a Ethereum / Polygon wallet. Please make sure, you use a wallet that allows you to receive and transfer NFTs, you have access to this wallet and its the address you want us to airdrop the claimed token to.\n\nMake sure to double-check that your wallet address is correct, as any items sent to an incorrect address cannot be recovered.",
    "inputType.imxPassportAddress": "Congratulations, your claim was successful!\n\nIlluvium operates on the Immutable X blockchain. To receive your assets, you need a valid Immutable Passport (IMX Passport) address. To create it, visit illuvium.io. Make sure to double-check that your address is correct, as any items sent to an incorrect address cannot be recovered.",
    "IMX Passport Address": "IMX Passport Address",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "ZIP or postal code": "ZIP or postal code",
    "Detach possible in": "Detach possible in",
    "Manage your GEN3RATORs and attached GEN3 Chips": "Manage your GEN3RATORs and attached GEN3 Chips",
    "You don't have any GEN3RATORs yet": "You don't have any GEN3RATORs yet",
    "These are your owned unattached GEN3 Chips": "These are your owned unattached GEN3 Chips",
    "You don't own any unattached GEN3 Chips": "You don't own any unattached GEN3 Chips",
    "Connect your wallet to view your GEN3RATORs and GEN3 Chips": "Connect your wallet to view your GEN3RATORs and GEN3 Chips",
    "You successfully minted the {tokenTitle} Token.": "You successfully minted the {tokenTitle} Token.",
    "You can see it in your Wallet": "You can See it in your Wallet",
    "Check your transaction on Polygonscan": "Check your transaction on Polygonscan",
    "Check your transaction on Etherscan": "Check your transaction on Etherscan",
    "Validating Voucher Code": "Validating Voucher Code",
    "We are validating your voucher code.": "We are validating your voucher code.",
    "Validating Allowlist": "Validating Allowlist",
    "We are validating that your wallet is in the allowlist.": "We are validating that your wallet is in the allowlist.",
    "Generating Airdrop": "Generating Airdrop",
    "The token is going to be airdropped to your wallet.": "The token is going to be airdropped to your wallet.",
    "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.": "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time.",
    "Something went wrong minting your token": "Something went wrong minting your token",
    "We could not mint your Token!": "We could not mint your Token!",
    "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.": "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.",
    "Something went wrong validating": "Something went wrong validating",
    "We could not validate your code!": "We could not validate your code!",
    "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.": "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at minting.gen3@samsung.com and include the full error message.",
    "Info": "Info",
    "free": "free",
    "Total Minted": "Total Minted",
    "Blockchain": "Blockchain",
    "Token Standard": "Token Standard",
    "You have to select first the Artist you want to mint. Choose wisely, this can't be changed!": "You have to select first the Artist you want to mint. Choose wisely, this can't be changed!",
    "You don't have a code yet? Find out more here.": "You don't have a code yet? Find out more here.",
    "Don't have a code for the free mint yet Here is how you can get your NFT or a code?": "Don’t have a code for the free mint yet Here is how you can get your NFT or a code?",
    "Don't have a code for the MX1 ART COLLECTION MINT?": "Don’t have a code for the MX1 ART COLLECTION MINT?",
    "mintpass.tx1.genesis.nocodemodal.header": "Don’t have a code for the free mint yet Here is how you can get your NFT or a code?",
    "Regardless of our minting windows, you always have the option to buy the MX1GE or MX1AC NFT on the secondary market. For example on OpenSea. Enclosed the original link: LINK FOLLOWS<br />In the first phase of the project from 01.16 - 01.02 you had the possibility to get a code by registering on Amazon.<br />You missed this window? No big deal :-)<br />We will provide our partner Ledger and our Artist with additional codes to give out for free. Our tip: Keep an eye on the communication channels of Ledger and the Artists.": "Regardless of our minting windows, you always have the option to buy the MX1GE or MX1AC NFT on the secondary market. For example on OpenSea. Enclosed the original link: <a href='https://opensea.io/collection/samsung-mx1-genesis-edition' target='_blank' rel='noreferrer'>https://opensea.io/collection/samsung-mx1-genesis-edition</a><br />In the first phase of the project from 01.16 - 01.02 you had the possibility to get a code by registering on Amazon.<br />You missed this window? No big deal :-)<br />We will provide our partner Ledger and our Artist with additional codes to give out for free. Our tip: Keep an eye on the on the communication channels of Ledger and the Artists.",
    "The Codes for the MX1AC created by Antoni Tudisco, Bruno Urli, Charlotte Taylor and Anthony Authié is only available for users that have pre-ordered the Samsung Galaxy S23 through Amazon in the time from 01.02.2023 until 16.02.2023.<br /><br />If you hold a Samsung MX1 GENESIS EDITION Community Token NFT during the Snapshot at 14.02.2023 11PM CET you are eligible for the Raffle which includes the chance to win a MX1AC NFT randomly. There will be also the chance to get a free-code from the Artists or Ledger.": "The Codes for the MX1AC created by Antoni Tudisco, Bruno Urli, Charlotte Taylor and Anthony Authié is only available for users that have pre-ordered the Samsung Galaxy S23 through Amazon in the time from 01.02.2023 until 16.02.2023.<br /><br />If you hold a Samsung MX1 GENESIS EDITION Community Token NFT during the Snapshot at 14.02.2023 11PM CET you are eligible for the Raffle which includes the chance to win a MX1AC NFT randomly. There will be also the chance to get a free-code from the Artists or Ledger.",
    "mintpass.tx1.genesis.nocodemodal.body": "Regardless of our minting windows, you always have the option to buy the TX1 NFT on the secondary market. For example on OpenSea. <br />\nTo obtain a code for minting the Samsung TX1 Token, you must have registered for an email notification to participate in the TX1 campaign on samsung.com/de or gen3.samsung.com between 11.04.2024 and 24.04.2024 and received an email notification for registration with an activation code.",
    "Please enter your unique Mint Voucher": "Please enter your unique Mint Voucher",
    "e.g. CODE123 (required)": "e.g. CODE123 (required)",
    "You are using the wrong voucher code. Please click on the link below to learn where you can find your personal code.": "You are using the wrong voucher code. Please click on the link below to learn where you can find your personal code.",
    "It looks like you want to use a mint code for a different page.": "It looks like you want to use a mint code for a different page.",
    "Click here to go to correct page": "Click here to go to correct page",
    "You need to connect your Wallet to be able to mint.": "You need to connect your Wallet to be able to mint.",
    "You are asked to sign a message to generate a secure login connection for your Wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "You are asked to sign a message to generate a secure login connection for your Wallet. We won't have access to your funds, NFTs or perform any actions with this information.",
    "MX1 ART Collection": "MX1 ART Collection",
    "Minting starts Friday evening, 17. Februar.": "Minting starts Friday evening, 17. Februar.",
    "The Samsung MX1 ART COLLECTION includes 9 different artpieces of 4 well known artists form the segments fashion and 3-motion, photography and architecture and interior design.  Artists: Antoni Tudisco, Bruno Urli, Charlotte Taylor, Anthony Authié and Nico Chiara. 100% royalty fees will be provided to artists of the pieces.": "The Samsung MX1 ART COLLECTION includes 9 different artpieces of 4 well known artists form the segments fashion and 3-motion, photography and architecture and interior design. Artists: Antoni Tudisco, Bruno Urli, Charlotte Taylor, Anthony Authié and Nico Chiara. 100% royalty fees will be provided to artists of the pieces.",
    "Mint starts soon": "Mint starts soon",
    "If you ordered the limited Samsung x Ledger web3 starter bundle or the Samsung Galaxy S23 between 01. Feb - 17. Feb 2023 via Amazon you will receive a unique mint voucher code.": "If you ordered the limited Samsung x Ledger web3 starter bundle or the Samsung Galaxy S23 between 01. Feb - 16. Feb 2023 via Amazon you will receive a unique mint code.",
    "Use this code to claim your Samsung MX1 ART Collection NFT of your favorite Artist.": "Use this code to claim your Samsung MX1 ART Collection NFT of your favorite Artist.",
    "You want to bookmark upcoming Samsung mints? Add them to your calendar here.": "Add Samsung MX1 Art Collection Mint to your calendar.",
    "Bookmark Mint Calendar": "Bookmark Mint Date",
    "Where can I get my code?": "Where can I get my code?",
    "This is how you obtain a MX1 Genesis Edition Code": "This is how you obtain a MX1 Genesis Edition Code",
    "This is how you obtain a MX1 Art Collection Code": "This is how you obtain a MX1 Art Collection Code",
    "bundle.title": "Samsung x Ledger Partnership ",
    "bundle.text": "Get your Samsung x Ledger Nano X* to secure and manage your crypto & NFTs.\nUse the unique promo code you received with your Web3 Starter Bundle, to get this Ledger Nano X  that includes a standard Ledger Nano X and a limited custom packaging.",
    "Terms apply": "Terms apply",
    "bundle.button": "Pre-Order Now",
    "teaser.tvbundle.title": "Samsung web3 TV Bundle",
    "teaser.tvbundle.copy": "The journey continues: Obtain your Samsung x Ledger Nano X for secure crypto and NFT management. Utilize the exclusive promo code accompanying your Sero or Frame TV in the Samsung web3 TV Bundle* to acquire this Ledger Nano X, featuring a standard model along with special limited-edition packaging. As an added bonus, you’ll also receive one of the limited digital Samsung GEN3RATORs.",
    "teaser.tvbundle.cta": "Secure Your Bundle Now",
    "Congratulations": "Congratulations",
    "Raffle Winners": "Raffle Winners",
    "faq.roadmap.ninth.answer": "<p>Prizes: 6x Samsung x Ledger web3 starter Bundle including: a brand new Samsung Galaxy S23 from the latest product launch and a Ledger Nano X hardware wallet. \n<br />\n<br />\n200 x random Samsung MX1 ART COLLECTION NFTs from PHASE 2 created by Antoni Tudisco, Bruno Urli, Charlotte Taylor and Anthony Authié\n.</p>",
    "Click here to check MX1 GE Winner Tokens": "Click here to check MX1 GE Winner Tokens",
    "Register now on Amazon": "Register now on Amazon",
    "Register now without obligation on the Amazon promotion page and get your Samsung MX1 Genesis Edition NFT free mint.": "Register now without obligation on the Amazon promotion page and get your Samsung MX1 Genesis Edition NFT free mint.",
    "Register Now": "Register Now",
    "more": "more",
    "Tier Details": "Tier Details",
    "artist.jaiden.title": "Jaiden  \nStipp",
    "artist.specter.title": "Specter \nBerlin",
    "artist.plastic_pen.title": "Stefan \nGrosse \nHalbuer",
    "artist.thomas.title": "Thomas \nFleck",
    "artist.tudisco.title": "Antoni \nTudisco",
    "artist.bruno.title": "Bruno \nUrli",
    "artist.charlotte.title": "Charlotte \nTaylor",
    "artist.authie.subtitle": "Anthony Authié - the art of architecture",
    "artist.authie.title": "Anthony \nAuthié",
    "artistIntro.genesis.subtitle": "The first Samsung community token with future utility.",
    "artistIntro.genesis.title": "Samsung MX1 GENESIS EDITION NFT",
    "artistIntro.genesis.smallText": "Discover the MX1GE NFT designed by the following artists:",
    "artistIntro.genesis.bigText": "Thomas Fleck [Germany], Jaiden Stipp [USA], Stefan Grosse Halbuer [Germany] and Specter [Germany]. Every artist interprets his personal journey of his life in a creative and intimate way. The MX1GE will open up the possibility for holders to have real life utility by Samsung.",
    "artistIntro.art.subtitle": "One of the worlds most beautiful facets: ART",
    "artistIntro.art.title": "SAMSUNG MX1 ART COLLECTION NFT ARTISTS",
    "artistIntro.art.smallText": "We’ve created a great mixture of the most hyped artist at time for you.",
    "artistIntro.art.bigText": "The digital artworks by world-renowned 3D artists such as “NFT-OG” Antoni Tudisco, Charlotte Taylor, Anthony Authié and the prodigy of NFT photography, 18-year-old Bruno Urli.",
    "Highlights": "Highlights",
    "Raffles": "Raffles",
    "Deals": "Deals",
    "Holder Benefits": "Holder Benefits",
    "benefits.intro.subtitle": "GEN3 COD3X",
    "benefits.intro.title": "Exclusive Member Benefits",
    "benefits.intro.smallText": "The program with exclusive sweepstakes and benefits for members only in the Samsung GEN3 ecosystem.",
    "benefits.intro.bigText": "With a mix of regular small perks and major activations. From everyday goodies to big events, these benefits are designed to reward you as part of Samsung GEN3. Check back regularly – there’s always something new to discover.",
    "There aren't raffles available at this time.": "There aren’t raffles available at this time.",
    "There aren't deals available at this time.": "There aren’t deals available at this time.",
    "Cookies": "Cookies",
    "Introduction": "Introduction",
    "Any   functional   non-fungible   token ('NFT')   running   on   a   blockchain, which can be or was generated or issued   ('Minted')   on   this   website ('SAMSUNG   NFT')   and   is   lawfully owned,   and   the   underlying   digital artwork or digital collectible linked to   them   ('Art')   are   subject   to   the following   terms   and   conditions ('these   T&Cs'),   provided   by  [full corporate name of Samsung entity running   the   project   and   the website]  ('Samsung'   or   'us'   or 'we').": "Any   functional   non-fungible   token (‘NFT’)   running   on   a   blockchain, which can be or was generated or issued   (‘Minted’)   on   this   website (‘SAMSUNG   NFT’)   and   is   lawfully owned,   and   the   underlying   digital artwork or digital collectible linked to   them   (‘Art’)   are   subject   to   the following   terms   and   conditions (‘these   T&Cs’),   provided   by  [full corporate name of Samsung entity running   the   project   and   the website]  (‘Samsung’   or   ‘us’   or ‘we’).",
    "Website as interface": "Website as interface",
    "This   website   is   not   on   the blockchain but acts as interface for the   user   (“user”   or   “you”)   to process the Minting of a SAMSUNG NFT   and   interact   with   the   related smart   contract,   and   for   secondary purchasers   of   a   SAMSUNG   NFT,   to access   these   T&Cs.   By   using   this website   and/or   by   Minting   or purchasing or selling (or by offering to   purchase   or   sell)   a   SAMSUNG NFT   and/or   the   underlying   Art   on any   platform   and/or   by   holding   a SAMSUNG   NFT   in   your   wallet,   you consent   to   the   terms   set   forth herein.": "This   website   is   not   on   the blockchain but acts as interface for the   user   (“user”   or   “you”)   to process the Minting of a SAMSUNG NFT   and   interact   with   the   related smart   contract,   and   for   secondary purchasers   of   a   SAMSUNG   NFT,   to access   these   T&Cs.   By   using   this website   and/or   by   Minting   or purchasing or selling (or by offering to   purchase   or   sell)   a   SAMSUNG NFT   and/or   the   underlying   Art   on any   platform   and/or   by   holding   a SAMSUNG   NFT   in   your   wallet,   you consent   to   the   terms   set   forth herein.",
    "Risk   assumption   /   your responsibility": "Risk   assumption   /   your responsibility",
    "Minting and transactions": "Minting and transactions",
    "You   as   the   person   Minting   can process   the   transaction   through this   website   by   connecting   your crypto   wallet   and   performing   and confirming   the   transactions indicated in your wallet. You accept and   acknowledge   that   we   are   not responsible   for   the   validation   and confirmation   of   any   transaction   or contract   through   your   own   wallet, but that this happens at your own judgment   and   under   your   own control.   Transactions   on   the blockchain   sometimes   go   wrong and get stuck, while the underlying transaction costs  (“Gas Fees”) still apply   and   can   be   irrevocably   lost, even   in   a   broken   transaction. Samsung does not take any liability in such case. Notwithstanding that, Samsung   may   offer   but   has   no obligation   to   do   so,   to   cover   Gas Fees   related   to   the   Minting   of   a SAMSUNG NFT.": "You   as   the   person   Minting   can process   the   transaction   through this   website   by   connecting   your crypto   wallet   and   performing   and confirming   the   transactions indicated in your wallet. You accept and   acknowledge   that   we   are   not responsible   for   the   validation   and confirmation   of   any   transaction   or contract   through   your   own   wallet, but that this happens at your own judgment   and   under   your   own control.   Transactions   on   the blockchain   sometimes   go   wrong and get stuck, while the underlying transaction costs  (“Gas Fees”) still apply   and   can   be   irrevocably   lost, even   in   a   broken   transaction. Samsung does not take any liability in such case. Notwithstanding that, Samsung   may   offer   but   has   no obligation   to   do   so,   to   cover   Gas Fees   related   to   the   Minting   of   a SAMSUNG NFT.",
    "Raffle": "Raffle",
    "You are registered for this event": "You are registered for this event",
    "Event Type": "Event Type",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Sign up for this event": "Sign up for this event",
    "This event has ended.": "This event has ended.",
    "Unlocking Content": "Unlocking Content",
    "You have signed up for this event": "You have signed up for this event",
    "View event": "View event",
    "There was a problem while signing up for this event.": "There was a problem while signing up for this event.",
    "There was a problem showing this event. Please contact support on discord": "There was a problem showing this event. Please contact support on discord",
    "Events": "Events",
    "Upcoming": "Upcoming",
    "Completed": "Completed",
    "There was a problem listing all the unlockable contents. Please contact support on discord": "There was a problem listing all the unlockable contents. Please contact support on discord",
    "General": "General",
    "Page not found": "Page not found",
    "You are not authorized to see this page!": "You are not authorized to See this page!",
    "Please sign in with Wallet first": "Please sign in with Wallet first",
    "You have to be a holder to view this page": "You have to be a holder to view this page",
    "Become a Holder": "Become a Holder",
    "Back to Home": "Back to Home",
    "teaser.gen3rator.title": "Get ready for the GEN3RATOR",
    "teaser.gen3rator.shortdescription": "Are you ready to dive deep into Samsung’s Web3 Universe? The GEN3RATOR is our latest and most innovative digital tool, unlocking numerous exciting features on the Samsung web3 Roadmap. How can you obtain this must-have?",
    "teaser.gen3rator.subtitle": "Explore Samsung’s Web3 Universe: Ready to Dive In?",
    "teaser.gen3rator.copy": "The GEN3RATOR is our latest and most innovative digital tool, unlocking numerous exciting features on the Samsung web3 Roadmap. How can you obtain this must-have? You can either participate in cool Raffles hosted by Samsung or one of the exclusive partner collections, immerse your existing Samsung NFTs in specific combinations, join exciting X Spaces, or secure your GEN3RATOR with the purchase of a Sero or Frame TV x Ledger Bundle. You have multiple ways to take part. Get ready as we embark on our new era of digital revolution in May 2024!",
    "teaser.tvapp.title": "Samsung NFT Gallery App",
    "teaser.tvapp.copy": "Transform your TV into a dynamic NFT gallery with the NFT Gallery app. Link your digital wallet and instantly bring your NFT collections to life on the big screen. Experience your digital art in stunning clarity, making every view a unique experience. Elevate your home with the future of art display where your TV isn‘t just smart, it‘s art smart.",
    "teaser.tvapp.cta": "Discover More",
    "teaser.benefits.title": "Enhance Your Journey with the Samsung GEN3 Ecosystem",
    "teaser.benefits.copy": "Prepare to experience the Samsung GEN3 Ecosystem like never before with our collectibles unlocking a realm of possibilities by subtly blending the art of collecting with exclusive benefits. This is just a small glimpse into how the Samsung GEN3 Ecosystem will evolve in the future - stay tuned.",
    "teaser.benefits.cta": "Learn about our Holder Benefits",
    "teaser.tip.headline": "How to Get Your Hands on Samsung NFTs and Make the Most of Them",
    "teaser.partner.title": "Meet the Collab Partners",
    "teaser.partner.copy": "Explore the inspiring collaborations through which we bring our vision of innovation and creativity to life. Learn more about our partners and their fascinating products powering the Samsung GEN3 Ecosystem and create unforgettable membership experiences.",
    "teaser.partner.cta": "Explore now!",
    "Samsung Electronics GmbH \nSamsung House \nAm Kronberger Hang 6 \n65824 Schwalbach / Ts. \nDeutschland": "Samsung Electronics GmbH \nSamsung House \nAm Kronberger Hang 6 \n65824 Schwalbach / Ts. \nDeutschland",
    "Commercial register: Amtsgericht Königstein, registration number: HRB 4657 \nTAX ID.: DE811710402": "Commercial register: Amtsgericht Königstein, registration number: HRB 4657 \nTAX ID.: DE811710402",
    "Managing Director: Man Young Kim": "Managing Director: Man Young Kim",
    "Responsible for content according to § 18 Abs. 2 MStV: Silke Cosetta, address see above.": "Responsible for content according to § 18 Abs. 2 MStV: Silke Cosetta, address see above.",
    "Hotline for general inquiries: 06196-7755577* \n*Costs according to the conditions of the contract partner for fixed network connections or mobile phone connections.": "Hotline for general inquiries: 06196-7755577* \n*Costs according to the conditions of the contract partner for fixed network connections or mobile phone connections.",
    "For general inquiries please contact: kundenbetreuung[at]samsung.de": "For general inquiries please contact: kundenbetreuung[at]samsung.de",
    "For General Samsung gen3 support please contact: support.gen3[at]samsung.com": "For General Samsung gen3 support please contact: support.gen3[at]samsung.com",
    "For Samsung minting support please contact: minting.gen3[at]samsung.com": "For Samsung minting support please contact: minting.gen3[at]samsung.com",
    "All contact options for your product or service inquiries can be found here: \nhttps://www.samsung.com/de/support/#contact": "All contact options for your product or service inquiries can be found here: \nhttps://www.samsung.com/de/support/#contact",
    "Notice according to § 36 Verbraucherstreitbeilegungsgesetz (VSBG): \nSamsung Electronics GmbH will not participate in any dispute resolution proceedings before a \nconsumer arbitration board within the meaning of the VSBG and is not obliged to do so.": "Notice according to § 36 Verbraucherstreitbeilegungsgesetz (VSBG): \nSamsung Electronics GmbH will not participate in any dispute resolution proceedings before a \nconsumer arbitration board within the meaning of the VSBG and is not obliged to do so.",
    "generators.header.title": "SAMSUNG GEN3RATOR & GEN3 Chip",
    "generators.header.description": "Embark on Samsung’s Web3 Universe by getting your hands on one of our exclusive digital GEN3RATORs. Together with Web3 Ecosystem Partners we’ve created a unique mechanism to unlock further rewards and benefits.\n\nMinting starts May 2024.",
    "Contract": "Contract",
    "generators.nocodemodal.header": "Don’t have a code for the mint yet? Here is how you can get your NFT or a code?",
    "generators.nocodemodal.body": "Regardless of our minting windows, you always have the option to buy a GEN3RATOR or GEN3 Chip NFT on the secondary market. For example on OpenSea.<br />\n<br />\nDuring Promotion Period from April 24 - September 30, 2024, 11:59 PM its possible to get GEN3RATOR and GEN3 Chip minting codes by purchasing one of 1,500 Samsung web3 TV Bundles via Samsung Online Shop. Detailed Terms you can find here (Link: <a href=\"https://gen3.samsung.com/en/disclaimer_tv_bundle\" target=\"_blank\" rel=\"noreferrer\">https://gen3.samsung.com/en/disclaimer_tv_bundle</a>).<br />\n<br />\nYou missed this offer? No big deal.<br />\nGet GEN3RATOR and/ or GEN3 Chip minting codes as a reward for our Holders via our Holder Benefits (Link: https://gen3.samsung.com/en/community/benefits) page.\nIn addition we will provide our great partners (Link: <a href=\"https://gen3.samsung.com/en/community/collabs\" target=\"_blank\" rel=\"noreferrer\">https://gen3.samsung.com/en/community/collabs</a>) with additional codes to give out for free. Our tip: Keep an eye on the on the communication channels of our Partners.",
    "generators.howto.title": "How you can claim your GEN3RATOR and Samsung GEN3 Chip",
    "generators.howto.copy": "To gain access to the GEN3RATOR and the required Samsung GEN3 Chip, there are three main paths available to you:<br />\n<br />\n1.) Purchase of the Samsung web3 TV Bundle: By acquiring the Samsung web3 TV Bundle, you automatically receive both the GEN3RATOR and the GEN3 Chip as NFTs necessary for activation.<br />\n2.) Holder Benefits: As a committed member of the Samsung community, participating in future activities and through your engagement, you have opportunities to obtain the GEN3RATOR and Chip.<br />\n3.) Partner Promotions: Keep an eye out for special promotions from our partner collections that offer you a chance to win a GEN3RATOR or Chip.<br />\n<br />\nYou must combine both NFTs to activate your digital GEN3RATOR, which in turn grants you access to exclusive NFTs and other benefits. Always keep your GEN3RATOR and Chip in your wallet to utilize all future benefits.",
    "Minting Section": "Minting Section",
    "Please connect your wallet": "Please connect your wallet",
    "Terms and Conditions": "Terms and conditions",
    "Deal": "Deal",
    "Your Unlocked Codes": "Your Unlocked Codes",
    "The content has been unlocked successfully": "The content has been unlocked successfully",
    "View content": "View content",
    "There was a problem unlocking this unlockable content.": "There was a problem unlocking this unlockable content.",
    "There was a problem showing this unlockable content. Please contact support on discord": "There was a problem showing this unlockable content. Please contact support on discord",
    "deals.title": "Exclusive Samsung x Ledger Deal for MX1 Token Holders",
    "deals.subtitle": "Secure your Ledger Nano X 60€ Voucher for MX1GE Holder, Free for S23 Purchaser with MX1AC*",
    "deals.paragraph.one": "We are thrilled to announce an epic partner deal for our esteemed MX1 Token Holders. As part of this exciting offer, you are eligible to unlock the following benefits:",
    "deals.paragraph.two": "<ul>\n  <li>A 60€ voucher code towards the purchase of a Samsung x Ledger Nano X. This offer is exclusively available to our MX1GE Token Holders, providing a golden opportunity to safeguard your valuable digital assets.</li>\n  <li>*In addition, if you happen to be a MX1AC Token Holder who purchased the Samsung Galaxy S23 through Amazon, you are eligible to unlock a 100% discount code, allowing you to acquire a Samsung x Ledger Nano X for free!</li>\n</ul>",
    "deals.paragraph.three": "Start your crypto journey securely with Ledger, a leading provider of hardware wallets for crypto assets. The Ledger Nano X is the perfect device for securely managing your digital assets. With over 5,500 coins and tokens supported, including Bitcoin, Ethereum, XRP, and more, you can be confident that your entire portfolio is safe and accessible.\nWhen you own crypto, you need to keep your private key safe. With Ledger wallets, you can be sure that your key is protected with industry-leading security features. This means that you can safely start your crypto journey and confidently manage your NFTs with our new Bluetooth-enabled hardware wallet.",
    "Unlock your personal voucher code": "Unlock your personal voucher code",
    "There are currently no deals for you to unlock.": "There are currently no deals for you to unlock.",
    "To get your voucher code, simply connect your wallet and unlock the \"partner deal\" with one of your existing Samsung MX1GE token. Once you have done this, the voucher code will be shown to you (note that it will only be shown once) and shared with you via email. You can then use this code to purchase your Ledger Nano X at a discounted price.": "To get your voucher code, simply connect your wallet and unlock the “partner deal” with one of your existing Samsung MX1GE token. Once you have done this, the voucher code will be shown to you (note that it will only be shown once) and shared with you via email. You can then use this code to purchase your Ledger Nano X at a discounted price.",
    "*Please be aware that not every MX1GE token can unlock a voucher code. Only users who had one or more MX1GE tokens in their wallet at the specified snapshot on 07.05.2023 are eligible to unlock a voucher code using one (!) of their tokens. It's important to note that owning multiple tokens does not guarantee the ability to unlock an equivalent number of vouchers.": "*Please be aware that not every MX1GE token can unlock a voucher code. Only users who had one or more MX1GE tokens in their wallet at the specified snapshot on 07.05.2023 are eligible to unlock a voucher code using one (!) of their tokens. It’s important to note that owning multiple tokens does not guarantee the ability to unlock an equivalent number of vouchers.",
    "There was a problem listing all the partner deals. Please contact support.gen3@samsung.com.": "There was a problem listing all the partner deals. Please contact support.gen3@samsung.com.",
    "Successfully Unlocked!": "Successfully Unlocked!",
    "There was a problem unlocking this Partner Deal. Please contact support.gen3@samsung.com.": "There was a problem unlocking this Partner Deal. Please contact support.gen3@samsung.com.",
    "partners.ledger.subtitle": "Hardware- & Offline-Wallet",
    "partners.ledger.title": "Ledger",
    "partners.ledger.description": "Celebrating its 10 year anniversary in 2024, Ledger is the world leader in Digital Asset security for consumers and enterprises. Ledger offers connected devices and platforms, with more than 6M devices sold to consumers in 200 countries and 10+ languages, 100+ financial institutions and commercial brands. Over 20% of the world’s crypto assets are secured by Ledger.",
    "partners.ledger.modal.subHeader": "Market leader in hardware wallets and crypto security",
    "partners.ledger.modal.headline": "Ledger",
    "partners.ledger.modal.text": "Samsung and Ledger have long enjoyed a robust partnership, anchoring their commitment to Web3 security. With a keen emphasis on Web3 education for all its customers, Samsung recognizes the importance of having a strong, established, and specialized partner like Ledger. This collaboration underscores Samsung’s dedication to keeping its customers at the forefront of technology with reliable security solutions.",
    "partners.ledger.link": "https://www.ledger.com/",
    "partners.wiami.subtitle": "A New Dimension of Reality",
    "partners.wiami.title": "Wilder World",
    "partners.wiami.description": "WIRE IN\nWilder World is a massive multiplayer metaverse that leverages the latest advances in real-time photorealism, blockchain, artificial intelligence and massive scale multiplayer technology. You, as a Wilder, control its future!\n\nDEFY THE FORUM. SAVE REALITY.\nIn 1945, the detonation of the atomic bomb caused The Great Fork, entangling Our Base Reality with another. Wilder World became the bridge between these two worlds.\n\nDEFEND WIAMI.\nWiami is the first city in Wilder World. At its center is the KERNEL – a source code module designed to decentralize power and limit outside forces vying to take control of the metaverse.",
    "partners.wiami.modal.subHeader": "A New Dimension of Reality",
    "partners.wiami.modal.headline": "Wilder World",
    "partners.wiami.modal.text": "Everything in Wiami, from avatars to clothing, vehicles, land, buildings, and more, will be NFTs that are fully tradable on the Wilder World Metaverse Market. Wilder World NFTs are procedurally generated and are amongst the highest quality and highest utility NFTs ever created. Explore some of its collections here.",
    "partners.wiami.link": "https://www.wilderworld.com/",
    "partners.illuvium.subtitle": "A universe of games",
    "partners.illuvium.title": "Illuvium",
    "partners.illuvium.description": "Illuvium is a fully decentralised global studio building the world’s first Interoperable Blockchain Game (IBG) universe including an open-world exploration game, industrial city builder, and autobattler, all on the Ethereum blockchain.\n\nJoin a graphically-rich sci-fi adventure, explore 7 dazzling alien landscapes and conquer the wilderness to uncover the cataclysmic events that shattered Illuvium and help your crash-landed crew flourish! ",
    "partners.illuvium.modal.subHeader": "Welcome to the world of Illuvium",
    "partners.illuvium.modal.headline": "Illuvium – Fight for ETH",
    "partners.illuvium.modal.text": "Join a graphically-rich sci-fi adventure, explore 7 dazzling alien landscapes and conquer the wilderness to uncover the cataclysmic events that shattered Illuvium and help your crash-landed crew flourish!",
    "partners.illuvium.link": "https://illuvium.io/",
    "partners.wow.subtitle": "EMPOWERING WOMEN THROUGH WEB3 ART",
    "partners.wow.title": "World of Women",
    "partners.wow.description": "World of Women (WoW) emerged as a vision from celebrated digital artist Yam Karkai to ensure greater representation of women in the next era of the web. WoW’s iconic PFP generative art collections have hit historical milestones, achieving over $400m in total trading volume and a record-setting sale at Christie’s Evening Sale by a woman artist. As web3’s largest mission-driven brand, WoW has donated over $3m to charitable causes, supported and spotlighted over 500 artists through its fund, and has commissioned work from 40 artists to deliver lore-based art to its community. Now, in partnership with Samsung, WoW aims to bring diverse art and storytelling into households and physical spaces worldwide.",
    "partners.wow.modal.subHeader": "FROM ICONIC ART TO GLOBAL COMMUNITY",
    "partners.wow.modal.headline": "World of Women",
    "partners.wow.modal.text": "World of Women (WoW) has grown to a community of over 350k supporters and 13k collectors who share WoW’s vision for an inclusive future. Members connect at IRL events around the world and online on Patio, WoW’s digital hub. Explore WoW today and be part of a trailblazing community at the forefront of art, tech and culture.",
    "partners.wow.link": "https://patio.wow.art/",
    "partners.belvedere.subtitle": "The Kiss NFT",
    "partners.belvedere.title": "Belvedere Museum",
    "partners.belvedere.description": "The Belvedere is a World Heritage Site, a Baroque jewel, and the site of the Austrian State Treaty. It is both one of the oldest museums in the world and a venue for contemporary art. As one of the largest cultural institutions in the country and an Austrian landmark, we are faced with the challenge of evolving amidst the differing priorities of cultural and scientific demands, loyalty to the local community and tourism. While we follow our mission to preserve the past, we also seek to break new ground. We stand as a mediator of history and as an inconvenient interrogator of the present. We are the country’s cultural hub of international standing, and exist in the transnational digital space.",
    "partners.belvedere.modal.subHeader": "Gustav Klimt by Belvedere Museum",
    "partners.belvedere.modal.headline": "The Kiss NFT",
    "partners.belvedere.modal.text": "Gustav Klimt’s “The Kiss” is a world-renowned work of art and the highlight of the Belvedere’s collection. The prestigious museum in Vienna has released a high-resolution digital image of the masterpiece in a limited edition of 10.000 unique individual tiles. This is the only official The Kiss NFT collection of the Belvedere, each NFT is a one-of-a-kind exclusive item that can be dedicated to someone special.",
    "partners.belvedere.link": "https://thekiss.art/?ref=SAMSUNG24",
    "partners.polygonlabs.subtitle": "The Value Layer of the Internet",
    "partners.polygonlabs.title": "Polygon Labs",
    "partners.polygonlabs.description": "Polygon Labs develops Ethereum scaling solutions for Polygon protocols. Polygon Labs engages with other ecosystem developers to help make available scalable, affordable, secure and sustainable blockchain infrastructure for Web3.",
    "partners.polygonlabs.modal.subHeader": "Web3, Aggregated.",
    "partners.polygonlabs.modal.headline": "Polygon Labs",
    "partners.polygonlabs.modal.text": "Enabling an infinitely scalable web of sovereign blockchains that feels like a single chain. Powered by ZK tech.",
    "partners.polygonlabs.link": "https://polygon.technology/",
    "partners.intro.subtitle": "Samsung’s Web3 Revolution: Building Synergies, Powering Communities, and Shaping the Future",
    "partners.intro.title": "MEET THE SAMSUNG WEB3 COLLABORATORS",
    "partners.intro.smallText": "At Samsung, we believe in building networks that create synergies and forge connections. Together with our esteemed partners, we’re creating the Samsung Ecosystem into a vibrant, community-powered domain. Working on the future of the internet, we’re embracing decentralization, blockchain technology, and cryptographic security to reimagine online encounters within the trusted Samsung Ecosystem. Join us on this transformative journey, where we fuse Web3 capabilities seamlessly into the digital landscape, aiming to shape the world’s largest Web3 community—an era of interconnected possibilities, fortified trust, and boundless empowerment.",
    "partners.intro.bigText": "Lets Enable web3.",
    "You are registered for this raffle": "You are registered for this raffle",
    "Raffle Type": "Raffle Type",
    "Sign up for this raffle": "Sign up for this raffle",
    "This raffle has ended.": "This raffle has ended.",
    "You have signed up for this raffle": "You have signed up for this raffle",
    "View raffle": "View raffle",
    "There was a problem while signing up for this raffle.": "There was a problem while signing up for this raffle.",
    "There was a problem showing this raffle. Please contact support on discord": "There was a problem showing this raffle. Please contact support on discord",
    "There was a problem listing all the raffles. Please contact support on discord": "There was a problem listing all the raffles. Please contact support on discord",
    "Your wallet does not contain a token that can unlock this content. Maybe the token has already been used?": "Your wallet does not contain a token that can unlock this content. Maybe the token has already been used?",
    "Terms & Conditions TV Bundle": "Terms & Conditions TV Bundle",
    "Terms & Conditions for a raffle": "Terms & Conditions for a raffle",
    "My Tokens": "My Tokens",
    "Missing name": "Missing name",
    "Check out this Token": "Check out this Token",
    "Description": "Description",
    "Owner": "Owner",
    "(You own this token)": "(You own this token)",
    "Traits": "Traits",
    "Token ID": "Token ID",
    "Check on Marketplaces": "Check on Marketplaces",
    "Rarible": "Rarible",
    "Token Search": "Token Search",
    "Tribe": "Tribe",
    "Community Membership Tiers": "Community Membership Tiers",
    "Membership Tier": "Membership Tier",
    "Required Collectibles": "Required Collectibles",
    "To view your available tokens, please sign in.": "To view your available tokens, please sign in.",
    "Accessible Benefits for this Membership Tier": "Accessible Benefits for this Membership Tier",
    "Currently no benefits available for this tier.": "Currently no benefits available for this tier.",
    "Membership Tiers": "Membership Tiers",
    "tiers.intro.subtitle": "GEN3 COD3X",
    "tiers.intro.title": "Our Membership Tiers",
    "tiers.intro.smallText": "Join the COD3X",
    "tiers.intro.bigText": "Experience the full potential of the GEN|3|GATE with the Samsung GEN3 Membership Tiers. These tiers are your key to a world full of rewards and opportunities, specifically designed to enrich your journey through the Samsung GEN3 Ecosystem. From daily benefits to unique experiences – new doors open to you with each tier.",
    "intro.tvapp.subtitle": "Samsung NFT Gallery - TV App",
    "intro.tvapp.title": "Features that bring your NFTs to Life",
    "intro.tvapp.smallText": "Discover a new way to enjoy your digital art collection with our Samsung NFT Gallery - TV app, designed to bring your NFTs into the heart of your home. This app offers a seamless bridge between your blockchain wallet and your TV, transforming it into a live gallery that not only showcases your NFTs but also breathes new life into them. Here’s why we see our app as a game-changer in the digital art space.",
    "features.tvapp.title": "Your personal Gallery",
    "features.tvapp.content": "- Showcase of your favorite NFTs in a personalized way<br />\n- Easy management of your interactive NFT Slideshow<br />\n- Seamless wallet connection for easy access to your NFTs.<br />\n- Discover our Partner Highlight Collections<br />\n<br />\nWith these features, our NFT Gallery - App is not just about viewing art; it’s about experiencing it in a new way, making every moment at home a remarkable experience",
    "tx1.raffle.teaser.title": "Join the TX1 GEN3RATOR Raffle",
    "tx1.raffle.teaser.content": "Seize the opportunity to win a GEN3RATOR and a Samsung GEN3 Chip! Among all participants who register for this raffle, we will randomly select the lucky winners to receive a GEN3RATOR Set after July 30th.",
    "tx1.raffle.teaser.cta": "Click here to join",
    "artist.tx1ge.title": "Discover the visionary world of digital artist Paul Milinski – where art transcends boundaries to create transformative experiences",
    "artist.paulmilinski.name": "Paul Milinski ",
    "artist.paulmilinski.text": "Paul Milinski, the visionary behind Milinski Studio, breaks art barriers with digital and physical experiences. Renowned for 3D rendering and collaborations with Cartier, Louis Vuitton, and others, he reshapes spaces worldwide. Paul’s work blends technical mastery, emotion, and intellect, bridging art and luxury. With each project, he transforms the mundane into the extraordinary, redefining creativity and luxury landscapes.",
    "artist.modal.paulmilinski.subHeader": "Exploring Emotion and Innovation in Contemporary Digital Art",
    "artist.modal.paulmilinski.headline": "Digital Dreams: The Art of Paul Milinski",
    "artist.modal.paulmilinski.text": "Dive into the captivating world of Paul Milinski, the Melbourne-based artist redefining digital art. With a distinctive emotive style, Milinski’s work commands attention from global brands and collectors, culminating in record-breaking auctions. Discover how his visionary creations inspire artists worldwide, shaping the future of art.",
    "Extras": "Extras",
    "Extra": "Extra",
    "Unlocked Extra": "Unlocked Extra",
    "Secret Files": "Secret Files",
    "Filter by Category": "Filter by Category",
    "All": "All",
    "Unlockable Contents": "Unlockable Contents",
    "There are no unlockable contents yet": "There are no unlockable contents yet",
    "immediate": "immediate",
    "after vote": "after vote",
    "after end": "after end",
    "Reveal Type": "Reveal Type",
    "Your Vote has been send successfully": "Your Vote has been send successfully",
    "There was a problem showing this voting. Please contact support on discord": "There was a problem showing this voting. Please contact support on discord",
    "Votings": "Votings",
    "There are no votings yet": "There are no votings yet",
    "Open": "Open",
    "Allowlist": "Allowlist"
  },
  "es": {
    "Powered by": "Desarrollado por",
    "Wallet": "Cartera",
    "New alerts": "Nuevas alertas",
    "Not signed in with Wallet": "No ha iniciado sesión con la cartera",
    "Connected to wrong network": "Conectado a una red incorrecta",
    "Account": "Cuenta",
    "Balance": "Saldo",
    "Signing Request": "Solicitud de firma",
    "Cancel": "Cancelar",
    "Sign": "Firme",
    "You are not signed in with your Wallet yet": "Aún no has iniciado sesión con tu cartera",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Se le pedirá que firme un mensaje para generar una conexión de inicio de sesión segura para su cartera. No tendremos acceso a tus fondos, NFTs ni realizaremos ninguna acción con esta información.",
    "You are signed in with your Wallet": "Ha iniciado sesión con su cartera",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Actualmente está conectado a una red diferente a la de este contrato. Por favor, cambie su red a {networkName} en su cartera.",
    "Switch to {networkName} network": "Cambiar a la red {networkName}",
    "Copy address": "Copiar dirección",
    "Tokens": "Tokens",
    "Settings": "Configuración",
    "Activity": "Actividad",
    "Please \"Sign in with Wallet\" first.": "Por favor, primero “Inicia sesión con cartera”.",
    "See above": "Véase más arriba",
    "Change E-mail address": "Cambiar la dirección de correo electrónico",
    "Change": "Cambia",
    "Delete Account": "Eliminar cuenta",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Para eliminar su cuenta, póngase en contacto con nosotros: <a href=‘mailto:support@bowline.app’ target=‘_blank’>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para más información sobre el tratamiento de sus datos personales, consulte nuestra <a href=‘https://www.bowline.app/data-protection/' target=‘_blank’>Política de privacidad</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href=‘https://www.bowline.app/tos/' target=‘_blank’>Condiciones del servicio</a>.",
    "No Activity yet in this session": "Todavía no hay actividad en esta sesión",
    "Recent Signatures": "Firmas recientes",
    "Transactions": "Transacciones",
    "Disconnect": "Desconecte",
    "Your Tokens related to this project": "Sus Tokens relacionados con este proyecto",
    "Reload Tokens": "Recargar Tokens",
    "You do not have any Token in Wallet for this project": "Usted no tiene ningún token en su cartera para este proyecto",
    "Reload": "Recarga",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Tu cartera puede contener otros Tokens de otras dApps. Visite los sitios web de los proyectos para verlos o compruebe su cartera en, por ejemplo",
    "or": "o",
    "Connect to Wallet": "Conectar con Wallet",
    "close": "cerrar",
    "Select a Wallet": "Seleccione una Cartera",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Cartera Coinbase",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Al conectar tu monedero aceptas nuestras <a href=‘https://www.bowline.app/tos/' target=‘_blank’>Condiciones del servicio</a>.",
    "How to connect?": "¿Cómo conectarse?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Para conectarse de forma segura e interactuar con este servicio necesita tener una cartera que soporte el protocolo Web3. Por favor, seleccione una de las siguientes opciones para conectarse a su cartera.",
    "I do have a wallet.": "Tengo una cartera.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "En caso de que ya disponga de una cartera, seleccione MetaMask, Ledger, Coinbase o Wallet Connect para conectarse a su cartera.",
    "No wallet yet?": "¿Aún no tienes cartera?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "No hay problema, tienes la comodidad de crear una cartera personal sin esfuerzo utilizando tus cuentas de redes sociales. Crearemos una cartera sin custodia para ti en segundo plano y podrás exportar tus claves privadas si lo necesitas.",
    "Connect with Coinbase Wallet": "Conectar con Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet es un monedero común y de uso frecuente que permite a los usuarios acceder al ecosistema Web 3 de aplicaciones descentralizadas.",
    "Want to learn more about this wallet?": "¿Quiere saber más sobre esta cartera?",
    "Learn how to install and setup this wallet on": "Aprende a instalar y configurar esta cartera en",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Recientemente hemos añadido soporte para esta cartera. Si tiene algún problema, háganoslo saber.",
    "Connect with Ledger": "Conectar con Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger es una cartera común y de uso frecuente que permite a los usuarios acceder al ecosistema Web 3 de aplicaciones descentralizadas.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Una vez que hagas clic en el botón Conectar, podrás utilizar Ledger Live y Ledger Connect desde tu ordenador o teléfono móvil.",
    "Connect with MetaMask": "Conectar con MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask es una cartera común y de uso frecuente que permite a los usuarios acceder al ecosistema Web 3 de aplicaciones descentralizadas.",
    "Please use your existing MetaMask wallet.": "Por favor, utilice su cartera MetaMask existente.",
    "Connect with your preferred wallet": "Conéctate con tu cartera preferida",
    "With WalletConnect you can connect to almost any existing wallet.": "Con WalletConnect puede conectarse a casi cualquier cartera existente.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Haga clic en WalletConnect y escanee el código QR con la cartera de su elección o seleccione una de la lista de carteras compatibles.",
    "Connect with your Google Account": "Conéctate con tu cuenta de Google",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Si aún no ha obtenido una cartera criptográfico, tiene la opción de utilizar sus cuentas sociales actuales, como Google o Facebook, para crear una cartera.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "No tenemos acceso a sus claves privadas, lo que significa que no podemos gestionar sus fondos en su nombre. Usted, y solo usted, mantiene el control total y la propiedad sobre su cartera y su clave privada asociada.",
    "Connect with Google": "Conectar con Google",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Para establecer una conexión con su cuenta de Google, deberá conceder autorización a una aplicación de terceros de confianza para crear una cuenta de red social que garantice la gestión segura de su cartera no custodiada.",
    "Connect to your Wallet via Social Connect.": "Conéctate a tu Cartera a través de Social Connect.",
    "Connect with your Meta Account": "Conectar con su Meta Cuenta",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect es una forma nueva, sencilla y rápida de crear una cartera. Por lo tanto, Bowline utiliza sus cuentas sociales existentes como Google o Facebook para crear una cartera que se puede utilizar en cualquier aplicación descentralizada (dApp) impulsada por Bowline.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline no tiene acceso a su información privada y financiera, sólo usted tiene pleno control y propiedad sobre su cartera.",
    "Connect with Facebook": "Conectar con Facebook",
    "Connect with Metamask": "Conectar con Metamask",
    "Connect with Wallet Connect": "Conectar con Wallet Connect",
    "Verify your e-mail address": "Verifique su dirección de correo electrónico",
    "The code was sent to your e-mail": "El código se ha enviado a su correo electrónico",
    "Please enter the code": "Introduzca el código",
    "Verify": "Verifique",
    "Resend": "Vuelva a enviar",
    "Discard": "Descartar",
    "Attending": "En",
    "Type": "Tipo",
    "Start": "Inicio",
    "End": "Fin",
    "Details": "Detalles",
    "Signed up": "Inscripción",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Hay nuevos contenidos disponibles. Haga clic aquí para actualizar la página y evitar incoherencias.",
    "Home": "Inicio",
    "Already minted in total": "Ya minted en total",
    "Already minted / wallet": "Ya minted / cartera",
    "Mint for {mintPrice} ETH / each": "Mint por {mintPrice} ETH / cada uno",
    "Paid Transaction": "Transacción pagada",
    "Mint": "Mint",
    "Buy Mint": "Comprar Mint",
    "Select an option to pay for this mint": "Seleccione una opción para pagar esta mint",
    "Pay with Crypto": "Pagar con criptomonedas",
    "We realized that your balance is too low. You need to have at least ": "Nos hemos dado cuenta de que tu saldo es demasiado bajo. Necesita tener al menos ",
    "Pay with Credit Card": "Pagar con tarjeta de crédito",
    "Please wait for the payment to be completed": "Espere a que se complete el pago",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "La transacción ha sido enviada y recibirá una notificación en su cartera bowline",
    "No tokens available for minting": "No hay tokens disponibles para mint",
    "Max tokens minted": "Número máximo de tokens minted",
    "Mint will start soon. Stay tuned!": "Mint empezará pronto. ¡Permanezca atento!",
    "Price/NFT": "Precio/NFT",
    "Amount you already minted": "Cantidad que ya ha minted",
    "Current Price": "Precio actual",
    "e.g. Voucher Code": "p. ej. Código del vale",
    "Sold out!": "¡Agotado!",
    "Please visit the": "Visite el",
    "secondary market.": "mercado secundario.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "Switch to Ethereum network": "Cambiar a la red Ethereum",
    "Switch to Rinkeby network": "Cambiar a la red Rinkeby",
    "Switch to Görli network": "Cambiar a la red Görli",
    "Switch to Sepolia network": "Cambiar a la red Sepolia",
    "Switch to Polygon network": "Cambiar a la red Polygon",
    "Switch to Mumbai network": "Cambiar a la red Mumbai",
    "Switch to Amoy network": "Cambiar a la red Amoy",
    "Preview": "Vista previa",
    "Edit": "Editar",
    "Update": "Actualización",
    "Create": "Cree",
    "Update Page": "Página de actualización",
    "Create Page": "Crear página",
    "Do you want to update this page?": "¿Desea actualizar esta página?",
    "Do you want to create this page?": "¿Quieres crear esta página?",
    "Set Title": "Título del conjunto",
    "Set URL": "Establecer URL",
    "Locale": "Local",
    "Page Deleted": "Página eliminada",
    "Page was deleted successfully!": "¡La página se ha eliminado correctamente!",
    "Continue": "Continúe en",
    "Back": "Volver",
    "Missing title": "Falta el título",
    "Expired": "Caducado",
    "Unlock": "Desbloquear",
    "Unlocking deal": "Acuerdo de desbloqueo",
    "Successfully Unlocked": "Desbloqueado con éxito",
    "You successfully unlocked this deal": "Has desbloqueado correctamente esta oferta",
    "Close": "Cerrar",
    "There was a problem while unlocking this deal.": "Hubo un problema al desbloquear esta oferta.",
    "Please connect your wallet to be able to unlock": "Por favor, conecte su cartera para poder desbloquear",
    "Welcome to {project}": "Bienvenido a {project}",
    "{project} asks for your email address": "{project} le pide su dirección de correo electrónico",
    "{project} requires your email address to continue": "{project} requiere su dirección de correo electrónico para continuar",
    "share email anonymised": "compartir correo electrónico anónimo",
    "(recommended)": "(recomendado)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} sólo recibirá una dirección de correo electrónico anónima, pero bowline mantendrá un registro de su dirección de correo electrónico real.",
    "share email": "compartir correo electrónico",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} obtendrá tu dirección de correo electrónico y la utilizará de acuerdo con su política de protección de datos, y bowline también almacenará tu dirección de correo electrónico para enviarte notificaciones.",
    "don't share email": "no compartas el correo electrónico",
    "Email address": "Dirección de correo electrónico",
    "Your stored email": "Su correo electrónico almacenado",
    "Submit": "Enviar",
    "By submitting you accept the": "Al enviar el formulario, acepta la",
    "terms of service": "condiciones del servicio",
    "terms of service for {projectTitle}": "condiciones de servicio para {projectTitle}",
    "data protection policy": "política de protección de datos",
    "data protection policy for {projectTitle}": "política de protección de datos para {projectTitle}",
    "of {projectTitle}.": "de {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para más información sobre el tratamiento de sus datos personales a través de bowline, consulte esta <a href=‘https://www.bowline.app/data-protection/' target=‘_blank’>Política de privacidad</a>.",
    "Position": "Posición",
    "Your Wallet": "Su cartera",
    "here": "aquí",
    "Share": "Compartir",
    "Bid for this Product": "Pujar por este producto",
    "you must bid at least": "debe pujar al menos",
    "Enter bid": "Introducir oferta",
    "Bid can't be higher than current balance": "La oferta no puede ser superior al saldo actual",
    "Warning: Your bid is lower than the current highest bid": "Advertencia: Su oferta es inferior a la oferta más alta actual",
    "Your balance": "Su saldo",
    "Place your Bid": "Presente su Oferta",
    "You cannot place a bid as you have insufficient funds.": "No puede pujar porque no tiene fondos suficientes.",
    "Please follow the confirmation steps in your wallet.": "Siga los pasos de confirmación en su cartera.",
    "Your bid is stored in our database.": "Su oferta se almacena en nuestra base de datos.",
    "You have successfully placed your bid!": "¡Has pujado con éxito!",
    "We will notify you if you are outbid, or if you are the bid winner!": "¡Te avisaremos si superas tu oferta o si eres el ganador!",
    " view more": " ver más",
    "This is a cached value, the price should soon be loaded from blockchain.": "Se trata de un valor en caché, el precio debería cargarse pronto desde blockchain.",
    "Purchase": "Comprar",
    "Your item purchase was successful": "Su compra se ha realizado correctamente",
    "Okay": "Okay",
    "Order Summary": "Resumen del pedido",
    "Amount: ": "Importe: ",
    "Total": "Total",
    "Close Modal": "Cerrar Modal",
    "Transaction in Progress": "Transacción en curso",
    "Please sign allowance": "Por favor, firme el permiso",
    "please approve the interaction": "apruebe la interacción",
    "Checking your balance": "Consultar el saldo",
    "checking if your wallet has enough funds to buy this product": "comprobar si su cartera tiene fondos suficientes para comprar este producto",
    "Please sign transaction": "Por favor, firme la transacción",
    "Metamask should open and ask you to sign the transaction": "Metamask debería abrirse y pedirte que firmes la transacción",
    "Wait for transaction to be mined": "Esperar a que se minen las transacciones",
    "Depending on network traffic this could take a few minutes": "Dependiendo del tráfico de la red, esto puede tardar unos minutos",
    "Something went wrong": "Algo salió mal",
    "We could not purchase the product!": "¡No pudimos comprar el producto!",
    "Biling details": "Datos de facturación",
    "First name": "Nombre de pila",
    "This field is required": "Este campo es obligatorio",
    "Maximum length is 150": "La longitud máxima es de 150",
    "Last name": "Apellido",
    "Company name": "Nombre de la empresa",
    "Address": "Dirección",
    "Minimum length is 5": "La longitud mínima es de 5",
    "Maximum length is 250": "La longitud máxima es de 250",
    "Postal Code": "Código postal",
    "City": "Ciudad",
    "Country": "País",
    "Summary": "Resumen",
    "Products": "Productos",
    "Price": "Precio",
    "Continue Purchase": "Seguir comprando",
    "Please Sign in with Wallet first before purchasing a product!": "¡Inicie sesión con su cartera antes de comprar un producto!",
    "Please connect your wallet first before being able to purchase products": "Conecte primero su cartera antes de poder comprar productos",
    "Click Purchase to complete your checkout.": "Haga clic en Comprar para completar el pago.",
    "Sign in with Wallet": "Iniciar sesión con cartera",
    "Stake All selected": "Estaca Todos seleccionados",
    "Unstake All selected": "Deshacer Todo seleccionado",
    "staked": "estacado",
    "Unstake": "Unstake",
    "Stake": "Estaca",
    "unclaimed earnings": "ganancias no reclamadas",
    "Your retrieved earnings, that are ready to claim!": "Sus ganancias recuperadas, ¡listas para reclamar!",
    "pending rewards": "recompensas pendientes",
    "Tokens in the Hole": "Tokens en el agujero",
    "Your total Tokens staked": "Total de Tokens apostados",
    "Available Balance": "Saldo disponible",
    "Your lifetime earnings from staking.": "Sus ganancias de por vida por las apuestas.",
    "Claim": "Reclame",
    "Authorization required": "Se requiere autorización",
    "The provided voucher code is not valid": "El código del vale proporcionado no es válido",
    "The provided voucher code got already used. Each voucher code can be used only once.": "El código de vale proporcionado ya se ha utilizado. Cada código de vale solo se puede utilizar una vez.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Tu transacción ya ha sido enviada a la blockchain. Parece que ha intentado mint el mismo token de nuevo. Por favor, tenga paciencia.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Si has intentado mint más de un token, puede que te haya pillado nuestra protección contra bots. Revisaremos manualmente tu transacción y te enviaremos el token más tarde.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "No hemos podido enviar tu token en este momento porque hay demasiado tráfico en la blockchain en este momento. Sin embargo, tu airdrop se ha añadido correctamente a la cola y recibirás tu token una vez que la transacción se haya escrito en la blockchain. Esto puede tardar hasta unas horas dependiendo de la longitud de la cola y el gas.",
    "Attention:": "En:",
    "We can't guarantee the exact order of the transactions.": "No podemos garantizar el orden exacto de las transacciones.",
    "An Error occured!": "¡Se ha producido un error!",
    "show full error message": "mostrar mensaje de error completo",
    "Processing": "Tratamiento",
    "Success!": "¡Éxito!",
    "a content category": "una categoría de contenido",
    "an unlockable content": "un contenido desbloqueable",
    "a whitelist entry": "una entrada en la lista blanca",
    "a event": "un evento",
    "a token": "una token",
    "created": "creado",
    "updated": "actualizado",
    "minted": "minted",
    "transfered": "transferido",
    "You successfully {action} {resourceType}": "Has conseguido {action} {resourceType}",
    "ok": "ok",
    "click to get back to overview screen": "pulse para volver a la pantalla general",
    "Missing Name": "Falta el nombre",
    "Select": "Seleccione",
    "Selected": "Selección",
    "This token has already been used": "Este token ya ha sido utilizado",
    "This token is already selected elsewhere": "Este token ya está seleccionado en otro sitio",
    "Auto Selected": "Auto Seleccionado",
    "Transfer Token": "Transferir Token",
    "Receiver": "Receptor",
    "Not a valid address!": "¡No es una dirección válida!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Va a transferir un nft a {address}. Esta operación no se puede deshacer",
    "Transfer this NFT": "Transfiera este NFT",
    "Purchase in Progress": "Compra en curso",
    "Signing with your wallet": "Firmar con su cartera",
    "Please follow your wallet instructions to confirm the transaction.": "Siga las instrucciones de su cartera para confirmar la transacción.",
    "You confirmed the transaction with your wallet.": "Ha confirmado la transacción con su cartera.",
    "Transaction is sent to the blockchain": "La transacción se envía a la blockchain",
    "After signing the transaction it is send to the blockchain.": "Una vez firmada, la transacción se envía a la blockchain.",
    "Transaction is being processed": "La transacción se está procesando",
    "Please check your wallets transaction history for your actual transaction status!": "¡Consulte el historial de transacciones de su cartera para conocer el estado real de su transacción!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Puede cerrar este modal en cualquier momento - no afecta a su transacción. Cerraremos el modal por ti una vez que veamos que la transacción está minada.",
    "Step": "Paso",
    "of": "de",
    "You have unlocked this successfully": "Has desbloqueado esto con éxito",
    "Select your Tokens for Voting": "Seleccione sus Tokens para votar",
    "Select your Token": "Seleccione su Token",
    "You can unlock this unlimited times.": "Puedes desbloquearlo un número ilimitado de veces.",
    "You can unlock this once.": "Puedes desbloquearlo una vez.",
    "You can unlock this from {date}.": "Puedes desbloquearlo a partir de {date}.",
    "You can unlock this until {date}.": "Puedes desbloquearlo hasta {date}.",
    "If you sell your token you will loose access": "Si vendes tus tokens perderás el acceso",
    "Sorry you dont have any matching Token for unlocking": "Lo sentimos, no tienes ningún Token para desbloquear",
    "Please enter to unlock": "Introduzca para desbloquear",
    "Entered value does not match email format": "El valor introducido no coincide con el formato del correo electrónico",
    "Unlocking": "Desbloqueo",
    "Sign the message in your wallet to submit the transaction. Once submitted, We will wait until the transaction is finalized and your unlockable content is available.": "Firme el mensaje en su cartera para enviar la transacción. Una vez enviada, esperaremos a que finalice la transacción y tu contenido desbloqueable esté disponible.",
    "Next": "Siguiente",
    "Sign Up": "Inscribirse",
    "Vote": "Vote",
    "And": "Y",
    "Or": "O",
    "Select {n} token from these": "Seleccione {n} tokens de estos",
    "Conditions met": "Condiciones cumplidas",
    "Select tokens below you want to use": "Seleccione los tokens que desea utilizar a continuación",
    "Email": "Correo electrónico",
    "Change password": "Cambiar contraseña",
    "Insert your current password and the new password": "Introduzca su contraseña actual y la nueva contraseña",
    "Current Password": "Contraseña actual",
    "Password": "Contraseña",
    "Save": "Guardar",
    "tokens": "tokens",
    "community event": "evento comunitario",
    "partner event": "evento para socios",
    "conference": "conferencia",
    "irl meetup": "irl meetup",
    "irl party": "fiesta irl",
    "virtual meetup": "encuentro virtual",
    "discord event": "evento de discord",
    "ask me anything": "pregúntame lo que quieras",
    "There are no votings options available": "No hay opciones de votación disponibles",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Conecte su cartera para poder votar",
    "Your Vote has been sent successfully": "Su Voto se ha enviado correctamente",
    "Voting": "Votar",
    "Successfully Voted": "Votado con éxito",
    "There was a problem while sending your vote. Please try again.": "Se ha producido un problema al enviar tu voto. Por favor, inténtelo de nuevo.",
    "Reveal": "Revelar",
    "get more Info": "más información",
    "This vote has ended. Thanks!": "Esta votación ha terminado. ¡Gracias!",
    "Wallet Changed": "Cartera cambiada",
    "It looks like you changed your wallet, would you like to connect your wallet?": "Parece que has cambiado de cartera, ¿quieres conectar tu cartera?",
    "Export Wallet": "Cartera de exportación",
    "(A QR code will be generated for {n} seconds)": "(Se generará un código QR durante {n} segundos)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Escanee el código QR con la cámara de su teléfono o con una aplicación de cartera (por ejemplo, Metamask) para exportar su cartera o haga clic en el botón de abajo para copiar la clave.",
    "Copy Private Key": "Copiar Clave Privada",
    "please complete your profile": "complete su perfil",
    "* means required": "* significa necesario",
    "Please connect to your wallet first.": "Conéctese primero a su cartera.",
    "Contract Address": "Dirección del contrato",
    "Add my Wallet to this Allowlist": "Añadir mi Cartera a esta Lista de permisos",
    "Congratulations!": "¡Felicidades!",
    "You've been added to the Allowlist!": "¡Has sido añadido a la lista de permitidos!",
    "Do you want to receive notifications on your email about this listing?": "¿Desea recibir notificaciones sobre este anuncio en su correo electrónico?",
    "Wallet Address": "Dirección de la cartera",
    "You are already part of this allowlist!": "¡Usted ya forma parte de esta lista permitida!"
  },
  "fr": {
    "Powered by": "Propulsé par",
    "Wallet": "Portefeuille",
    "New alerts": "Nouvelles alertes",
    "Not signed in with Wallet": "Non connecté avec Wallet",
    "Connected to wrong network": "Connexion à un mauvais réseau",
    "Account": "Compte",
    "Balance": "Solde",
    "Signing Request": "Demande de signature",
    "Cancel": "Annuler",
    "Sign": "Signer",
    "You are not signed in with your Wallet yet": "Vous n’êtes pas encore connecté avec votre Wallet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "Il vous est demandé de signer un message afin de générer une connexion sécurisée pour votre wallet. Nous n’aurons pas accès à vos fonds, ni à vos NFT, et nous n’effectuerons aucune action avec ces informations.",
    "You are signed in with your Wallet": "Vous êtes connecté avec votre Wallet",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Vous êtes actuellement connecté à un réseau différent de celui sur lequel ce contrat est déployé. Veuillez remplacer votre réseau par {networkName} dans votre wallet.",
    "Switch to {networkName} network": "Passer au réseau {networkName}",
    "Copy address": "Copier l'adresse",
    "Tokens": "Jetons",
    "Settings": "Paramètres",
    "Activity": "Activité",
    "Please \"Sign in with Wallet\" first.": "Veuillez d’abord vous connecter avec Wallet.",
    "See above": "Voir ci-dessus",
    "Change E-mail address": "Changement d’adresse électronique",
    "Change": "Changer",
    "Delete Account": "Supprimer le compte",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Pour supprimer votre compte, veuillez nous contacter : <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Pour plus d’informations sur le traitement de vos données personnelles, veuillez consulter notre <a href=‘https://www.bowline.app/data-protection/' target=‘_blank’>Politique de confidentialité</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href=‘https://www.bowline.app/tos/' target=‘_blank’>Termes de service</a>.",
    "No Activity yet in this session": "Pas d’activité pour l’instant dans cette session",
    "Recent Signatures": "Signatures récentes",
    "Transactions": "Transactions",
    "Disconnect": "Déconnexion",
    "Your Tokens related to this project": "Vos Tokens liés à ce projet",
    "Reload Tokens": "Tokens de recharge",
    "You do not have any Token in Wallet for this project": "Vous n’avez pas de Token dans le Wallet pour ce projet",
    "Reload": "Recharge",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "Votre Wallet peut contenir d’autres Tokens provenant d’autres dApps. Veuillez visiter les sites web des projets pour les voir ou vérifier votre wallet sur par exemple",
    "or": "ou",
    "Connect to Wallet": "Connexion au Wallet",
    "close": "fermer",
    "Select a Wallet": "Sélectionner un Wallet",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "En connectant votre Wallet, vous acceptez nos <a href=‘https://www.bowline.app/tos/' target=‘_blank’>Termes de service</a>.",
    "How to connect?": "Comment se connecter ?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Pour vous connecter et interagir en toute sécurité avec ce service, vous devez disposer d’un wallet prenant en charge le protocole Web3. Veuillez sélectionner l’une des options suivantes pour vous connecter à votre wallet.",
    "I do have a wallet.": "J’ai un Wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "Si vous disposez déjà d’un Wallet, veuillez sélectionner MetaMask, Ledger, Coinbase ou Wallet Connect pour vous connecter à votre Wallet.",
    "No wallet yet?": "Pas encore de Wallet ?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "Pas de problème, vous avez la possibilité de créer un Wallet personnel sans effort en utilisant vos comptes de réseaux sociaux. Nous créerons un Wallet non privatif pour vous en arrière-plan et vous pourrez exporter vos clés privées si nécessaire.",
    "Connect with Coinbase Wallet": "Se connecter avec Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Coinbase Wallet est un Wallet commun et souvent utilisé qui permet aux utilisateurs d’accéder à l’écosystème d’applications décentralisées Web 3.",
    "Want to learn more about this wallet?": "Vous voulez en savoir plus sur ce wallet ?",
    "Learn how to install and setup this wallet on": "Découvrez comment installer et paramétrer ce Wallet sur",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Nous avons récemment ajouté la prise en charge de ce wallet. Si vous rencontrez un problème, veuillez nous en informer.",
    "Connect with Ledger": "Se connecter à Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "Ledger est un Wallet commun et souvent utilisé qui permet aux utilisateurs d’accéder à l’écosystème d’applications décentralisées du Web 3.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Une fois que vous avez cliqué sur le bouton de connexion, vous pouvez utiliser Ledger Live et Ledger Connect à partir de votre ordinateur ou de votre téléphone portable.",
    "Connect with MetaMask": "Se connecter à MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "MetaMask est un wallet courant et souvent utilisé qui permet aux utilisateurs d’accéder à l’écosystème d’applications décentralisées Web 3.",
    "Please use your existing MetaMask wallet.": "Veuillez utiliser votre wallet MetaMask existant.",
    "Connect with your preferred wallet": "Connectez-vous avec votre Wallet préféré",
    "With WalletConnect you can connect to almost any existing wallet.": "Avec WalletConnect, vous pouvez vous connecter à presque tous les portefeuilles existants.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Cliquez sur WalletConnect et scannez le code QR avec le Wallet de votre choix ou sélectionnez-le dans la liste des Wallets compatibles.",
    "Connect with your Google Account": "Connexion avec votre compte Google",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Si vous n’avez pas encore obtenu de Wallet de crypto-monnaie, vous avez la possibilité d’utiliser vos comptes sociaux actuels tels que Google ou Facebook pour créer un Wallet.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "Nous n’avons pas accès à vos clés privées, ce qui signifie que nous ne pouvons pas gérer vos fonds en votre nom. Vous, et vous seul, conservez le contrôle total et la propriété de votre wallet et de sa clé privée associée.",
    "Connect with Google": "Se connecter à Google",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Pour établir une connexion avec votre compte Google, vous devrez accorder l’autorisation à une application tierce de confiance de créer un compte de réseau social qui assure la gestion sécurisée de votre wallet non gardien.",
    "Connect to your Wallet via Social Connect.": "Connectez-vous à votre Wallet par l’intermédiaire de Social Connect.",
    "Connect with your Meta Account": "Connexion avec votre compte Meta",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "Social Connect est un nouveau moyen simple et rapide de créer un Wallet. Par conséquent, Bowline utilise vos comptes sociaux existants comme Google ou Facebook pour créer un Wallet qui peut être utilisé sur n’importe quelle application décentralisée (dApp) alimentée par Bowline.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "Bowline n’a pas accès à vos informations privées et financières, vous seul avez le plein contrôle et la propriété de votre Wallet.",
    "Connect with Facebook": "Se connecter à Facebook",
    "Connect with Metamask": "Se connecter à Metamask",
    "Connect with Wallet Connect": "Se connecter à Wallet Connect",
    "Verify your e-mail address": "Vérifiez votre adresse e-mail",
    "The code was sent to your e-mail": "Le code a été envoyé à votre e-mail",
    "Please enter the code": "Veuillez saisir le code",
    "Verify": "Vérifier",
    "Resend": "Renvoyer",
    "Discard": "Rejeter",
    "Attending": "Présence",
    "Type": "Type",
    "Start": "Démarrage",
    "End": "Fin",
    "Details": "Détails",
    "Signed up": "S’inscrire",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Un nouveau contenu est disponible. Veuillez cliquer ici pour actualiser la page et éviter les incohérences.",
    "Home": "Accueil",
    "Already minted in total": "Déjà minet au total",
    "Already minted / wallet": "Déjà minted / Wallet",
    "Mint for {mintPrice} ETH / each": "Mint pour {mintPrice} ETH / pièce",
    "Paid Transaction": "Transaction payée",
    "Mint": "Mint",
    "Buy Mint": "Acheter de la Mint",
    "Select an option to pay for this mint": "Sélectionnez une option pour payer cette mini",
    "Pay with Crypto": "Payer avec des crypto-monnaies",
    "We realized that your balance is too low. You need to have at least ": "Nous avons constaté que votre solde est trop faible. Vous devez avoir au moins ",
    "Pay with Credit Card": "Payer par carte de crédit",
    "Please wait for the payment to be completed": "Veuillez attendre que le paiement soit effectué",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "La transaction a été envoyée et vous recevrez une notification dans votre wallet Bowline",
    "No tokens available for minting": "Pas de Tokens disponibles pour la minting",
    "Max tokens minted": "Nombre maximal de Tokens minet",
    "Mint will start soon. Stay tuned!": "L’opération Mint débutera bientôt. Restez à l’écoute !",
    "Price/NFT": "Prix/NFT",
    "Amount you already minted": "Montant déjà minted",
    "Current Price": "Prix actuel",
    "e.g. Voucher Code": "ex. code de bon d’achat",
    "Sold out!": "Vendu !",
    "Please visit the": "Veuillez consulter le site",
    "secondary market.": "marché secondaire.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "Switch to Ethereum network": "Passer au réseau Ethereum",
    "Switch to Rinkeby network": "Passer au réseau Rinkeby",
    "Switch to Görli network": "Passer au réseau Görli",
    "Switch to Sepolia network": "Passer au réseau Sepolia",
    "Switch to Polygon network": "Passer au réseau Polygon",
    "Switch to Mumbai network": "Passer au réseau Mumbai",
    "Switch to Amoy network": "Passer au réseau Amoy",
    "Preview": "Avant-première",
    "Edit": "Editer",
    "Update": "Mise à jour",
    "Create": "Créer",
    "Update Page": "Page de mise à jour",
    "Create Page": "Créer une page",
    "Do you want to update this page?": "Voulez-vous mettre à jour cette page ?",
    "Do you want to create this page?": "Voulez-vous créer cette page ?",
    "Set Title": "Titre de l’ensemble",
    "Set URL": "Définir l’URL",
    "Locale": "Localité",
    "Page Deleted": "Page supprimée",
    "Page was deleted successfully!": "La page a été supprimée avec succès !",
    "Continue": "Continuer",
    "Back": "Retour",
    "Missing title": "Titre manquant",
    "Expired": "Expiré",
    "Unlock": "Déverrouiller",
    "Unlocking deal": "Déblocage de l’accord",
    "Successfully Unlocked": "Débloqué avec succès",
    "You successfully unlocked this deal": "Vous avez débloqué cette offre avec succès",
    "Close": "Fermer",
    "There was a problem while unlocking this deal.": "Il y a eu un problème lors du déblocage de cette offre.",
    "Please connect your wallet to be able to unlock": "Veuillez connecter votre wallet pour pouvoir le déverrouiller",
    "Welcome to {project}": "Bienvenue à {project}",
    "{project} asks for your email address": "{project} vous demande votre adresse électronique",
    "{project} requires your email address to continue": "{project} requiert votre adresse électronique pour continuer",
    "share email anonymised": "partager l’email de manière anonyme",
    "(recommended)": "(recommandé)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} ne recevra qu’une adresse électronique anonyme, mais bowline gardera la trace de votre véritable adresse électronique.",
    "share email": "partager l’email",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} recevra votre adresse électronique et l’utilisera conformément à sa politique de protection des données et bowline conservera également votre adresse électronique pour vous envoyer des notifications.",
    "don't share email": "ne pas partager l’email",
    "Email address": "Adresse électronique",
    "Your stored email": "Votre email stocké",
    "Submit": "Soumettre",
    "By submitting you accept the": "En soumettant votre demande, vous acceptez les",
    "terms of service": "conditions de service",
    "terms of service for {projectTitle}": "conditions de service pour {projectTitle}",
    "data protection policy": "politique de protection des données",
    "data protection policy for {projectTitle}": "politique de protection des données pour {projectTitle}",
    "of {projectTitle}.": "de {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Pour plus d’informations sur le traitement de vos données personnelles par Bowline, veuillez consulter cette <a href=‘https://www.bowline.app/data-protection/' target=‘_blank’>Politique de confidentialité</a>.",
    "Position": "Position",
    "Your Wallet": "Votre Wallet",
    "here": "ici",
    "Share": "Partager",
    "Bid for this Product": "Offre pour ce produit",
    "you must bid at least": "vous devez faire une offre d’au moins",
    "Enter bid": "Saisir l’offre",
    "Bid can't be higher than current balance": "L’offre ne peut être supérieure au solde actuel",
    "Warning: Your bid is lower than the current highest bid": "Avertissement : Votre offre est inférieure à l’offre la plus élevée du moment",
    "Your balance": "Votre équilibre",
    "Place your Bid": "Placez votre offre",
    "You cannot place a bid as you have insufficient funds.": "Vous ne pouvez pas placer d’offre car vous n’avez pas assez de fonds.",
    "Please follow the confirmation steps in your wallet.": "Veuillez suivre les étapes de confirmation dans votre wallet.",
    "Your bid is stored in our database.": "Votre offre est stockée dans notre base de données.",
    "You have successfully placed your bid!": "Vous avez placé votre offre avec succès !",
    "We will notify you if you are outbid, or if you are the bid winner!": "Nous vous informerons si vous êtes surenchéri ou si vous êtes le gagnant de l’enchère !",
    " view more": " voir plus",
    "This is a cached value, the price should soon be loaded from blockchain.": "Il s’agit d’une valeur mise en cache, le prix devrait bientôt être chargé à partir de la blockchain.",
    "Purchase": "Achat",
    "Your item purchase was successful": "L’achat de votre article a été effectué avec succès",
    "Okay": "Okay",
    "Order Summary": "Résumé de la commande",
    "Amount: ": "Montant : ",
    "Total": "Total",
    "Close Modal": "Fermer la fenêtre modale",
    "Transaction in Progress": "Transaction en cours",
    "Please sign allowance": "Veuillez signer l’allocation",
    "please approve the interaction": "veuillez approuver l’interaction",
    "Checking your balance": "Vérification du solde",
    "checking if your wallet has enough funds to buy this product": "vérifier si votre portefeuille a Wallet de fonds pour acheter ce produit",
    "Please sign transaction": "Veuillez signer la transaction",
    "Metamask should open and ask you to sign the transaction": "Metamask devrait s’ouvrir et vous demander de signer la transaction",
    "Wait for transaction to be mined": "Attendre que la transaction soit mine",
    "Depending on network traffic this could take a few minutes": "En fonction du trafic sur le réseau, cette opération peut prendre quelques minutes",
    "Something went wrong": "Quelque chose n’a pas fonctionné",
    "We could not purchase the product!": "Nous n’avons pas pu acheter le produit !",
    "Biling details": "Détails de l’étiquetage",
    "First name": "Prénom",
    "This field is required": "Ce champ est obligatoire",
    "Maximum length is 150": "La longueur maximale est de 150",
    "Last name": "Nom de famille",
    "Company name": "Nom de l’entreprise",
    "Address": "Adresse",
    "Minimum length is 5": "La longueur minimale est de 5",
    "Maximum length is 250": "La longueur maximale est de 250",
    "Postal Code": "Code postal",
    "City": "Ville",
    "Country": "Pays",
    "Summary": "Résumé",
    "Products": "Produits",
    "Price": "Prix",
    "Continue Purchase": "Poursuivre l’achat",
    "Please Sign in with Wallet first before purchasing a product!": "Veuillez d’abord vous connecter avec Wallet avant d’acheter un produit !",
    "Please connect your wallet first before being able to purchase products": "Veuillez d’abord connecter votre wallet avant de pouvoir acheter des produits",
    "Click Purchase to complete your checkout.": "Cliquez sur Acheter pour terminer votre commande.",
    "Sign in with Wallet": "Se connecter avec Wallet",
    "Stake All selected": "Enjeu Tous sélectionnés",
    "Unstake All selected": "Déstocker Tout sélectionné",
    "staked": "jalonné",
    "Unstake": "Déstockage",
    "Stake": "Enjeu",
    "unclaimed earnings": "revenus non réclamés",
    "Your retrieved earnings, that are ready to claim!": "Vos revenus récupérés, prêts à être réclamés !",
    "pending rewards": "récompenses en attente",
    "Tokens in the Hole": "Tokens dans le trou",
    "Your total Tokens staked": "Votre total de Tokens misés",
    "Available Balance": "Solde disponible",
    "Your lifetime earnings from staking.": "Les gains que vous avez réalisés au cours de votre vie grâce au jalonnement.",
    "Claim": "Réclamation",
    "Authorization required": "Autorisation requise",
    "The provided voucher code is not valid": "Le code de réduction fourni n’est pas valide",
    "The provided voucher code got already used. Each voucher code can be used only once.": "Le code de réduction fourni a déjà été utilisé. Chaque code de bon ne peut être utilisé qu’une seule fois.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "Votre transaction a déjà été envoyée à la blockchain. Il semble que vous ayez essayé de frapper à nouveau le même Token. Veuillez patienter.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Si vous avez essayé de monnayer plus d’un Token, il se peut que vous ayez été pris en flagrant délit par notre protection contre les robots. Nous examinerons manuellement votre transaction et vous enverrons le token plus tard.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "Nous n’avons pas pu airdrop votre toke en ce moment parce qu’il y a trop de trafic sur la blockchain en ce moment. Cependant, votre airdrop a été ajouté avec succès à la file d’attente, et vous recevrez votre Token une fois que la transaction aura été écrite sur la blockchain. Cela peut prendre jusqu’à quelques heures en fonction de la longueur de la file d’attente et du gaz.",
    "Attention:": "Attention :",
    "We can't guarantee the exact order of the transactions.": "Nous ne pouvons pas garantir l’ordre exact des opérations.",
    "An Error occured!": "Une erreur s’est produite !",
    "show full error message": "afficher le message d’erreur complet",
    "Processing": "Traitement",
    "Success!": "Succès !",
    "a content category": "une catégorie de contenu",
    "an unlockable content": "un contenu à débloquer",
    "a whitelist entry": "une entrée dans la liste blanche",
    "a event": "un événement",
    "a token": "un token",
    "created": "créé",
    "updated": "mis à jour",
    "minted": "minted",
    "transfered": "transféré",
    "You successfully {action} {resourceType}": "Vous avez réussi {action} {resourceType}",
    "ok": "ok",
    "click to get back to overview screen": "cliquer pour revenir à l’écran de synthèse",
    "Missing Name": "Nom manquant",
    "Select": "Sélectionner",
    "Selected": "Sélectionné",
    "This token has already been used": "Ce Token a déjà été utilisé",
    "This token is already selected elsewhere": "Ce Token est déjà sélectionné ailleurs",
    "Auto Selected": "Sélection automatique",
    "Transfer Token": "Token de transfert",
    "Receiver": "Récepteur",
    "Not a valid address!": "Ce n’est pas une adresse valide !",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Vous allez transférer un NFT à {address}. Cette opération ne peut être annulée",
    "Transfer this NFT": "Transférer ce NFT",
    "Purchase in Progress": "Achats en cours",
    "Signing with your wallet": "Signer avec son Wallet",
    "Please follow your wallet instructions to confirm the transaction.": "Veuillez suivre les instructions de votre wallet pour confirmer la transaction.",
    "You confirmed the transaction with your wallet.": "Vous avez confirmé la transaction avec votre wallet.",
    "Transaction is sent to the blockchain": "La transaction est envoyée à la blockchain",
    "After signing the transaction it is send to the blockchain.": "Une fois la transaction signée, elle est envoyée à la blockchain.",
    "Transaction is being processed": "La transaction est en cours de traitement",
    "Please check your wallets transaction history for your actual transaction status!": "Veuillez consulter l’historique des transactions de votre wallet pour connaître l’état réel de votre transaction !",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Vous pouvez fermer cette fenêtre à tout moment - cela n’affecte pas votre transaction. Nous fermerons la fenêtre modale pour vous une fois que nous aurons constaté que la transaction est minée.",
    "Step": "Étape",
    "of": "de",
    "You have unlocked this successfully": "Vous l’avez débloqué avec succès",
    "Select your Tokens for Voting": "Sélectionnez vos Tokens pour le vote",
    "Select your Token": "Sélectionnez votre Token",
    "You can unlock this unlimited times.": "Vous pouvez le débloquer un nombre illimité de fois.",
    "You can unlock this once.": "Vous ne pouvez le débloquer qu’une seule fois.",
    "You can unlock this from {date}.": "Vous pouvez le débloquer à partir de {date}.",
    "You can unlock this until {date}.": "Vous pouvez le déverrouiller jusqu’au {date}.",
    "If you sell your token you will loose access": "Si vous vendez votre token, vous perdrez l’accès à celui-ci",
    "Sorry you dont have any matching Token for unlocking": "Désolé, vous n’avez pas de Token correspondant pour le déblocage",
    "Please enter to unlock": "Veuillez entrer pour déverrouiller",
    "Entered value does not match email format": "La valeur saisie ne correspond pas au format de l’email",
    "Unlocking": "Déverrouillage",
    "Sign the message in your wallet to submit the transaction. Once submitted, We will wait until the transaction is finalized and your unlockable content is available.": "Signez le message dans votre Wallet pour soumettre la transaction. Une fois la transaction envoyée, nous attendrons qu’elle soit finalisée et que votre contenu déverrouillable soit disponible.",
    "Next": "Suivant",
    "Sign Up": "S’inscrire",
    "Vote": "Vote",
    "And": "Et",
    "Or": "Ou",
    "Select {n} token from these": "Sélectionnez {n} Token parmi les suivants",
    "Conditions met": "Conditions remplies",
    "Select tokens below you want to use": "Sélectionnez les Tokens ci-dessous que vous souhaitez utiliser",
    "Email": "Courriel",
    "Change password": "Modifier le mot de passe",
    "Insert your current password and the new password": "Insérez votre mot de passe actuel et le nouveau mot de passe",
    "Current Password": "Mot de passe actuel",
    "Password": "Mot de passe",
    "Save": "Sauvegarde",
    "tokens": "tokens",
    "community event": "événement communautaire",
    "partner event": "événement partenaire",
    "conference": "conférence",
    "irl meetup": "irl meetup",
    "irl party": "soirée irl",
    "virtual meetup": "rencontre virtuelle",
    "discord event": "événement Discord",
    "ask me anything": "demandez-moi n’importe quoi",
    "There are no votings options available": "Il n’y a pas d’options de vote disponibles",
    "vote": "vote",
    "Please connect your wallet to be able to vote": "Veuillez connecter votre wallet pour pouvoir voter",
    "Your Vote has been sent successfully": "Votre vote a été envoyé avec succès",
    "Voting": "Vote",
    "Successfully Voted": "Voté avec succès",
    "There was a problem while sending your vote. Please try again.": "Il y a eu un problème lors de l’envoi de votre vote. Veuillez réessayer.",
    "Reveal": "Révéler",
    "get more Info": "plus d’informations",
    "This vote has ended. Thanks!": "Ce vote est terminé. Nous vous remercions de votre attention !",
    "Wallet Changed": "Wallet modifié",
    "It looks like you changed your wallet, would you like to connect your wallet?": "Il semble que vous ayez changé de Wallet, voulez-vous connecter votre Wallet ?",
    "Export Wallet": "Export Wallet",
    "(A QR code will be generated for {n} seconds)": "(Un code QR sera généré pendant {n} secondes)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Veuillez scanner le code QR à l’aide de l’appareil photo de votre téléphone ou de l’application Wallet (par exemple Metamask) pour exporter votre wallet ou cliquez sur le bouton ci-dessous pour copier la clé.",
    "Copy Private Key": "Copier la clé privée",
    "please complete your profile": "veuillez compléter votre profil",
    "* means required": "* signifie nécessaire",
    "Please connect to your wallet first.": "Veuillez d’abord vous connecter à votre wallet.",
    "Contract Address": "Adresse du contrat",
    "Add my Wallet to this Allowlist": "Ajouter mon Wallet à cette liste d’attente",
    "Congratulations!": "Félicitations !",
    "You've been added to the Allowlist!": "Vous avez été ajouté à la liste d’admissibilité !",
    "Do you want to receive notifications on your email about this listing?": "Voulez-vous recevoir des notifications sur votre email à propos de cette annonce ?",
    "Wallet Address": "Adresse du Wallet",
    "You are already part of this allowlist!": "Vous faites déjà partie de cette liste !"
  },
  "it": {
    "Powered by": "",
    "Connect": "",
    "Wallet": "Wallet",
    "New alerts": "Nuovi avvisi",
    "Not signed in with Wallet": "Non connesso con Wallet",
    "Account": "Account",
    "Balance": "Saldo",
    "Signing Request": "Richiesta di firma",
    "Cancel": "Annulla",
    "Sign": "Firma",
    "You are not signed in with your Wallet yet": "Non hai ancora effettuato l'accesso con Wallet",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "",
    "You are signed in with your Wallet": "Hai effettuato l'accesso con Wallet",
    "Copy address": "Copia indirizzo",
    "Tokens": "Token",
    "Settings": "Impostazioni",
    "Activity": "Attività",
    "Please \"Sign in with Wallet\" first.": "Effettua prima l'accesso con Wallet.",
    "See above": "Vedi sopra",
    "Change E-mail address": "",
    "Change": "",
    "Delete Account": "Elimina account",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Per eliminare il tuo account, contattaci: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "No Activity yet in this session": "",
    "Recent Signatures": "",
    "Transactions": "",
    "Disconnect": "",
    "Your Tokens related to this project": "",
    "Reload Tokens": "",
    "You do not have any Token in Wallet for this project": "",
    "Reload": "",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "",
    "or": "",
    "Connect to Wallet": "",
    "close": "",
    "Select a Wallet": "",
    "Metamask": "",
    "Ledger": "",
    "Coinbase Wallet": "",
    "Wallet Connect": "",
    "Social Connect": "",
    "Google": "",
    "Facebook": "",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "",
    "How to connect?": "",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "",
    "I do have a wallet.": "",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "",
    "No wallet yet?": "",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "",
    "Connect with Coinbase Wallet": "",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Want to learn more about this wallet?": "",
    "Learn how to install and setup this wallet on": "",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "",
    "Connect with Ledger": "",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "",
    "Connect with MetaMask": "",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "",
    "Please use your existing MetaMask wallet.": "",
    "Connect with your preferred wallet": "",
    "With WalletConnect you can connect to almost any existing wallet.": "",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "",
    "Connect with your Google Account": "",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "",
    "Connect with Google": "",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "",
    "Connect to your Wallet via Social Connect.": "",
    "Connect with your Meta Account": "",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "",
    "Connect with Facebook": "",
    "Connect with Metamask": "",
    "Connect with Wallet Connect": "",
    "Verify your e-mail address": "",
    "The code was sent to your e-mail": "",
    "Please enter the code": "",
    "Verify": "",
    "Resend": "",
    "Discard": "",
    "Attending": "",
    "Type": "",
    "Start": "",
    "End": "",
    "Details": "",
    "Signed up": "",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "",
    "Home": "",
    "Already minted in total": "",
    "Already minted / wallet": "",
    "Mint for {mintPrice} ETH / each": "",
    "Paid Transaction": "",
    "Mint": "",
    "Buy Mint": "",
    "Select an option to pay for this mint": "",
    "Pay with Crypto": "",
    "We realized that your balance is too low. You need to have at least ": "",
    "Pay with Credit Card": "",
    "Please wait for the payment to be completed": "",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "",
    "No tokens available for minting": "",
    "Max tokens minted": "",
    "Mint will start soon. Stay tuned!": "",
    "Price/NFT": "",
    "Amount you already minted": "",
    "Current Price": "",
    "e.g. Voucher Code": "",
    "Sold out!": "",
    "Please visit the": "",
    "secondary market.": "",
    "OpenSea": "",
    "LooksRare": "",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "",
    "Switch to Ethereum network": "",
    "Switch to Rinkeby network": "",
    "Switch to Görli network": "",
    "Switch to Polygon network": "",
    "Switch to Mumbai network": "",
    "Preview": "",
    "Edit": "",
    "Update": "",
    "Create": "",
    "Update Page": "",
    "Create Page": "",
    "Do you want to update this page?": "",
    "Do you want to create this page?": "",
    "Set Title": "",
    "Set URL": "",
    "Locale": "",
    "Page Deleted": "",
    "Page was deleted successfully!": "",
    "Continue": "",
    "Back": "",
    "Missing title": "",
    "Expired": "",
    "Unlock": "",
    "Please connect your wallet to be able to unlock": "",
    "Welcome to {project}": "",
    "{project} asks for your email address": "",
    "{project} requires your email address to continue": "",
    "share email anonymised": "",
    "(recommended)": "",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "",
    "share email": "",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "",
    "don't share email": "",
    "Email address": "",
    "Your stored email": "",
    "Submit": "Invia",
    "By submitting you accept the": "",
    "terms of service": "",
    "terms of service for {projectTitle}": "",
    "Close": "",
    "data protection policy": "",
    "data protection policy for {projectTitle}": "",
    "of {projectTitle}.": "",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "",
    "Share": "",
    "Bid for this Product": "",
    "you must bid at least": "",
    "Enter bid": "",
    "Bid can't be higher than current balance": "",
    "Warning: Your bid is lower than the current highest bid": "",
    "Your balance": "",
    "Place your Bid": "",
    "You cannot place a bid as you have insufficient funds.": "",
    "Please follow the confirmation steps in your wallet.": "",
    "Your bid is stored in our database.": "",
    "You have successfully placed your bid!": "",
    "We will notify you if you are outbid, or if you are the bid winner!": "",
    " view more": "",
    "This is a cached value, the price should soon be loaded from blockchain.": "",
    "Purchase": "",
    "Your item purchase was successful": "",
    "Okay": "",
    "Order Summary": "",
    "Amount: ": "",
    "Total": "",
    "Close Modal": "",
    "Transaction in Progress": "",
    "Please sign allowance": "",
    "please approve the interaction": "",
    "Checking your balance": "",
    "checking if your wallet has enough funds to buy this product": "",
    "Please sign transaction": "",
    "Metamask should open and ask you to sign the transaction": "",
    "Wait for transaction to be mined": "",
    "Depending on network traffic this could take a few minutes": "",
    "Something went wrong": "",
    "We could not purchase the product!": "",
    "Biling details": "",
    "First name": "",
    "This field is required": "",
    "Maximum length is 150": "",
    "Last name": "",
    "Company name": "",
    "Address": "",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "",
    "City": "",
    "Country": "",
    "Summary": "",
    "Products": "",
    "Price": "",
    "Continue Purchase": "",
    "Please Sign in with Wallet first before purchasing a product!": "",
    "Please connect your wallet first before being able to purchase products": "",
    "Click Purchase to complete your checkout.": "",
    "Sign in with Wallet": "Connetti con Wallet",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "",
    "Your retrieved earnings, that are ready to claim!": "",
    "pending rewards": "",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "",
    "Your lifetime earnings from staking.": "",
    "Claim": "",
    "Authorization required": "",
    "The provided voucher code is not valid": "",
    "The provided voucher code got already used. Each voucher code can be used only once.": "",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "",
    "Attention:": "",
    "We can't guarantee the exact order of the transactions.": "",
    "An Error occured!": "",
    "show full error message": "",
    "Processing": "",
    "Success!": "",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "",
    "a token": "",
    "created": "",
    "updated": "",
    "minted": "",
    "transfered": "",
    "You successfully {action} {resourceType}": "",
    "ok": "",
    "click to get back to overview screen": "",
    "Missing Name": "",
    "Select": "",
    "Selected": "",
    "Transfer Token": "",
    "Receiver": "",
    "Not a valid address!": "",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": "",
    "Transfer this NFT": "",
    "Purchase in Progress": "",
    "Signing with your wallet": "",
    "Please follow your wallet instructions to confirm the transaction.": "",
    "You confirmed the transaction with your wallet.": "",
    "Transaction is sent to the blockchain": "",
    "After signing the transaction it is send to the blockchain.": "",
    "Transaction is being processed": "",
    "Please check your wallets transaction history for your actual transaction status!": "",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "",
    "Select Tokens for Voting": "",
    "Select Token for Unlocking": "",
    "Select {n} token from these": "",
    "And": "",
    "Or": "",
    "Sorry you dont have any matching Token for unlocking": "",
    "Please fill out those inputs to unlock": "",
    "Entered value does not match email format": "",
    "Sign Up": "",
    "Vote": "",
    "Email": "Email",
    "Change password": "",
    "Insert your current password and the new password": "",
    "Current Password": "",
    "Password": "Password",
    "Save": "",
    "tokens": "",
    "community event": "",
    "partner event": "",
    "conference": "",
    "irl meetup": "",
    "irl party": "",
    "virtual meetup": "",
    "discord event": "",
    "ask me anything": "",
    "There are no votings options available": "",
    "vote": "",
    "Please connect your wallet to be able to vote": "",
    "Your Vote has been sent successfully": "",
    "Reveal": "",
    "get more Info": "",
    "This vote has ended. Thanks!": "",
    "Wallet Changed": "",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "",
    "(A QR code will be generated for {n} seconds)": "",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "",
    "Copy Private Key": "",
    "please complete your profile": "",
    "* means required": "",
    "Please connect to your wallet first.": "",
    "Contract Address": "",
    "Add my Wallet to this Allowlist": "",
    "Congratulations!": "",
    "You've been added to the Allowlist!": "",
    "Do you want to receive notifications on your email about this listing?": "",
    "Wallet Address": "",
    "You are already part of this allowlist!": "",
    "Name": "",
    "Description": "",
    "Items.header": "Ciao 1 Kind",
    "Items.header.offline": "Ciao offline 1 Kind",
    "YYYY-MM-DD": "DD.MM.YYYY",
    "LoadingContent.message": "",
    "ForgotPassword": "Dimenticato le credenziali? Clicca",
    "ForgotPassword.here": "qui",
    "NewPassword": "Nuova Password",
    "NewPasswordConfirmation": "Conferma Nuova Password",
    "PasswordConfirmation": "Conferma Password",
    "Number": "",
    "Text": "",
    "TextArea": "",
    "checkbox": "",
    "Switch": "",
    "Male": "",
    "Female": "",
    "RadioGroup": "",
    "select": "",
    "multi-select": "",
    "ForOFour.message": "",
    "ForOFour.button": "",
    "Home.header": "Ciao 1 Kind",
    "Home.header.offline": "Ciao offline 1 Kind"
  },
  "pt": {
    "Powered by": "Powered by",
    "Connect": "Desconectar",
    "Wallet": "Wallet",
    "New alerts": "Novos alertas",
    "Not signed in with Wallet": "Não conectado com Wallet",
    "Account": "Conta",
    "Balance": "Saldo",
    "Signing Request": "Pedido de Assinatura",
    "Cancel": "Cancelar",
    "Sign": "Assinar",
    "You are not signed in with your Wallet yet": "Ainda não está conectado com o ethereum",
    "You are asked to sign a message to generate a secure login connection for your wallet. We won't have access to your funds, NFTs or perform any actions with this information.": "É-lhe pedido que assine uma mensagem para gerar uma ligação de início de sessão segura para a sua Wallet. Não teremos acesso aos seus fundos, NFTs ou realizaremos quaisquer acções com esta informação.",
    "You are signed in with your Wallet": "Está conectado com ethereum",
    "Copy address": "Copiar endereço",
    "Tokens": "Tokens",
    "Settings": "Configurações",
    "Activity": "Atividade",
    "Please \"Sign in with Wallet\" first.": "Por favor, use “Iniciar sessão com Wallet” primeiro.",
    "See above": "Veja acima",
    "Change E-mail address": "Endereço de e-mail",
    "Change": "Wallet Alterada",
    "Delete Account": "Excluir conta",
    "To delete your account please contact us: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.": "Para excluir sua conta, entre em contacto connosco: <a href='mailto:support@bowline.app' target='_blank'>support@bowline.app</a>.",
    "For more information on the processing of your personal data, please see our <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para mais informação sobre o tratamento dos seus dados pessoais, consulte a nossa <a href='https://www.bowline.app/data-protection/' target='_blank'>Política de Privacidade</a>.",
    "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Bowline <a href='https://www.bowline.app/tos/' target='_blank'>Termos de serviço</a>.",
    "No Activity yet in this session": "Ainda não há atividade nesta sessão",
    "Recent Signatures": "Assinaturas recentes",
    "Transactions": "Transações",
    "Disconnect": "Desconectar",
    "Your Tokens related to this project": "Os seus Tokens relacionados com este projeto",
    "Reload Tokens": "Recarregar Tokens",
    "You do not have any Token in Wallet for this project": "Não possui nenhum Token na Wallet para este projeto",
    "Reload": "Recarregar",
    "Your Wallet might contain other Tokens from other dApps. Please visit the projects websites to see them or check your wallet on for example": "A sua Wallet pode conter Tokens de outros dApps. Por favor, visite os websites dos projetos para os ver ou verifique a sua Wallet, por exemplo",
    "or": "ou",
    "Connect to Wallet": "Conectar à Wallet",
    "close": "fechar",
    "Select a Wallet": "Selecionar a Wallet",
    "Metamask": "Metamask",
    "Ledger": "Ledger",
    "Coinbase Wallet": "Coinbase Wallet",
    "Wallet Connect": "Wallet Connect",
    "Social Connect": "Social Connect",
    "Google": "Google",
    "Facebook": "Facebook",
    "By connecting your wallet you accept our <a href='https://www.bowline.app/tos/' target='_blank'>Terms of Service</a>.": "Ao conectar a Wallet aceita os nossos <a href='https://www.bowline.app/tos/' target='_blank'>Termos de Serviço</a>.",
    "How to connect?": "Como conectar?",
    "To securely connect and interact with this service you need to have a wallet that supports the Web3 protocol. Please select one of the following options to connect to your wallet.": "Para se conectar e interagir de forma segura com este serviço, é necessário ter uma Wallet que suporte o protocolo Web3. Por favor, selecione uma das seguintes opções para se conectar à sua Wallet.",
    "I do have a wallet.": "Eu tenho uma Wallet.",
    "In case you do already have a wallet, please select MetaMask, Ledger, Coinbase or Wallet Connect to connect to your wallet.": "Caso já tenha uma Wallet, selecione MetaMask, Ledger, Coinbase ou Wallet Connect para se conectar à sua Wallet.",
    "No wallet yet?": "Ainda não tem uma Wallet?",
    "No problem, you have the convenience of creating a personal wallet effortlessly by utilizing your social network accounts. We'll create a non-custodial wallet for you in the background and you can export your private keys if required.": "Não há problema, tem a conveniência de criar uma Wallet pessoal sem esforço, utilizando as suas contas de redes sociais. Criaremos uma Wallet sem custódia para si em segundo plano e pode exportar as suas chaves privadas, se necessário.",
    "Connect with Coinbase Wallet": "Conectar com Coinbase Wallet",
    "Coinbase Wallet is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "A Coinbase Wallet é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Want to learn more about this wallet?": "Quer saber mais sobre esta Wallet?",
    "Learn how to install and setup this wallet on": "Saiba como instalar e configurar esta Wallet em",
    "We've just recently added support for this wallet. If you experience any issue please let us know.": "Adicionámos recentemente o suporte para esta Wallet. Se tiver algum problema, por favor informe-nos.",
    "Connect with Ledger": "Conectar com Ledger",
    "Ledger is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "O Ledger é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Once you click the Connect Button you can use Ledger Live and Ledger Connect from your Computer or Mobile Phone.": "Depois de clicar no botão Conectar, pode utilizar o Ledger Live e o Ledger Connect a partir do seu computador ou telemóvel.",
    "Connect with MetaMask": "Conectar com MetaMask",
    "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications.": "A MetaMask é uma Wallet comum e frequentemente utilizada que permite aos utilizadores aceder ao ecossistema Web 3 de aplicações descentralizadas.",
    "Please use your existing MetaMask wallet.": "Por favor utilize a sua MetaMask Wallet atual.",
    "Connect with your preferred wallet": "Conecte com a sua Wallet preferida",
    "With WalletConnect you can connect to almost any existing wallet.": "Com WalletConnect, pode conectar-se a quase todas as Wallets existentes.",
    "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets.": "Por favor clique em WalletConnect e digitalize o código QR com a Wallet da sua escolha ou seleccione a partir da lista de Wallets compatíveis.",
    "Connect with your Google Account": "Conectar com a sua conta Google",
    "If you haven't yet obtained a crypto wallet, you have the option to utilize your current social accounts such as Google or Facebook to create a wallet.": "Se ainda não tiver obtido uma crypto Wallet, tem a opção de utilizar as suas contas sociais actuais, como o Google ou o Facebook, para criar uma Wallet.",
    "We do not possess access to your private keys, which means we are unable to manage your funds on your behalf. You, and only you, maintain complete control and ownership over your wallet and its associated private key.": "Não temos acesso às suas chaves privadas, o que significa que não podemos gerir os seus fundos em seu nome. O utilizador, e apenas o utilizador, mantém o controlo total e a propriedade da sua Wallet e da respectiva chave privada associada.",
    "Connect with Google": "Conectar com Google",
    "To establish a connection with your Google account, you will need to grant authorization to a trusted third-party application to create a Social Network Account that ensures the secure management of your non-custodial wallet.": "Para estabelecer uma conexão com a sua conta Google, terá de conceder permissão a uma aplicação de terceiros confiável para criar uma Social Network Account que garante a gestão segura da sua Wallet sem custódia.",
    "Connect to your Wallet via Social Connect.": "Conecte-se à sua Wallet via Social Connect.",
    "Connect with your Meta Account": "Conecte-se com a sua Conta Meta",
    "Social Connect is a new, simple and fast way to create a wallet. Therefore, Bowline uses your existing social accounts like Google or Facebook to create a wallet that can be used on any Bowline powered decentralized application (dApp).": "O Social Connect é uma forma nova, simples e rápida de criar uma Wallet. Portanto, a Bowline utiliza as suas contas sociais existentes, como o Google ou o Facebook, para criar uma Wallet que pode ser usada em qualquer aplicação descentralizada (dApp) alimentada pela Bowline.",
    "Bowline has no access to your private and financial information, only you have full control and ownership over your wallet.": "A Bowline não tem acesso às suas informações privadas e financeiras, apenas o utilizador possui controle total e propriedade sobre a sua Wallet.",
    "Connect with Facebook": "Conectar com Facebook",
    "Connect with Metamask": "Conectar com Metamask",
    "Connect with Wallet Connect": "Conectar com Wallet Connect",
    "Verify your e-mail address": "{project} solicita o seu endereço de e-mail",
    "The code was sent to your e-mail": "O código foi enviado para o seu e-mail",
    "Please enter the code": "Por favor, visite o",
    "Verify": "Verificar",
    "Resend": "Reenviar",
    "Discard": "Descartar",
    "Attending": "Participando",
    "Type": "Tipo",
    "Start": "Início",
    "End": "Fim",
    "Details": "Detalhes",
    "Signed up": "Registado",
    "New content is available. Please click here to refresh the page and avoid inconsistencies.": "Novo conteúdo está disponível. Por favor, clique aqui para atualizar a página e evitar inconsistências.",
    "Home": "Página inicial",
    "Already minted in total": "Já mintadas no total",
    "Already minted / wallet": "Já mintado / wallet",
    "Mint for {mintPrice} ETH / each": "Mint por {mintPrice} ETH / cada",
    "Paid Transaction": "Transação paga",
    "Mint": "Mint",
    "Buy Mint": "Comprar Mint",
    "Select an option to pay for this mint": "Selecione uma opção para pagar por este mint",
    "Pay with Crypto": "Pagar com Crypto",
    "We realized that your balance is too low. You need to have at least ": "Verificámos que o seu saldo é demasiado baixo. Precisa de ter pelo menos ",
    "Pay with Credit Card": "Pagar com cartão de crédito",
    "Please wait for the payment to be completed": "Por favor, aguarde até que o pagamento seja concluído",
    "The transaction has been sent and you will receive a notification in your bowline wallet": "A transação foi enviada e receberá uma notificação na sua Bowline Wallet",
    "No tokens available for minting": "Não há tokens disponíveis para minting",
    "Max tokens minted": "Máximo de tokens mintados",
    "Mint will start soon. Stay tuned!": "O mint começará em breve. Fique atento!",
    "Price/NFT": "Preço/NFT",
    "Amount you already minted": "Quantidade que já mintou",
    "Current Price": "Preço atual",
    "e.g. Voucher Code": "por exemplo, código de voucher",
    "Sold out!": "Esgotado!",
    "Please visit the": "Por favor, visite o",
    "secondary market.": "mercado secundário.",
    "OpenSea": "OpenSea",
    "LooksRare": "LooksRare",
    "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.": "Está atualmente conectado a uma rede diferente daquela em que este contrato foi implementado. Por favor, altere a sua rede para {networkName} na sua Wallet.",
    "Switch to Ethereum network": "Mudar para a rede Ethereum",
    "Switch to Rinkeby network": "Mudar para a rede Rinkeby",
    "Switch to Görli network": "Mudar para a rede Görli",
    "Switch to Polygon network": "Mudar para a rede Polygon",
    "Switch to Mumbai network": "Mudar para a rede Mumbai",
    "Switch to Amoy network": "Mudar para a rede Amoy",
    "Preview": "Pré-visualização",
    "Edit": "Editar",
    "Update": "Atualizar",
    "Create": "Criar",
    "Update Page": "Atualizar Página",
    "Create Page": "Criar Página",
    "Do you want to update this page?": "Deseja atualizar esta página?",
    "Do you want to create this page?": "Deseja criar esta página?",
    "Set Title": "Definir Título",
    "Set URL": "Definir URL",
    "Locale": "Linguagem",
    "Page Deleted": "Página Removida",
    "Page was deleted successfully!": "Página removida com sucesso!",
    "Continue": "Continuar",
    "Back": "Voltar",
    "Missing title": "Título em falta",
    "Expired": "Expirado",
    "Unlock": "Desbloquear",
    "Please connect your wallet to be able to unlock": "Por favor conecte com Wallet para poder desbloquear",
    "Welcome to {project}": "Bem-vindo ao {project}",
    "{project} asks for your email address": "{project} solicita o seu endereço de e-mail",
    "{project} requires your email address to continue": "{project} requer o seu endereço de e-mail para continuar",
    "share email anonymised": "partilhar e-mail anónimo",
    "(recommended)": "(recomendado)",
    "{project} will only receive an anonymised email address but bowline will keep track of your real email address.": "{project} receberá apenas um endereço de e-mail anónimo, mas a bowline manterá o registo do seu endereço de e-mail real.",
    "share email": "partilhar e-mail",
    "{project} will get your email address and use it according to their data protection policy and bowline will also store your email address to send notifications.": "{project} receberá o seu endereço de e-mail e o utilizará de acordo com a sua política de proteção de dados, e a bowline também armazenará o seu endereço de e-mail para enviar notificações.",
    "don't share email": "não partilhar e-mail",
    "Email address": "Endereço de e-mail",
    "Your stored email": "O seu e-mail armazenado",
    "Submit": "Submeter",
    "By submitting you accept the": "Ao submeter o pedido, o utilizador aceita os",
    "terms of service": "termos de serviço",
    "terms of service for {projectTitle}": "termos de serviço para {projectTitle}",
    "Close": "Fechar",
    "data protection policy": "política de proteção de dados",
    "data protection policy for {projectTitle}": "política de proteção de dados para {projectTitle}",
    "of {projectTitle}.": "de {projectTitle}.",
    "For more information on the processing of your personal data through bowline, please see this <a href='https://www.bowline.app/data-protection/' target='_blank'>Privacy Policy</a>.": "Para mais informação sobre o tratamento dos seus dados pessoais através da bowline, por favor consulte esta <a href='https://www.bowline.app/data-protection/' target='_blank'>Política de Privacidade</a>.",
    "Share": "Partilhar",
    "Bid for this Product": "Licitar para este Produto",
    "you must bid at least": "é necessário licitar pelo menos",
    "Enter bid": "Introduzir proposta",
    "Bid can't be higher than current balance": "A proposta não pode ser superior ao saldo atual",
    "Warning: Your bid is lower than the current highest bid": "Aviso: A sua proposta é inferior à licitação mais alta atual",
    "Your balance": "O seu saldo",
    "Place your Bid": "Faça a sua proposta",
    "You cannot place a bid as you have insufficient funds.": "Não pode efetuar uma licitação porque não tem fundos suficientes.",
    "Please follow the confirmation steps in your wallet.": "Siga os passos de confirmação na sua Wallet.",
    "Your bid is stored in our database.": "A sua proposta foi armazenada na nossa base de dados.",
    "You have successfully placed your bid!": "A sua licitação foi efectuada com sucesso!",
    "We will notify you if you are outbid, or if you are the bid winner!": "Se a sua licitação for ultrapassada ou se for o vencedor da licitação, será notificado!",
    " view more": " ver mais",
    "This is a cached value, the price should soon be loaded from blockchain.": "Este é um valor em cache, o preço deve ser carregado em breve a partir de blockchain.",
    "Purchase": "Comprar",
    "Your item purchase was successful": "A sua compra do item foi bem-sucedida",
    "Okay": "Okay",
    "Order Summary": "Resumo do Pedido",
    "Amount: ": "Quantidade: ",
    "Total": "Total",
    "Close Modal": "Fechar Modal",
    "Transaction in Progress": "Transação em Andamento",
    "Please sign allowance": "Por favor, assine a autorização",
    "please approve the interaction": "por favor, aprove a interação",
    "Checking your balance": "Verificando o seu saldo",
    "checking if your wallet has enough funds to buy this product": "verificando se a sua Wallet possui fundos suficientes para comprar este produto",
    "Please sign transaction": "Por favor, assine a transação",
    "Metamask should open and ask you to sign the transaction": "Metamask deveria abrir e pedir para o utilizador assinar a transação",
    "Wait for transaction to be mined": "Aguarde que a transação seja extraída",
    "Depending on network traffic this could take a few minutes": "Dependendo do tráfego da rede, isso pode demorar alguns minutos",
    "Something went wrong": "Algo correu mal",
    "We could not purchase the product!": "Não foi possível comprar o produto!",
    "Biling details": "Dados de faturação",
    "First name": "Primeiro nome",
    "This field is required": "Este campo é obrigatório",
    "Maximum length is 150": "",
    "Last name": "Apelido",
    "Company name": "Nome da empresa",
    "Address": "Morada",
    "Minimum length is 5": "",
    "Maximum length is 250": "",
    "Postal Code": "Código Postal",
    "City": "Cidade",
    "Country": "País",
    "Summary": "Resumo",
    "Products": "Produtos",
    "Price": "Preço",
    "Continue Purchase": "Continuar Compra",
    "Please Sign in with Wallet first before purchasing a product!": "Por favor, inicie sessão com Wallet antes de comprar um produto!",
    "Please connect your wallet first before being able to purchase products": "Por favor, conecte com Wallet primeiro antes de poder comprar produtos",
    "Click Purchase to complete your checkout.": "Clique em Comprar para concluir a compra.",
    "Sign in with Wallet": "Iniciar sessão com Wallet",
    "Stake All selected": "",
    "Unstake All selected": "",
    "staked": "",
    "Unstake": "",
    "Stake": "",
    "unclaimed earnings": "ganhos não reclamados",
    "Your retrieved earnings, that are ready to claim!": "Os seus ganhos recolhidos, que estão prontos a ser reclamados!",
    "pending rewards": "recompensas pendentes",
    "Tokens in the Hole": "",
    "Your total Tokens staked": "",
    "Available Balance": "Saldo Disponível",
    "Your lifetime earnings from staking.": "",
    "Claim": "Recolher",
    "Authorization required": "Autorização necessária",
    "The provided voucher code is not valid": "O código do voucher fornecido não é válido",
    "The provided voucher code got already used. Each voucher code can be used only once.": "O código do voucher fornecido já foi utilizado. Cada código do voucher só pode ser utilizado uma vez.",
    "Your transaction was already sent to the blockchain. It looks like you tried to mint the same token again. Please be patient.": "A sua transação já foi enviada para a blockchain. Parece que tentou mintar o mesmo token novamente. Por favor, seja paciente.",
    "If you tried to mint more than one Token, you've might got caught by our bot protection. We will manually review your transaction and airdrop you the token later.": "Se tentou mintar mais do que um Token, poderá ter sido apanhado pela nossa proteção de bots. Iremos rever manualmente a sua transação e enviar-lhe-emos o token mais tarde.",
    "We could not airdrop your toke at this moment because there is too much traffic on the blockchain right now. However, your airdrop was successfully added to the queue, and you´ll receive your token once the transaction was written to the blockchain. This can take up to a few hours depending on queue length and gas.": "",
    "Attention:": "Participando",
    "We can't guarantee the exact order of the transactions.": "",
    "An Error occured!": "Ocorreu um erro!",
    "show full error message": "mostrar mensagem de erro completa",
    "Processing": "Processando",
    "Success!": "Sucesso!",
    "a content category": "",
    "an unlockable content": "",
    "a whitelist entry": "",
    "a event": "um evento",
    "a token": "um token",
    "created": "criado",
    "updated": "atualizado",
    "minted": "mintado",
    "transfered": "transferido",
    "You successfully {action} {resourceType}": "Realizou com sucesso {action} {resourceType}",
    "ok": "ok",
    "click to get back to overview screen": "clique para voltar ao ecrã de visão geral",
    "Missing Name": "Nome em falta",
    "Select": "Selecione",
    "Selected": "Selecionado",
    "Transfer Token": "Transferir Token",
    "Receiver": "Destinatário",
    "Not a valid address!": "Não é uma morada válida!",
    " ⚠️ You will transfer an nft to {address}. This operation can not be undone": " ⚠️ Vai transferir um nft para {address}. Esta operação não pode ser anulada",
    "Transfer this NFT": "Transferir este NFT",
    "Purchase in Progress": "Compra em andamento",
    "Signing with your wallet": "Assinar com Wallet",
    "Please follow your wallet instructions to confirm the transaction.": "Siga as instruções da sua Wallet para confirmar a transação.",
    "You confirmed the transaction with your wallet.": "Confirmou a transação com a sua Wallet.",
    "Transaction is sent to the blockchain": "Transação enviada para a blockchain",
    "After signing the transaction it is send to the blockchain.": "Depois de assinar a transação, esta é enviada para a blockchain.",
    "Transaction is being processed": "A transação está a ser processada",
    "Please check your wallets transaction history for your actual transaction status!": "Por favor, verifique o histórico de transações da sua Wallet para obter o status atual da sua transação!",
    "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined.": "Pode fechar este modal em qualquer altura - isso não afeta a sua transação. Fecharemos o modal assim que verificarmos que a transação está mined.",
    "Select Tokens for Voting": "Selecionar Tokens para Votação",
    "Select Token for Unlocking": "Selecionar Tokens para Desbloquear",
    "Select {n} token from these": "Selecionar {n} token dentre estes",
    "And": "E",
    "Or": "Ou",
    "Sorry you dont have any matching Token for unlocking": "Lamentamos, mas não tem nenhum Token correspondente para desbloqueio",
    "Please fill out those inputs to unlock": "Por favor, preencha esses campos para desbloquear",
    "Entered value does not match email format": "O valor inserido não corresponde ao formato de e-mail",
    "Sign Up": "Registar-se",
    "Vote": "Votar",
    "Email": "E-mail",
    "Change password": "Mudar palavra-passe",
    "Insert your current password and the new password": "Introduza a sua palavra-passe atual e a nova palavra-passe",
    "Current Password": "Palavra-passe atual",
    "Password": "Palavra-passe",
    "Save": "Guardar",
    "tokens": "tokens",
    "community event": "evento comunitário",
    "partner event": "evento de parceiro",
    "conference": "conferência",
    "irl meetup": "encontro presencial",
    "irl party": "festa presencial",
    "virtual meetup": "encontro virtual",
    "discord event": "evento no Discord",
    "ask me anything": "pergunte-me qualquer coisa",
    "There are no votings options available": "Não existem opções de votação disponíveis",
    "vote": "votar",
    "Please connect your wallet to be able to vote": "Por favor, conecte com Wallet para poder votar",
    "Your Vote has been sent successfully": "O seu voto foi enviado com sucesso",
    "Reveal": "Revelar",
    "get more Info": "obter mais informações",
    "This vote has ended. Thanks!": "Esta votação terminou. Obrigado!",
    "Wallet Changed": "Wallet Alterada",
    "It looks like you changed your wallet, would you like to connect your wallet?": "",
    "Export Wallet": "Exportar Wallet",
    "(A QR code will be generated for {n} seconds)": "(Será gerado um código QR durante {n} segundos)",
    "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet or click the button below to copy the key.": "Digitalize o código QR utilizando a câmara do seu telemóvel ou a aplicação Wallet (por exemplo, Metamask) para exportar a sua Wallet ou clique no botão abaixo para copiar a chave.",
    "Copy Private Key": "Copiar Chave Privada",
    "please complete your profile": "por favor, complete o seu perfil",
    "* means required": "* significa obrigatório",
    "Please connect to your wallet first.": "Por favor, conecte com Wallet primeiro.",
    "Contract Address": "Endereço do contrato",
    "Add my Wallet to this Allowlist": "Adicione a minha Wallet a esta Lista de Permissões",
    "Congratulations!": "Parabéns!",
    "You've been added to the Allowlist!": "Foi adicionado à Lista de Permissões!",
    "Do you want to receive notifications on your email about this listing?": "Deseja receber notificações no seu e-mail sobre este listagem?",
    "Wallet Address": "Endereço da Wallet",
    "You are already part of this allowlist!": ""
  }
}